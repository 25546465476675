import materialPalette from "./materialPalette";

// declare module "@material-ui/core/styles/createPalette" {
//   interface TypeBackground {
//     default: string;
//     paper: string;
//     paper2: string;
//     main: string;
//     selected: string;
//   }

//   interface Palette {
//     tertiary: Palette["primary"];
//     background: TypeBackground;
//   }

//   interface PaletteOptions {
//     tertiary: PaletteOptions["primary"];
//     background?: Partial<TypeBackground>;
//   }
// }

export default {
  ...materialPalette,
  //Add overrides here, keep in mind to pass down materialPalettes child nodes if needed
  primary: {
    light: "rgb(84, 187, 101)",
    main: "#2AAB3F",
    dark: "#45B556",
    contrastText: "#000000"
  },
  secondary: {
    light: "rgb(82, 151, 151)",
    main: "#277E7E",
    dark: "rgb(27, 88, 88)",
    contrastText: "#000000"
  },
  tertiary: {
    light: "#E6F2FA",
    main: "#598DB2",
    dark: "#406580",
    contrastText: "#000000"
  },
  error: {
    light: "rgb(227, 146, 159)",
    main: "#DC7787",
    dark: "rgb(154, 83, 94)",
    contrastText: "#000000"
  },
  warning: {
    light: "#FFE500",
    main: "#FFD966",
    dark: "rgb(98, 66, 34)",
    contrastText: "#000000"
  },
  info: {
    light: "rgb(77, 139, 183)",
    main: "#216EA5",
    dark: "rgb(23, 77, 115)",
    contrastText: "#000000"
  },
  success: {
    light: "rgb(81, 153, 99)",
    main: "#2AAB3F",
    dark: "rgb(26, 89, 42)",
    contrastText: "#000000"
  },
  background: {
    ...materialPalette.background,
    paper: "#1e1e1e",
    default: "#121212",
    paper2: "#F7F9FA"
  },
  text: {
    ...materialPalette.text,
    primary: "rgba(255, 255, 255, .87)",
    secondary: "rgba(255, 255, 255, .60)",
    disabled: "rgba(255, 255, 255, .38)",
    hint: "#959595",
    icon: "rgba(255, 255, 255, 0.5)"
  },
  action: {
    active: "rgba(255, 255, 255, 1)",
    activatedOpacity: 0.16,
    hover: "rgba(255, 255, 255, 0.08)",
    hoverOpacity: 0.08,
    selected: "rgba(255, 255, 255, 0.12)",
    selectedOpacity: 0.12,
    disabled: "rgba(255, 255, 255, 0.3)",
    disabledBackground: "rgba(255, 255, 255, 0.12)",
    disabledOpacity: 0.38,
    focus: "rgba(255, 255, 255, 0.12)",
    focusOpacity: 0.08
  },
  grey: {
    "50": "#fafafa",
    "100": "#f5f5f5",
    "200": "#eeeeee",
    "300": "#e0e0e0",
    "400": "#bdbdbd",
    "500": "#9e9e9e",
    "600": "#757575",
    "700": "#616161",
    "800": "#424242",
    "900": "#212121",
    A100: "#f5f5f5",
    A200: "#eeeeee",
    A400: "#bdbdbd",
    A700: "#616161"
  }
};
