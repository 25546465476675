import { acceleratorApi } from ".";
import { PersonaListResponse } from "../../types/accelerator/personas";
import { urlPathWithQueryParams } from "../../utils";

/**
 * Get Personas
 *
 * @return {Promise<PersonaListResponse>}
 */
export const getPersonas = async (): Promise<PersonaListResponse> => {
  return acceleratorApi.get<PersonaListResponse>(
    urlPathWithQueryParams(`personas`, { page: 0, size: 1000 })
  );
};

export const PersonasApi = {
  getPersonas
};
