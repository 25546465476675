import { SpcCalendarResponse } from "../../types/spc-calendar-data";
import { safeProductionCalendarApi } from ".";

/**
 * Gets calendar data
 * @returns {SpcSiteListResponse}
 */
export const getSpcCalendarData = async (
  date: string,
  siteId: string,
  locationId: string,
  islast7days: boolean
): Promise<SpcCalendarResponse> => {
  return await safeProductionCalendarApi.get(
    `safeproddata/site/${siteId}/location/${locationId}?specifieddate=${date}&isLast7days=${islast7days}`
  );
};

export const CalendarApi = {
  getSpcCalendarData: getSpcCalendarData
};
