import { compare } from "fast-json-patch";
import { acceleratorApi } from ".";
import { PlantsListResponse, PlantDocumentResponse, Plant } from "../../types/accelerator";
import { urlPathWithQueryParams } from "../../utils";

/**
 * Get Plants
 *
 * @return {Promise<PlantsListResponse>}
 */
export const getPlants = async (): Promise<PlantsListResponse> => {
  return await acceleratorApi.get(urlPathWithQueryParams(`plants`, { page: 0, size: 1000 }));
};

/**
 * Get Plant By ID
 *
 * @param plantId: @ String
 *
 * @return {Promise<AssetResponse>}
 */
export const getPlantById = async (plantId: string): Promise<PlantDocumentResponse> => {
  return acceleratorApi.get(`plants/${plantId}`);
};

/**
 * Create Plant
 *
 * @param Plant: @ Partial<Plant>
 *
 * @return {Promise<PlantDocumentResponse>}
 */
export const createPlant = async (plant: Partial<Plant>): Promise<PlantDocumentResponse> => {
  return acceleratorApi.post("plants", plant);
};

/**
 * Patch Plant
 * @param previousValue: @type Plant
 * @param nextValue: @type Partial<Plant>
 *
 * @return {Promise<AssetResponse>}
 */
export const patchPlant = (
  previousValue: Plant,
  nextValue: Partial<Plant>
): Promise<PlantDocumentResponse> => {
  const patchUpdates = compare(previousValue, { ...previousValue, ...nextValue });
  return acceleratorApi.patch(`plants/${previousValue.id}`, patchUpdates);
};

/**
 * Delete Plant
 *
 * @param plantId: @ String
 *
 * @return {Promise<void>}
 */
export const deletePlant = async (plantId: string): Promise<void> => {
  await acceleratorApi.delete(`plants/${plantId}`);
};

export const PlantsApi = {
  get: getPlants,
  getById: getPlantById,
  create: createPlant,
  update: patchPlant,
  delete: deletePlant
};
