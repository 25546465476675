import { materialBalanceApi } from ".";
import { TungstenDataSourceListResponse, TungstenTagsListResponse } from "../../types/tungsten";

/**
 * Get list of Tungsten data sources
 * @returns {DataSourceDocumentResponse}
 */
export const getDataSources = async (): Promise<TungstenDataSourceListResponse> => {
  return await materialBalanceApi.get(`tungsten/datasources`);
};

export const getTungstenTags = async (
  system: string,
  tagname: string
): Promise<TungstenTagsListResponse> => {
  return await materialBalanceApi.get(
    `tungsten/tags?tagName=${encodeURIComponent(tagname)}&system=${system}`
  );
};

export const TungstenApi = {
  DataSources: {
    get: getDataSources,
    getTungstenTags
  }
};
