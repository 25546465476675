import { compare, Operation } from "fast-json-patch";
import { calculationsEngineApi } from ".";
import {
  Calculation,
  CalculationDocumentResponse,
  CalculationsListResponse
} from "../../types/calculation-engine";

/**
 * Gets calculations for a single location Id
 * @returns {CalculationListResponse}
 */
export const getCalculationsByLocationId = async (
  locationId: string
): Promise<CalculationsListResponse> => {
  return calculationsEngineApi.get(`locations/${locationId}/calculations`);
};

/**
 * Gets claculation by Id
 * @returns {CalculationListResponse}
 */
export const getCalculationById = async (
  calculationId: string
): Promise<CalculationDocumentResponse> => {
  return calculationsEngineApi.get(`calculations/${calculationId}`);
};

/**
 * Gets Calculation History by id
 * @returns {CalculationListResponse}
 */
export const getCalculationHistoryById = async (
  calculationId: string
): Promise<CalculationsListResponse> => {
  return calculationsEngineApi.get(`calculations/${calculationId}/history`);
};

/**
 * Create Calculation
 *
 * @param calculations: @type Partial<Calculation>
 *
 * @return {Promise<Calculation>}
 */
export const createCalculation = async (
  calculations: Partial<Calculation>
): Promise<CalculationDocumentResponse> => {
  const data: Calculation = await calculationsEngineApi.post<Partial<Calculation>, Calculation>(
    `calculations`,
    calculations
  );

  const response: CalculationDocumentResponse = {
    data
  };

  return response;
};

/**
 * Update Calculation
 *
 * @param calculationId: @type String
 * @param previousValue: @type Calculation
 * @param nextValue: @type Partial<Calculation>
 *
 * @return {Promise<CalculationDocumentResponse>}
 */
export const updateCalculation = async (
  calculationId: string,
  previousValue: Calculation,
  nextValue: Partial<Calculation>
): Promise<CalculationDocumentResponse> => {
  const patchUpdates = compare(previousValue, { ...previousValue, ...nextValue });

  const data: Calculation = await calculationsEngineApi.patch<Operation[], Calculation>(
    `calculations/${calculationId}`,
    patchUpdates
  );

  const response: CalculationDocumentResponse = {
    data
  };

  return response;
};

/**
 * Delete claculation by Id
 * @returns {CalculationListResponse}
 */
export const deleteCalculationById = async (
  calculationId: string
): Promise<CalculationDocumentResponse> => {
  return calculationsEngineApi.delete(`calculations/${calculationId}`);
};

/**
 * Execute Calculation
 * @param calculationId: @type String
 * @return {Promise<CalculationDocumentResponse>}
 */
export const executeCalculation = async (
  calculationId: string,
  date: string,
  allowOutput: boolean
): Promise<CalculationsListResponse> => {
  return await calculationsEngineApi.get(
    `calculations/${calculationId}/execute?date=${date}&allowOutput=${allowOutput}`
  );
};

export const CalculationsApi = {
  createCalculation,
  executeCalculation,
  getCalculationById,
  getCalculationsByLocationId,
  getCalculationHistoryById,
  updateCalculation,
  deleteCalculationById
};
