import {
  CreateProductBody,
  ProductDocumentResponse,
  ProductListResponse,
  Product
} from "../../types/product";
import { compare } from "fast-json-patch";
import { sortByKey } from "../../utils/utils";
import { AuditLogListResponse } from "../../types/audit-log";
import { materialBalanceApi } from ".";

/**
 * Gets all the products
 * @returns {ProductListResponse}
 */
export const getProducts = async (): Promise<ProductListResponse> => {
  const products: ProductListResponse = await materialBalanceApi.get(`products`);
  products.data = sortByKey(products.data, "productName");
  return products;
};

/**
 * Get Product by product id
 * @param productId {string}
 * @returns {ProductDocumentResponse}
 */
export const getProductById = async (
  productId: string,
  skipAuth?: boolean
): Promise<ProductDocumentResponse> => {
  const url = skipAuth ? "?isProductNotAuthorized=true" : "";
  return await materialBalanceApi.get(`products/${productId}${url}`);
};

/**
 * Get Product by location id
 * @param locationId {string}
 * @returns {ProductListResponse}
 */
export const getProductByLocationId = async (locationId: string): Promise<ProductListResponse> => {
  const products: ProductListResponse = await materialBalanceApi.get(
    `areas/${locationId}/products`
  );
  products.data = sortByKey(products.data, "productName");
  return products;
};
/**
 * Get Product by site id
 * @param siteId {string}
 * @returns {ProductListResponse}
 */
export const getProductBySiteId = async (siteId: string): Promise<ProductListResponse> => {
  const products: ProductListResponse = await materialBalanceApi.get(`sites/${siteId}/products`);
  products.data = sortByKey(products.data, "productName");
  return products;
};

/**
 * Get the audit log of a product
 * @param productId {string}
 * @param includeChildren {boolean}
 * @returns {AuditLogListResponse}
 */
export const getProductAuditLog = async (
  productId: string,
  includeChildren?: boolean
): Promise<AuditLogListResponse> => {
  const products: AuditLogListResponse = await materialBalanceApi.get(
    `products/${productId}/auditLogs${includeChildren ? "?includeChildren=true" : ""}`
  );
  return products;
};

/**
 * Create new product
 * @param product {CreateProductBody}
 * @returns {ProductDocumentResponse}
 */
export const createProduct = async (
  product: CreateProductBody
): Promise<ProductDocumentResponse> => {
  return await materialBalanceApi.post(`products`, product);
};

/**
 * Update product
 * @param product {Product}
 * @returns {ProductDocumentResponse}
 */
export const updateProduct = async (product: Product): Promise<ProductDocumentResponse> => {
  return await materialBalanceApi.put(`products/${product.productId}`, {
    json: product
  });
};

/**
 * patch product
 * @param previousProduct {Product}
 * @param product {Partial<Product>}
 * @returns {ProductDocumentResponse}
 */
export const patchProduct = async (
  previousProduct: Product,
  product: Partial<Product>
): Promise<ProductDocumentResponse> => {
  const patchUpdates = compare(previousProduct, product);
  return materialBalanceApi.patch(`products/${product.productId}`, patchUpdates);
};

/**
 * Delete product
 * @param productId {string}
 * @returns {void}
 */
export const deleteProduct = async (productId: string): Promise<void> => {
  return materialBalanceApi.delete<void>(`products/${productId}`);
};

/**
 * Update product dependencies
 * @param productId {string}
 * @param needs {string[]}
 * @returns {void}
 */
export const updateProductDependencies = async (
  productId: string,
  needs: string[]
): Promise<void> => {
  return materialBalanceApi.post(`products/${productId}/dependencies`, { needs });
};

export const ProductsApi = {
  get: getProducts,
  getOne: getProductById,
  create: createProduct,
  delete: deleteProduct,
  update: patchProduct,
  getBySiteId: getProductBySiteId,
  getByLocationId: getProductByLocationId,
  getAuditLog: getProductAuditLog,
  updateDependencies: updateProductDependencies
};
