import React from "react";
import { Box, CircularProgress, Typography } from "@mui/material";

import {
  Location,
  useGetLocations,
  useSelectedLocation,
  useUpdateUserPreferences,
  useUserProfile
} from "@packages/service-api";

import LocationTree, { TreeNode } from "./LocationTree";
import { useAppSidebar } from "./state";
import CollapsedSidebarItem from "./CollapsedSidebarItem";
import LocationDialog from "./LocationDialog";
import { useLocation } from "react-router-dom";
import { listToTree } from "../../utils";

type Props = {
  applicationId: string;
  hideLocationbar?: boolean;
  onLocationChange?: (nextLocation: Location) => void;
};

const SidebarLocation: React.FC<Props> = (props) => {
  const { applicationId, onLocationChange, hideLocationbar } = props;

  const [modalOpen, setModalOpen] = React.useState<boolean>(false);

  const urlLocation = useLocation();
  const userProfile = useUserProfile();
  const { collapsed } = useAppSidebar();

  const {
    loading: selectedLocationLoading,
    selectedLocation,
    siteId
  } = useSelectedLocation(applicationId);
  const { updateUserPreferences, saving } = useUpdateUserPreferences();

  const includeRootLocation = React.useMemo(
    () => urlLocation?.pathname.includes("/admin/"),
    [urlLocation.pathname]
  );

  const { locations, loading: locationsLoading } = useGetLocations(
    applicationId,
    siteId,
    includeRootLocation
  );

  const loading = selectedLocationLoading || locationsLoading || saving;

  React.useEffect(() => {
    if (loading || locations.length === 0 || !!selectedLocation) {
      return;
    }

    if (siteId) {
      let highestLocation: Location;

      // if the root location is available, default to that.
      if (includeRootLocation) {
        const rootLocation = locations.find((l) => l.locationId === applicationId);
        highestLocation = rootLocation;
        // Otherwise build the location tree and find the highest location in the tree and set the selected location to that
      } else {
        const locationTree = listToTree(locations as TreeNode[], "locationId", "parentLocationId");

        if (locationTree[0]) {
          highestLocation = locationTree[0];
        }
      }

      if (highestLocation) {
        handleUpdateLocation(highestLocation);
      }
    }
  }, [loading, locations, selectedLocation, siteId, includeRootLocation]);

  const handleUpdateLocation = async (nextLocation: Location) => {
    if (selectedLocation?.locationId !== nextLocation?.locationId) {
      if (onLocationChange) {
        onLocationChange(nextLocation);
      }

      await updateUserPreferences({
        [applicationId]: {
          ...userProfile.preferences[applicationId],
          [siteId]: { selectedLocationId: nextLocation?.locationId }
        }
      });
    }
  };

  return (
    <Box>
      {collapsed ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
          }}
        >
          <CollapsedSidebarItem
            active
            icon="map-pin"
            label={selectedLocation?.name}
            tooltip={selectedLocation?.name}
            onClick={() => setModalOpen(true)}
          />
        </Box>
      ) : (
        !hideLocationbar && (
          <Box sx={{ px: 2 }}>
            <Box
              sx={{
                alignItems: "center",
                display: "flex",
                flexDirection: "row"
              }}
            >
              <Typography variant="h6" fontWeight="bold" sx={{ mr: 1 }}>
                Location
              </Typography>
              {loading && <CircularProgress size={14} />}
            </Box>

            <LocationTree
              locations={locations}
              loading={loading}
              selected={selectedLocation}
              onChange={handleUpdateLocation}
            />
          </Box>
        )
      )}

      {modalOpen && (
        <LocationDialog applicationId={applicationId} onClose={() => setModalOpen(false)} />
      )}
    </Box>
  );
};

export default SidebarLocation;
