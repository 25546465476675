import objectPath from "object-path";

import { Observable, Subscriber, dayjs, deepClone } from "@packages/utils";
import { applications } from "@nutrien-operations/config";

import {
  HTTPError,
  ShiftInstanceDocumentResponse,
  ShiftInstanceWithDate,
  ShiftSchedule
} from "../types";
import { ApplicationId, LocationId, SiteId } from "../types/common";
import { CommonDataApi, ShiftLogsApi } from "../apis";

type WindowWithShiftManager = Window & {
  shiftManager: ShiftManager;
};

export type ShiftManagerLocationStatus = "initializing" | "loading" | "ready" | "not-enabled";

export type LocationState = {
  currentShift: ShiftInstanceWithDate;
  schedule: ShiftSchedule;
  selectedShiftIndex: number;
  status: ShiftManagerLocationStatus;
  error?: string;
};

type LocationMapInterface = Record<LocationId, LocationState>;
type SiteMapInterface = Record<SiteId, LocationMapInterface>;
type ShiftManagerState = Record<ApplicationId, SiteMapInterface>;

// improvements that could be made
// 1. add an isInitializing flag to stop concurrent initializations of the same location
// 2. create a map of shift schedules right now it's going to reload on location change.

/**
 * Shift manager class to manage all things related to shifts in the mono repo
 */
export class ShiftManager {
  /**
   * Internal state of the shift manager.
   *
   * @type {Observable<ShiftManagerState>} - Map wrapped in an observable to make it possible to subscribe to updated
   * @memberof ShiftManager
   */
  _state: Observable<ShiftManagerState> = new Observable<ShiftManagerState>({});

  /**
   * Get the internal state
   *
   * @type {ShiftManagerState}
   * @memberof ShiftManager
   */
  get state(): ShiftManagerState {
    return this._state.get();
  }

  /**
   * Mutate the internal state
   *
   * @memberof ShiftManager
   */
  set state(nextStateValue: ShiftManagerState) {
    this._state.set(deepClone<ShiftManagerState>(nextStateValue));
  }

  /**
   * Initialize internal state for a specific location.
   * @param {string} applicationId - Application Id
   * @param {string} siteId - Site Id of location
   * @param {string} locationId - The id of the location
   * @param {string} date - optional Date to search for the shift schedule. Defaults to today.
   * @returns {void}
   */
  private async initializeLocation(
    applicationId: string,
    siteId: string,
    locationId: string,
    date?: string
  ) {
    let shiftSchedule: ShiftSchedule;

    // date to find the shift schedule of. Default to today
    const searchDate = date || dayjs().format("YYYY-MM-DD");

    try {
      this.setLocationState(applicationId, siteId, locationId, { status: "initializing" });

      // get the shift schedule for the location on the search date
      const schedule = await CommonDataApi.ShiftSchedules.getByLocationAndDate(
        locationId,
        searchDate
      );

      shiftSchedule = schedule?.data;
    } catch (error) {
      const errorMessage = `Failed to initialize location. No shift schedule is configured for date [${searchDate}] as location [${locationId}]`;

      const state = this.setLocationState(applicationId, siteId, locationId, {
        status: "not-enabled",
        error: errorMessage
      });

      //eslint-disable-next-line
      console.error(errorMessage);
      return state;
    }

    // determine which API to call to get the current shift from
    const appApi = this.applicationSpecificShiftEndpoints(applicationId);

    let currentShift: ShiftInstanceDocumentResponse;

    try {
      // get the current shift
      currentShift = await appApi.getCurrentShift(locationId);
    } catch (err) {
      const error: HTTPError = err;
      let errorString = "";

      try {
        const responseBody = await error.response.json();
        errorString = responseBody?.error;
      } catch {
        const text = await error.response?.text();

        if (text) {
          errorString = `${errorString}: ${text}`;
        }
      }

      const state = this.setLocationState(applicationId, siteId, locationId, {
        status: "not-enabled",
        error: errorString
      });

      //eslint-disable-next-line
      console.error(errorString);
      return state;
    }

    // find the index of the current shift in the array shift instances of the shift schedule
    const index = shiftSchedule.shiftsInstances?.findIndex((instance) => {
      return (
        instance.shiftId === currentShift?.data.shiftId &&
        instance.startDateTime === currentShift?.data.startDateTime
      );
    });

    const calculatedLocationState: LocationState = {
      currentShift: {
        ...currentShift?.data,
        date: dayjs(currentShift?.data.startDateTime).format("YYYY-MM-DD")
      },
      schedule: shiftSchedule,
      selectedShiftIndex: index,
      status: "ready"
    };

    this.setLocationState(applicationId, siteId, locationId, calculatedLocationState);
    return calculatedLocationState;
  }

  /**
   * Set the state for a specific location. If it does not exist
   * @param {string} applicationId - Application Id
   * @param {string} siteId - Site Id of location
   * @param {string} locationId - The id of the location
   * @returns {LocationState} - The state of that specific application
   */
  setLocationState(
    applicationId: string,
    siteId: string,
    locationId: string,
    update: Partial<LocationState>
  ) {
    const previousLocationState = objectPath.get<LocationState>(
      this.state,
      `${applicationId}.${siteId}.${locationId}`,
      undefined
    );

    const nextLocationState: LocationState = {
      ...previousLocationState,
      ...update
    };

    const nextState = deepClone(this.state);

    objectPath.set<LocationState>(
      nextState,
      `${applicationId}.${siteId}.${locationId}`,
      nextLocationState
    );

    this.state = nextState;

    return nextLocationState;
  }

  /**
   * Get the state for a specific location.
   * @param {string} applicationId - Application Id
   * @param {string} siteId - Site Id of location
   * @param {string} locationId - The id of the location
   * @returns {LocationState} - The state of that specific application
   */
  async getLocationState(applicationId: string, siteId: string, locationId: string) {
    let state = objectPath.get<LocationState>(
      this.state,
      `${applicationId}.${siteId}.${locationId}`,
      undefined
    );

    if (!state) {
      state = await this.initializeLocation(applicationId, siteId, locationId);
    }

    return state;
  }

  /**
   * Get the current shift at a specific location
   * @param {string} applicationId - Application Id
   * @param {string} siteId - Site Id of location
   * @param {string} locationId - The id of the location
   * @returns {ShiftInstanceWithDate} - The current shift
   */
  public async currentShift(applicationId: string, siteId: string, locationId: string) {
    const locationState = await this.getLocationState(applicationId, siteId, locationId);

    if (locationState.status !== "ready") {
      return undefined;
    }

    return locationState.currentShift;
  }

  /**
   * Get the selected shift at a specific location
   * @param {string} applicationId - Application Id
   * @param {string} siteId - Site Id of location
   * @param {string} locationId - The id of the location
   * @returns {ShiftInstanceWithDate} - The selected shift
   */
  public async selectedShift(applicationId: string, siteId: string, locationId: string) {
    const locationState = await this.getLocationState(applicationId, siteId, locationId);

    if (locationState.status !== "ready") {
      return undefined;
    }

    const instance = locationState.schedule?.shiftsInstances[locationState.selectedShiftIndex];

    return {
      ...instance,
      date: dayjs(instance.startDateTime).format("YYYY-MM-DD")
    };
  }

  /**
   * Select a specific shift for a location
   * @param {string} applicationId - Application Id
   * @param {string} siteId - Site Id of location
   * @param {string} locationId - The id of the location
   * @param {string} shiftId - The id of the shift to select
   * @param {string} date - The date of the shift too select
   */
  public async selectShift(
    applicationId: string,
    siteId: string,
    locationId: string,
    shiftId: string,
    date: string
  ) {
    const locationState = await this.getLocationState(applicationId, siteId, locationId);

    const nextIndex = locationState.schedule?.shiftsInstances.findIndex(
      (instance) =>
        instance.shiftId === shiftId &&
        dayjs(instance.startDateTime).format("YYYY-MM-DD") === dayjs(date).format("YYYY-MM-DD")
    );

    if (nextIndex > -1 && nextIndex <= locationState.schedule?.shiftsInstances.length - 1) {
      this.setSelectedShiftIndex(applicationId, siteId, locationId, nextIndex);
    } else {
      throw new Error(
        `No shift instance exists in the schedule with shiftId [${shiftId}] and date [${date}]`
      );
    }

    return locationState.currentShift;
  }

  /**
   * Move forward or back n number of shifts in a shift schedule. Pass negative numOfShifts to go backwards
   * @param {string} applicationId - Application Id
   * @param {string} siteId - Site Id of location
   * @param {string} locationId - The id of the location
   * @param {number} numOfShifts - Number of shifts to move through a shift schedule. Pass negative numOfShifts to go backwards
   */
  public async progressShiftIndex(
    applicationId: string,
    siteId: string,
    locationId: string,
    numOfShifts: number
  ) {
    const locationState = await this.getLocationState(applicationId, siteId, locationId);
    const nextIndex = locationState.selectedShiftIndex + numOfShifts;

    if (nextIndex > -1 && nextIndex <= locationState.schedule?.shiftsInstances.length - 1) {
      this.setSelectedShiftIndex(applicationId, siteId, locationId, nextIndex);
    } else {
      //eslint-disable-next-line
      console.warn(
        "It is not currently possible to progress into another shift interval. See L228 of packages/service-api/src/shifts/ShiftManager.ts for details"
      );
    }

    // TODO - Handle moving forward into the next shift interval
    // the way here to handle the overflow of a shift interval is, if next interval is less than 0
    // or greater than locationState.schedule.shiftIntervals.length then we need to load the
    // next/previous shift schedule and then find the index again. This will require the restructure
    // of LocationState to show an index of some other stored set of schedules
  }

  /**
   * Subscribe to updates in state
   * @param {Subscriber<ShiftManagerState>} subscriber - callback function to notify on change to state
   * @returns {Function} - An unsubscribe function
   */
  public subscribe(subscriber: Subscriber<ShiftManagerState>) {
    return this._state.subscribe(subscriber);
  }

  /**
   * Get the API endpoints to call to get shift details for a specific application
   * @param applicationId - Id of the application to find the endpoints for
   * @returns application specific endpoints. This must include the methods .getCurrentShift(), .getNextShift() and .getPreviousShift()
   */
  private applicationSpecificShiftEndpoints = (applicationId: string) => {
    switch (applicationId) {
      case applications["shift-logs"].applicationId:
        return ShiftLogsApi.Shifts;
      default:
        throw new Error(
          `No shifts endpoints are configured for the applicationId ${applicationId}`
        );
    }
  };

  /**
   * Internal helper for setting the index of the selected shift for a location
   * @param {string} applicationId - Application Id
   * @param {string} siteId - Site Id of location
   * @param {string} locationId - The id of the location
   * @param {number} index - The index of the selected shift to set in state.
   */
  private setSelectedShiftIndex(
    applicationId: string,
    siteId: string,
    locationId: string,
    index: number
  ) {
    this.setLocationState(applicationId, siteId, locationId, { selectedShiftIndex: index });
  }

  /**
   * Initialize a new version of the ShiftManager if it doesn't already exist. It also attaches it to the window in order to make it accessible
   * across applications so there is only ever one instance of the shift manager per window.
   * @returns {ShiftManager}
   */
  public static init() {
    const windowWithShiftManager = window as unknown as WindowWithShiftManager;

    if (!windowWithShiftManager.shiftManager) {
      windowWithShiftManager.shiftManager = new ShiftManager();
    }

    return windowWithShiftManager.shiftManager;
  }
}

export const shiftManager = ShiftManager.init();
