import React from "react";
import { ApplicationIcon, ApplicationIconName } from "../icons";
import { Box, ListItemIcon, Tooltip } from "@mui/material";

type BaseProps = {
  active?: boolean;
  onClick?: () => void;
  tooltip: string;
};

type ItemWithIconProps = {
  icon?: ApplicationIconName;
  label: string;
};
type ItemWithLabelProps = {
  icon?: ApplicationIconName;
  label: string;
};

type Props = BaseProps & (ItemWithIconProps | ItemWithLabelProps);

const CollapsedSidebarItem: React.FC<Props> = (props) => {
  const { active, icon, label, onClick, tooltip } = props;

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <Tooltip title={tooltip} placement="right">
      <ListItemIcon
        onClick={handleClick}
        sx={{
          mr: 0,
          minWidth: "unset",
          backgroundColor: active ? "primary.light" : "grey.200",
          borderRadius: 2,
          p: 1,
          width: 40,
          height: 36,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "black",
          "&:hover": {
            backgroundColor: "primary.light",
            color: "primary.dark",
            cursor: "pointer"
          }
        }}
      >
        {icon ? <ApplicationIcon name={icon} /> : <Box sx={{ fontWeight: "bold" }}>{label}</Box>}
      </ListItemIcon>
    </Tooltip>
  );
};

export default CollapsedSidebarItem;
