import { compare } from "fast-json-patch";

import { materialBalanceApi } from ".";
import {
  CsvTemplateReportConfig,
  CsvTemplate,
  CsvTemplateDocumentResponse,
  CsvTemplatesListResponse,
  PresignedUrlResponse,
  QueryOptions
} from "../../types";
import { urlPathWithQueryParams } from "../../utils/utils";
import { uploadAttachment } from "../shared";

/**
 * Get CSVs
 * @returns {CsvTemplatesListResponse}
 */
export const getCsvTemplates = async (
  query: Partial<CsvTemplate> = {},
  queryOptions: QueryOptions = {}
): Promise<CsvTemplatesListResponse> => {
  const templates: CsvTemplatesListResponse = await materialBalanceApi.get(
    urlPathWithQueryParams("csvTemplates", { ...query, ...queryOptions })
  );

  return templates;
};

/**
 * Get a csv template by Id
 * @param csvTemplateId {string}
 * @returns {CsvTemplateDocumentResponse}
 */
export const getCsvTemplateById = async (
  csvTemplateId: string
): Promise<CsvTemplateDocumentResponse> => {
  return materialBalanceApi.get(`csvTemplates/${csvTemplateId}`);
};

/**
 * Download CSV Template file by Id
 * @param csvTemplateId {string}
 * @returns {Blob}
 */
export const downloadTemplateFile = async (csvTemplateId: string): Promise<void> => {
  await materialBalanceApi.downloadFile(`csvTemplates/${csvTemplateId}/download`);
};

/**
 * Create a CSV Template
 * @param  csvTemplate: Partial<CsvTemplate> {string}
 * @returns {CsvTemplateDocumentResponse}
 */
export const createCsvTemplate = async (
  csvTemplate: CsvTemplate
): Promise<CsvTemplateDocumentResponse> => {
  return materialBalanceApi.post(`csvTemplates`, csvTemplate);
};

/**
 * Generate CSV Report
 * @param  csvReport: Partial<CsvReport>}
 * @returns {CsvReportDocumentResponse}
 */
export const generateCsvReport = async (
  csvTemplateId: string,
  config: Partial<CsvTemplateReportConfig>
): Promise<void> => {
  const url = `csvTemplates/${csvTemplateId}/generate?processDate=${config.processDate}&returnContents=${config.returnContents}&upload=${config.upload}`;

  if (config.returnContents) {
    await materialBalanceApi.downloadFile(url);
  }

  await materialBalanceApi.get(url);
};

/**
 * Update Csv Template
 *
 * @param csvTemplateId: @type String
 * @param previousValue: @type CsvTemplate
 * @param nextValue: @type Partial<CsvTemplate>
 *
 * @return {Promise<CsvTemplateDocumentResponse>}
 */
export const updateCsvTemplate = async (
  csvTemplateId: string,
  previousValue: CsvTemplate,
  nextValue: Partial<CsvTemplate>
): Promise<CsvTemplateDocumentResponse> => {
  const patchUpdates = compare(previousValue, { ...previousValue, ...nextValue });
  return materialBalanceApi.patch(`csvTemplates/${csvTemplateId}`, patchUpdates);
};

/**
 * Upload a new csv template file for a template
 *
 * @param {string} csvTemplateId
 * @param {File} file
 * @return {*}  {Promise<void>}
 */
export const uploadCsvTemplateFile = async (csvTemplateId: string, file: File): Promise<void> => {
  const presignedUrlResponse = await materialBalanceApi.get<PresignedUrlResponse>(
    `csvTemplates/${csvTemplateId}/presignedUrl`
  );
  const { presignedUrl } = presignedUrlResponse?.data ?? {};

  if (!presignedUrl) {
    throw new Error("Failed to retrieve presignedUrl for this file");
  }

  await uploadAttachment({ request: { presignedUploadUrl: presignedUrl, attachment: file } });
};

/**
 * Delete CsvTemplate by Id
 * @param csvTemplateId: @type String
 *
 * @returns {Promise<CsvTemplatesListResponse>}
 */
export const deleteCsvTemplateById = async (csvTemplateId: string): Promise<void> => {
  return materialBalanceApi.delete(`csvTemplates/${csvTemplateId}`);
};

export const CsvTemplateApi = {
  getCsvTemplates,
  getCsvTemplateById,
  downloadTemplateFile,
  generateCsvReport,
  create: createCsvTemplate,
  update: updateCsvTemplate,
  uploadTemplateFile: uploadCsvTemplateFile,
  delete: deleteCsvTemplateById
};
