import ky, { BeforeRequestHook, NormalizedOptions } from "ky";
import { KyInstance } from "ky/distribution/types/ky";
import { KyHeadersInit } from "ky/distribution/types/options";

import Auth from "../auth/auth";
import { downloadBlob, extractFileNameFromContentDisposition } from "@packages/utils";

export const setAuthenticationHeader: BeforeRequestHook = async (
  request: Request,
  _options: NormalizedOptions
) => {
  const accessToken = await Auth.getAccessToken();
  request.headers.set("Authorization", accessToken);
};

/**
 * Authenticated api instance
 */
export class ApiInstance {
  kyInstance: KyInstance;

  constructor(prefixUrl: string, skipAuth = false) {
    this.setup(prefixUrl, skipAuth);
  }

  static init(prefixUrl: string, skipAuth = false) {
    return new ApiInstance(prefixUrl, skipAuth);
  }

  setup = (prefixUrl: string, skipAuth: boolean) => {
    this.kyInstance = ky.extend({
      prefixUrl,
      hooks: {
        beforeRequest: skipAuth ? [] : [setAuthenticationHeader]
      },
      timeout: false
    });
  };

  /**
   * Parse response as JSON if it's valid, otherwise fallback
   * @param response {T} - Api response
   * @returns - parsed JSON or string
   */
  private parseResponse = <T = string>(response) => {
    try {
      return JSON.parse(response) as T;
    } catch (error) {
      return response as unknown as T;
    }
  };

  /**
   * Perform GET request
   * @param path {string} - api path (with no leading slash)
   * @param headers @type {KyHeadersInit} - add headers to the request
   * @returns {ResponseBody} - API Response
   */
  get = async <ResponseBody>(path: string, headers: KyHeadersInit = {}): Promise<ResponseBody> => {
    const responseText = await this.kyInstance.get(path, { headers }).text();
    return this.parseResponse<ResponseBody>(responseText);
  };

  /**
   * Download a file from a url
   * @param path {string} - api path (with no leading slash)
   * @param headers @type {KyHeadersInit} - add headers to the request
   * @returns {ResponseBody} - API Response
   */
  downloadFile = async (path: string, headers: KyHeadersInit = {}): Promise<void> => {
    try {
      const response = await this.kyInstance.get(path, { headers });

      const disposition =
        response.headers.get("Content-Disposition") || response.headers.get("content-disposition");
      const fileName = extractFileNameFromContentDisposition(disposition);

      await downloadBlob(fileName, await response.blob());
    } catch (error) {
      throw new Error(`Failed to download file: ${error.message}`);
    }
  };

  /**
   * Perform POST request
   * @param path {string} - api path (with no leading slash)
   * @param body {Body} - post body
   * @param headers @type {KyHeadersInit} - add headers to the request
   * @returns {ResponseBody} - API Response
   */
  post = async <Body, ResponseBody = unknown>(
    path: string,
    body: Body,
    headers: KyHeadersInit = {}
  ): Promise<ResponseBody> => {
    const responseText = await this.kyInstance.post(path, { headers, json: body }).text();

    return this.parseResponse<ResponseBody>(responseText);
  };

  /**
   * Perform File upload request
   * @param path {string} - api path (with no leading slash)
   * @param file {File} - post body
   * @param headers @type {KyHeadersInit} - add headers to the request
   * @returns {ResponseBody} - API Response
   */
  uploadFile = async <File, ResponseBody = unknown>(
    path: string,
    file: File,
    headers: KyHeadersInit = {}
  ): Promise<ResponseBody> => {
    const responseText = await this.kyInstance
      .post(path, { headers, body: file as BodyInit })
      .text();

    return this.parseResponse<ResponseBody>(responseText);
  };

  /**
   * Perform PUT request
   * @param path {string} - api path (with no leading slash)
   * @param body {Body} - put body
   * @param headers @type {KyHeadersInit} - add headers to the request
   * @returns {ResponseBody} - API Response
   */
  put = async <Body, ResponseBody = unknown>(
    path: string,
    body: Body,
    headers: KyHeadersInit = {}
  ): Promise<ResponseBody> => {
    const responseText = await this.kyInstance.put(path, { headers, json: body }).text();

    return this.parseResponse<ResponseBody>(responseText);
  };

  /**
   * Perform PATCH request
   * @param path {string} - api path (with no leading slash)
   * @param body {Body} - patch body
   * @param headers @type {KyHeadersInit} - add headers to the request
   * @returns {ResponseBody} - API Response
   */
  patch = async <Body, ResponseBody = unknown>(
    path: string,
    body: Body,
    headers: KyHeadersInit = {}
  ): Promise<ResponseBody> => {
    const responseText = await this.kyInstance.patch(path, { headers, json: body }).text();

    return this.parseResponse<ResponseBody>(responseText);
  };

  /**
   * Perform DELETE request
   * @param path {string} - api path (with no leading slash)
   * @param headers @type {KyHeadersInit} - add headers to the request
   * @returns {ResponseBody} - API Response
   */
  delete = async <ResponseBody = unknown>(
    path: string,
    headers: KyHeadersInit = {}
  ): Promise<ResponseBody> => {
    const responseText = await this.kyInstance.delete(path, { headers }).text();
    return this.parseResponse<ResponseBody>(responseText);
  };
}
