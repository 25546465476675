type LayoutField = {
  displayOrder: number;
  layoutId: string;
  key: string;
  type: "field";
};
type LayoutGroup = {
  displayOrder: number;
  layoutId: string;
  name: string;
  key?: string;
  type: "group";
  layout: (LayoutField | LayoutGroup)[];
};

type LayoutItem = LayoutField | LayoutGroup;
type Layout = LayoutItem[];

/**
 *The findFieldByPath searches for a specific layout item within a given
 *layout array based on a colon-separated path and returns the matching LayoutItem or undefined if not found.
 *After input validation, the function splits the path into keys and employs a nested function, recursiveSearch,
 *to traverse the layout. Within this function, it iterates through the layout items, comparing each layoutId with
 *the current key from the path. If a match is found and we're at the last key, the matching item is returned;
 *otherwise, if the item is a 'group', the function recursively continues the search within that group.
 *Finally, the nested function is initially invoked with the full layout and the first key.
 * @param path {string} The path to find field by
 * @param layout {Layout}
 */
export function findFieldByPath(path: string, layout: Layout): LayoutItem | undefined {
  if (!path || !layout) {
    return undefined;
  }
  const keys = path.split(":");
  function recursiveSearch(items: Layout, index: number): LayoutItem | undefined {
    if (index >= keys.length) return undefined;

    for (const item of items) {
      if (item.layoutId === keys[index]) {
        if (index === keys.length - 1) return item;
        if (item.type === "group" && item.layout) {
          return recursiveSearch(item.layout, index + 1);
        }
      }
    }
  }
  return recursiveSearch(layout, 0);
}

/**
 *The findIndexPathByField takes a colon-separated path and a layout array to find a specific layout item.
 *It returns an index-based path to that item or undefined if not found.
 *The function starts by validating inputs and handling a special case for the root.
 *It then splits the input path into keys and uses a nested function recursiveSearch to traverse the layout.
 *The recursive function iterates through the layout, comparing layoutId against the keys,
 *updating the index-based path as it goes.
 *The function returns the found path once the final key matches a 'field' type item.
 * @param path {string} The path to find the index field
 * @param layout {Layout}
 */
export function findIndexPathByField(path: string, layout: Layout): string | undefined {
  if (!path || !layout) {
    return undefined;
  }

  if (path === "root") {
    return "/";
  }

  const keys = path.split(":");

  function recursiveSearch(items: Layout, index: number, currentPath: string): string | undefined {
    if (index >= keys.length) return undefined;

    for (let i = 0; i < items.length; i++) {
      const item = items[i];

      if (item.layoutId === keys[index]) {
        const newPath = `${currentPath}/${i}`;

        if (index === keys.length - 1) {
          return `${newPath}/layout`;
        }

        if (item.type === "group" && item.layout) {
          const foundPath = recursiveSearch(item.layout, index + 1, `${newPath}/layout`);

          if (foundPath) return foundPath;
        }
      }
    }
  }

  const result = recursiveSearch(layout, 0, "");

  if (result && result.endsWith("/layout")) {
    return result.slice(0, -7);
  }

  return result;
}

/**
 * This function takes as input a hierarchical grid structure represented as an array of objects,
 * and it flattens this structure into a single-level array while preserving the path to each element in the original structure.
 * It does so by recursively traversing the structure, creating new paths, and eliminating the layout property.
 * The result is an array of objects, each with a layoutPath property indicating its position in the original nested structure.
 *  @param path {string} The path to find field by
 *  @param layout The layout of a grid
 */

export function flattenGrid(grid, path = []) {
  let result = [];

  for (const item of grid) {
    const newPath = [...path, item.layoutId];

    const transformedItem = {
      ...item,
      layoutPath: newPath
    };

    delete transformedItem.layout;

    result.push(transformedItem);

    if (item.layout && item.layout.length > 0) {
      result = result.concat(flattenGrid(item.layout, newPath));
    }
  }
  return result;
}
