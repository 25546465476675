import * as React from "react";
import clsx from "clsx";
import { useTreeItemState, TreeItemContentProps } from "@mui/x-tree-view";
import { Typography } from "@mui/material";

export interface LocationTreeItemProps extends TreeItemContentProps {
  onItemSelect: () => void;
  itemDisabled?: boolean;
}

const LocationTreeItem = React.forwardRef(function CustomContent(
  props: LocationTreeItemProps,
  ref
) {
  const {
    classes,
    className,
    label,
    itemId,
    icon: iconProp,
    expansionIcon,
    displayIcon,
    itemDisabled,
    onItemSelect
  } = props;

  const {
    disabled,
    expanded,
    selected,
    focused,
    handleExpansion,
    handleSelection,
    preventSelection
  } = useTreeItemState(itemId);

  const icon = iconProp || expansionIcon || displayIcon;

  const handleMouseDown = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    preventSelection(event);
  };

  const handleExpansionClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    handleExpansion(event);
  };

  const handleSelectionClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (disabled || itemDisabled) {
      preventSelection(event);
    } else {
      handleSelection(event);
      onItemSelect();
    }
  };

  return (
    <div
      className={clsx(className, classes.root, {
        [classes.expanded]: expanded,
        [classes.selected]: selected,
        [classes.focused]: disabled || itemDisabled ? undefined : focused
      })}
      onMouseDown={handleMouseDown}
      ref={ref as React.Ref<HTMLDivElement>}
    >
      <div onClick={handleExpansionClick} className={classes.iconContainer}>
        {icon}
      </div>
      <Typography
        onClick={handleSelectionClick}
        component="div"
        className={clsx(className, classes.label, {
          [classes.disabled]: disabled || itemDisabled
        })}
      >
        {label}
      </Typography>
    </div>
  );
});

export default LocationTreeItem;
