/* eslint-disable @typescript-eslint/no-explicit-any */
import { materialBalanceApi } from ".";
import { StatusData } from "../../types/formula";

/**
 * Validates a node formula
 * @param productNodeId {string}
 * @param formula {string}
 * @param processDateTime {string}
 * @param uom {string}
 * @returns {void}
 */
export const validateFormula = async (
  productNodeId: string,
  formula: string,
  processDateTime: string,
  uom: string
): Promise<void> => {
  return materialBalanceApi.post("formula/evaluate", {
    productNodeId,
    formula,
    processDateTime,
    uom
  });
};

/**
 * Check if formula request is valid
 * @param info {string | void}
 * @returns {void}
 */
export const checkFormulaRequest = async (info: string | void): Promise<void> => {
  const statusURL = (info as string).slice(1);
  return materialBalanceApi.get(`${statusURL}/status?requestMode=Synchronous`);
};

/**
 * Check if formula status is completed or not
 * @param info {string | void}
 * @returns {StatusData}
 */
export const checkFormulaStatus = async (info: string | void): Promise<StatusData> => {
  const statusURL = (info as string).slice(1);
  return materialBalanceApi.get(`${statusURL}/status`);
};

export const FormulasApi = {
  validateFormula,
  checkFormulaRequest,
  checkFormulaStatus
};
