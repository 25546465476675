import { onlineLogSheetsApi } from "..";
import { ListOfValuesSchemaListResponse } from "../../../types";

/**
 * Get a list of manual entry sheets by locationId
 *
 * @param {string} applicationId
 * @param {string} locationId
 * @return {Promise<ListOfValuesSchemaListResponse>}
 */
const getSheetsByLocationId = async (
  applicationId: string,
  locationId: string
): Promise<ListOfValuesSchemaListResponse> => {
  return onlineLogSheetsApi.get<ListOfValuesSchemaListResponse>(
    `manual-entry/applications/${applicationId}/locations/${locationId}/sheets`
  );
};

export const ManualEntrySheetsApi = {
  getByLocationId: getSheetsByLocationId
};
