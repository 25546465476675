import React from "react";
import { styled } from "@mui/material/styles";
import { Button, Drawer, Hidden } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { CircularProgressBar } from "../WithLoader";
import DrawerContentSection from "./DrawerContentSection";

type Props = {
  onClose: () => void;
  actions?: {
    action?: (data?: unknown) => void;
    text?: string;
    disabled?: boolean | undefined;
    variant?: "text" | "outlined" | "contained";
    color?: "error";
  }[];
  title?: React.ReactElement | string | React.ReactNode | JSX.Element;
  contentTitle?: React.ReactElement | string | React.ReactNode | JSX.Element;
  maxWidth?: number;
  width?: string | number;
  bodyText?: React.ReactElement | string | React.ReactNode | JSX.Element;
  disableBackdropClick?: boolean;
  children?: React.ReactNode;
  loading?: boolean;
  hideContent?: boolean;
};

const DrawerActions = styled("div")(() => ({
  position: "fixed",
  bottom: 0,
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "row-reverse",
  borderTop: "1px solid rgba(0, 0, 0, 0.12)"
}));

const DrawerTitle = styled("div")(() => ({
  fontWeight: 600,
  borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
  width: "100%",
  fontSize: "1.25rem",
  lineHeight: 1.6,
  letterSpacing: "0.0075em"
}));

const DrawerContent = styled("div")(({ theme }) => ({
  minWidth: "100%",
  [theme.breakpoints.up(700)]: {
    minWidth: 500
  },
  height: "calc(100% - 134px)",
  overflow: "auto"
}));

const Title = styled("div")(() => ({
  width: "calc(100% - 50px)",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  overflow: "hidden"
}));

const DrawerMain = styled(Drawer)(({ theme }) => ({
  "&.side-drawer": {
    [theme.breakpoints.up("md")]: {
      position: "relative"
    },
    minWidth: "100%",
    [theme.breakpoints.up(700)]: {
      minWidth: 500
    },
    zIndex: 1500,
    "& > .MuiDrawer-paperAnchorRight": {
      borderRadius: 0
    },
    "& .MuiDrawer-paper": {
      [theme.breakpoints.down(700)]: {
        width: "100%"
      }
    }
  }
}));

const DrawerBodyText = styled("div")(({ theme }) => ({
  margin: 0,
  fontWeight: 400,
  fontSize: "1rem",
  lineHeight: 1.5,
  letterSpacing: "0.00938em",
  color: "rgba(0, 0, 0, 0.6)",
  paddingBottom: theme.spacing(1)
}));

const Dragger = styled("div")(({ theme }) => ({
  width: 4,
  cursor: "ew-resize",
  padding: "4px 0 0",
  borderTop: "1px solid #ddd",
  position: "absolute",
  top: 0,
  left: 0,
  bottom: 0,
  zIndex: "100",
  backgroundColor: theme.palette.primary.main
}));

const MUIDrawer: React.FC<Props> = (props) => {
  const {
    onClose,
    actions,
    children,
    title,
    bodyText,
    maxWidth: mxWidth = 900,
    width: actualWidth = 500,
    contentTitle,
    disableBackdropClick,
    loading,
    hideContent
  } = props;

  const onCloseProp = !disableBackdropClick ? { onClose } : {};
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up(700));
  const maxWidth = matches ? mxWidth : "100%";
  const initialWidth = matches ? actualWidth : "100%";
  const minWidth = initialWidth;

  const [width, setWidth] = React.useState<string | number>(initialWidth);
  const [isResizing, setIsResizing] = React.useState<boolean>(false);

  const onMouseMove = (e) => {
    if (!isResizing) {
      return;
    }

    const offsetRight = document.body.offsetWidth - (e.clientX - document.body.offsetLeft);

    if (
      typeof maxWidth === "number" &&
      typeof minWidth === "number" &&
      offsetRight > minWidth &&
      offsetRight < maxWidth
    ) {
      setWidth(offsetRight);
    }
  };

  const onMouseDown = () => {
    setIsResizing(true);
  };

  const onMouseUp = () => {
    setIsResizing(false);
  };

  React.useEffect(() => {
    document.addEventListener("mouseup", onMouseUp);
    return () => {
      document.removeEventListener("mouseup", onMouseUp);
    };
  }, []);

  React.useEffect(() => {
    document.addEventListener("mousemove", onMouseMove);
    return () => {
      document.removeEventListener("mousemove", onMouseMove);
    };
  }, [isResizing]);

  return (
    <DrawerMain
      open
      className="side-drawer"
      {...onCloseProp}
      anchor="right"
      PaperProps={{ style: { width } }}
    >
      <Hidden smDown>
        <Dragger id="dragger" onMouseDown={onMouseDown} className="dragger" />
      </Hidden>
      <DrawerTitle sx={{ m: 0, p: 2, maxWidth, width }}>
        <Title>{title}</Title>
        {onClose ? (
          <IconButton
            size="medium"
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 13,
              color: (theme) => theme.palette.grey[500]
            }}
          >
            <CloseIcon color="primary" fontSize="medium" />
          </IconButton>
        ) : null}
      </DrawerTitle>
      <DrawerContent sx={{ m: 0, p: 2, maxWidth, width, position: "relative" }}>
        {bodyText && <DrawerBodyText>{bodyText}</DrawerBodyText>}
        {contentTitle ? (
          <>
            {loading && <CircularProgressBar />}
            {!hideContent && (
              <DrawerContentSection loading={loading} title={contentTitle}>
                {children}
              </DrawerContentSection>
            )}
          </>
        ) : (
          children
        )}
      </DrawerContent>
      {actions && (
        <>
          <DrawerActions sx={{ m: 0, p: 2, maxWidth, width }}>
            {actions.map((action, i) => {
              if (!action?.text) return <div key={1} />;
              return (
                <Button
                  key={`${i}-text`}
                  onClick={action.action}
                  disabled={action.disabled}
                  variant={action.variant || i ? "outlined" : "contained"}
                  color={action.color}
                >
                  {action.text}
                </Button>
              );
            })}
          </DrawerActions>
        </>
      )}
    </DrawerMain>
  );
};

MUIDrawer.defaultProps = {
  disableBackdropClick: true,
  loading: false,
  hideContent: false
};

export default MUIDrawer;
