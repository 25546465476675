export const formatFileSize = (fileSizeInBytes: number): string => {
  const byteUnits = [" kB", " MB", " GB", " TB", "PB", "EB", "ZB", "YB"];

  let index = -1;

  do {
    fileSizeInBytes /= 1024;
    index++;
  } while (fileSizeInBytes > 1024);

  return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[index];
};
