import React from "react";
import Auth from "../auth/auth";
import { useUserProfile } from "../hooks/auth";
import NoPermissionsAlert from "./NoPermissionsAlert";

type Props = {
  applicationId?: string;
  children?: React.ReactElement;
  component?: React.ReactElement;
  hideNoPermissionsUI?: boolean;
  locationId?: string;
  noPermissionsComponent?: React.ReactElement;
  permissions: string[] | string;
  redirect?: boolean;
  skipSiteLocationCheck?: boolean;
  hidePermissionsAlert?: boolean;
};

const WithPermissions: React.FC<Props> = (props) => {
  const profile = useUserProfile();

  const siteId = React.useMemo(
    () => profile?.preferences?.selectedSiteId || profile?.siteId,
    [profile]
  );

  if (!siteId) {
    return null;
  }

  const {
    applicationId,
    children,
    component,
    hideNoPermissionsUI,
    locationId,
    noPermissionsComponent,
    permissions,
    redirect,
    hidePermissionsAlert,
    skipSiteLocationCheck
  } = props;

  if (children && component) {
    throw new Error("<WithPermissions /> accepts only one of [children, component]");
  }

  const permissionsArray = Array.isArray(permissions) ? permissions : [permissions];

  if (
    !Auth.checkPermission({
      applicationId,
      permissions: permissionsArray,
      locationId,
      siteId,
      skipSiteLocationCheck
    })
  ) {
    if (redirect) {
      window.location.replace("/unauthorized");
    }

    if (noPermissionsComponent) {
      return noPermissionsComponent;
    } else if (!hideNoPermissionsUI) {
      return (
        !hidePermissionsAlert && (
          <NoPermissionsAlert
            permissions={permissionsArray}
            locationId={locationId}
            applicationId={applicationId}
          />
        )
      );
    } else {
      return null;
    }
  }

  return component || children;
};

export default WithPermissions;
