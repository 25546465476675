import React from "react";
import { Breakpoint } from "@mui/material";
import { Helmet } from "react-helmet";

import { Application } from "@nutrien-operations/config";
import { Location } from "@packages/service-api";
import PageContainerContent from "./PageContainerContent";
import { useAppSidebar } from "../AppSidebar/state";

interface Props {
  appConfig?: Application;
  children: React.ReactNode;
  content?: string;
  description?: string;
  hideToolbars?: boolean;
  maxWidth?: false | Breakpoint;
  title?: string;
  hideLocationbar?: boolean;
  onLocationChange?: (nextLocation: Location) => void;
}

export const PageContainer = (props: Props) => {
  const { children, content, description, hideToolbars, title, onLocationChange, hideLocationbar } =
    props;

  const { appConfig } = useAppSidebar();
  return (
    <>
      <Helmet>
        <title>{title ? title + " | Nutrien Operations" : "Nutrien Operations"}</title>
        {description && content && <meta name={description} content={content} />}
      </Helmet>

      <PageContainerContent
        onLocationChange={onLocationChange}
        appConfig={appConfig}
        hideLocationbar={hideLocationbar}
        hideToolbars={hideToolbars}
      >
        {children}
      </PageContainerContent>
    </>
  );
};
