import { Component, ErrorInfo, ReactElement } from "react";
import { AppProps } from "single-spa";
import { Alert, AlertTitle, Box } from "@mui/material";

export class ErrorBoundary extends Component<{ children: ReactElement }, { error: object | null }> {
  constructor(props) {
    super(props);

    this.state = {
      error: null
    };
  }

  static getDerivedStateFromError(error) {
    return { error };
  }

  componentDidCatch(error, errorInfo) {
    // eslint-disable-next-line no-console
    console.error(error, errorInfo);
  }

  render() {
    if (this.state.error) {
      return <h1>Something went wrong.</h1>;
    }

    return this.props.children;
  }
}

export function errorBoundary(error: Error, errorInfo: ErrorInfo, _props: AppProps): ReactElement {
  return (
    <ErrorBoundary>
      <Box padding={10}>
        <Alert severity="error">
          <AlertTitle>{error.message}</AlertTitle>
          <pre>{errorInfo.componentStack}</pre>
        </Alert>
      </Box>
    </ErrorBoundary>
  );
}
