import { ApiInstance } from "./api-instance";
import { ShiftInstanceDocumentResponse } from "../types";

/**
 * Get current shift for a location
 * @param locationId {string}
 * @returns {ShiftInstanceDocumentResponse}
 */
export const getCurrentShift = async (
  api: ApiInstance,
  locationId: string
): Promise<ShiftInstanceDocumentResponse> => {
  return api.get(`locations/${locationId}/shifts/current`);
};

/**
 * Get the next shift for a location after a specified shift
 * @param locationId {string}
 * @returns {ShiftInstanceDocumentResponse}
 */
export const getNextShift = async (
  api: ApiInstance,
  locationId: string,
  shiftId: string
): Promise<ShiftInstanceDocumentResponse> => {
  return api.get(`locations/${locationId}/shifts/${shiftId}/next`);
};
/**
 * Get the previous shift for a location after a specified shift
 * @param locationId {string}
 * @returns {ShiftInstanceDocumentResponse}
 */
export const getPreviousShift = async (
  api: ApiInstance,
  locationId: string,
  shiftId: string
): Promise<ShiftInstanceDocumentResponse> => {
  return api.get(`locations/${locationId}/shifts/${shiftId}/previous`);
};

export const CommonApplicationShifts = (apiInstance: ApiInstance) => ({
  getCurrentShift: (locationId: string) => getCurrentShift(apiInstance, locationId),
  getNextShift: (locationId: string, shiftId: string) =>
    getNextShift(apiInstance, locationId, shiftId),
  getPreviousShift: (locationId: string, shiftId: string) =>
    getPreviousShift(apiInstance, locationId, shiftId)
});
