import { acceleratorApi } from ".";
import { urlPathWithQueryParams } from "../../utils";

import { AcceleratorSitesListResponse, SitesSummaryListResponse } from "../../types";

/**
 * Get Sites
 * @return {Promise<AcceleratorSitesListResponse>}
 */
export const getSites = async (): Promise<AcceleratorSitesListResponse> => {
  return acceleratorApi.get(urlPathWithQueryParams("sites", { page: 0, size: 1000 }));
};
/**
 * Get SitesSummary
 * @return {Promise<SitesSummaryListResponse>}
 */
export const getSitesSummaries = async (): Promise<SitesSummaryListResponse> => {
  return acceleratorApi.get("sites/summaries");
};

export const SitesApi = {
  get: getSites,
  getSummary: getSitesSummaries
};
