import { TextField as MUITextField, TextFieldProps } from "@mui/material";
import { Control, Controller, Path } from "react-hook-form";
import { FieldValues } from "react-hook-form/dist/types/fields";

export type TextFieldElementProps<T> = Omit<TextFieldProps, "name"> & {
  /* React hook form control  */
  control?: Control<T>;
  /* Element to display at the end of the input  */
  endAdornment?: React.ReactNode;
  /* Name (key) of field in the form  */
  name: Path<T>;
  /* Call onChange with null when the text input is empty (ie: "") */
  nullEmptyValue?: boolean;
  /* Element to display at the beginning of the input  */
  startAdornment?: React.ReactNode;
};

function TextField<TFieldValues extends FieldValues>({
  control,
  disabled,
  endAdornment,
  helperText,
  inputProps,
  label,
  name,
  nullEmptyValue,
  placeholder,
  required,
  startAdornment,
  sx,
  type,
  rows = "",
  multiline = false,
  variant
}: TextFieldElementProps<TFieldValues>): JSX.Element {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange, onBlur }, fieldState: { invalid, error } }) => {
        const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
          let value = e.target.value;

          if (nullEmptyValue && value === "") {
            onChange(null);
            return;
          }

          if (type === "number") {
            const splitValue = value.split(".");
            // allow only number and dot
            value = value.replace(/[^0-9\.]/g, ""); //eslint-disable-line
            // allow only one dot
            if (splitValue.length > 2) {
              value = value.replace(/\.+$/, "");
            }
            // convert back to string
            if (splitValue.length === 2) {
              // after dot allow max 3 digit
              splitValue[1] = splitValue[1].substring(0, 3);
              value = splitValue.join(".");
            }
          }
          e.target.value = value;
          onChange(e);
        };

        return (
          <MUITextField
            sx={sx}
            rows={rows}
            multiline={multiline}
            variant={variant || "standard"}
            name={name}
            value={value ?? ""}
            onChange={onInputChange}
            onBlur={onBlur}
            required={required}
            disabled={disabled}
            type="text"
            error={invalid}
            label={label}
            fullWidth
            InputLabelProps={{ shrink: true }}
            inputProps={inputProps}
            InputProps={{
              endAdornment,
              placeholder,
              startAdornment
            }}
            helperText={error ? error.message : helperText}
          />
        );
      }}
    />
  );
}

export default TextField;
