import React from "react";

import { useSelectedLocation } from "../../auth";
import { LocationState, shiftManager } from "../../../shifts";

export const useLocationState = (applicationId: string) => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [state, setLocationState] = React.useState<LocationState>();

  const { selectedLocation } = useSelectedLocation(applicationId);

  React.useEffect(() => {
    const getLocationState = async () => {
      try {
        setLoading(true);
        const locationState = await shiftManager.getLocationState(
          applicationId,
          selectedLocation.siteId,
          selectedLocation.locationId
        );

        setLocationState(locationState);
      } finally {
        setLoading(false);
      }
    };

    if (selectedLocation) {
      getLocationState();

      return shiftManager.subscribe(getLocationState);
    }
  }, [selectedLocation]);

  return {
    loading,
    state
  };
};
