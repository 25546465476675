import { compare } from "fast-json-patch";
import { ApiSingleDocumentResponse, QueryOptions } from "../../types";
import { Location, LocationDocumentResponse, LocationListResponse } from "../../types/location";
import { urlPathWithQueryParams } from "../../utils/utils";
import { commonDataApi } from ".";

export type GetLocationsQueryOptions = QueryOptions & { includeApplicationRoot?: boolean };

const getLocations = async (
  applicationId: string,
  siteId: string,
  query: Partial<Location> = {},
  queryOptions: GetLocationsQueryOptions = {}
): Promise<LocationListResponse> => {
  return await commonDataApi.get(
    urlPathWithQueryParams(`applications/${applicationId}/sites/${siteId}/locations`, {
      ...query,
      ...queryOptions
    })
  );
};

/**
 * Get locations details
 * @param locationIds {string[]}
 * @returns {LocationListResponse}
 */
const getLocationsByIds = async (
  locationIds: string[],
  queryOptions: QueryOptions = {}
): Promise<LocationListResponse> => {
  return await commonDataApi.get(
    urlPathWithQueryParams(`locations`, {
      ...queryOptions,
      locationId: locationIds.join()
    })
  );
};

/**
 * Create new location
 * @param applicationId {string}
 * @param siteId {string}
 * @param location {Location}
 * @returns {LocationDocumentResponse}
 */
const createLocation = async (
  applicationId: string,
  siteId: string,
  location: Location
): Promise<LocationDocumentResponse> => {
  return await commonDataApi.post(
    `applications/${applicationId}/sites/${siteId}/locations`,
    location
  );
};

/**
 * Delete location
 * @param applicationId {string}
 * @param siteId {string}
 * @param locationId {string}
 * @returns {void}
 */
const deleteLocation = async (
  applicationId: string,
  siteId: string,
  locationId: string
): Promise<void> => {
  return await commonDataApi.delete(
    `applications/${applicationId}/sites/${siteId}/locations/${locationId}`
  );
};

/**
 * patch Location
 * @param applicationId {string}
 * @param siteId {string}
 * @param previousLocation {Location}
 * @param location {Partial<Location>}
 * @returns {LocationDocumentResponse}
 */
const patchLocation = async (
  applicationId: string,
  siteId: string,
  previousLocation: Location,
  location: Partial<Location>
): Promise<LocationDocumentResponse> => {
  const patchUpdates = compare(previousLocation, { ...previousLocation, ...location });
  if (patchUpdates.length) {
    return commonDataApi.patch(
      `applications/${applicationId}/sites/${siteId}/locations/${previousLocation.locationId}`,
      patchUpdates
    );
  }
};

/**
 * change Location order
 * @param applicationId {string}
 * @param siteId {string}
 * @param locationId {string}
 * @param locationIds {string[]}
 * @returns {void}
 */
const changeLocationOrder = async (
  applicationId: string,
  siteId: string,
  locationId: string,
  locationIds: string[]
): Promise<void> => {
  return commonDataApi.post(
    `applications/${applicationId}/sites/${siteId}/locations/${locationId}/order`,
    locationIds
  );
};

/**
 * Fetch a single location
 * @param locationId {string} id of location to fetch
 * @returns {Location} location
 */
const getLocationById = async (locationId: string): Promise<ApiSingleDocumentResponse<Location>> =>
  commonDataApi.get(`locations/${locationId}`);

/**
 * Fetch locations by application id
 * @param applicationId {string} id of application the location belongs to
 * @returns {Location[]} locations
 */
const getLocationsByApplicationId = async (
  applicationId: string,
  queryOptions: QueryOptions = {}
): Promise<LocationListResponse> =>
  commonDataApi.get(
    urlPathWithQueryParams(`applications/${applicationId}/locations`, {
      ...queryOptions
    })
  );

export const LocationsApi = {
  get: getLocations,
  getOne: getLocationById,
  getLocationsByIds,
  getByApplicationId: getLocationsByApplicationId,
  update: patchLocation,
  delete: deleteLocation,
  create: createLocation,
  changeOrder: changeLocationOrder
};
