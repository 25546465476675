import { legacyAcceleratorApi } from ".";
import {
  VEmptyTemplate,
  VEmptyTemplateListResponse
} from "../../types/accelerator/vempty-templates";

/**
 * Get VEmptyTemplate
 * @return {Promise<VEmptyTemplateListResponse>}
 */
export const getVEmptyTemplates = async (): Promise<VEmptyTemplateListResponse> => {
  const data: VEmptyTemplate[] = await legacyAcceleratorApi.get<VEmptyTemplate[]>(
    `vemptytemplates`
  );

  const response: VEmptyTemplateListResponse = {
    data,
    hasMore: true,
    total: Infinity
  };

  return response;
};

export const VEmptyTemplateApi = {
  getVEmptyTemplates
};
