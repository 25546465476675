import React from "react";
import { Paper, PaperProps } from "@mui/material";

type Props = PaperProps & {
  children: React.ReactNode | React.ReactNode[];
};

export const MessageBox: React.FC<Props> = ({ children, sx, ...restProps }) => {
  return (
    <Paper
      sx={{
        minHeight: 100,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: 2,
        animation: "cssAnimation 0s 0.2s forwards",
        opacity: 0,
        "@keyframes cssAnimation": {
          to: {
            opacity: 1
          }
        },
        ...sx
      }}
      {...restProps}
    >
      {children}
    </Paper>
  );
};
