import { potashReportingApi } from ".";
import { PotashReportsResponse } from "../../types/potash-reports";

/**
 * Gets reports
 * @returns {PotashReportsResponse}
 */
export const getPotashReports = async (locationId: string): Promise<PotashReportsResponse> => {
  return await potashReportingApi.get(`getreportlist/location/${locationId}`);
};

export const ReportsApi = {
  getPotashReports: getPotashReports
};
