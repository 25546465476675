import Config from "@nutrien-operations/config";

import { ApiInstance } from "../api-instance";

import { ReportsApi } from "./report";
import { ReportSubscriptionsApi } from "./report-subscription";
import { SiteReportSubscriptionsApi } from "./site-report-subscription";
import { TabPermissionsApi } from "./tab-permission";
import { SectionPermissionsApi } from "./section-permission";
import { ReportCommentsApi } from "./report-comments";
import { HrTemplateApi } from "./hr-templates";
import { AppoverPermissionsApi } from "./approver-permission";

export class PotashReportingApi {
  protected _instance: ApiInstance | null = null;

  constructor() {
    const prefixUrl = Config.API["potash-reporting"];
    this._instance = new ApiInstance(prefixUrl);
  }

  getInstance = () => this._instance;

  static Reports = ReportsApi;
  static ReportSubscriptions = ReportSubscriptionsApi;
  static Subscriptions = SiteReportSubscriptionsApi;
  static Tabs = TabPermissionsApi;
  static Sections = SectionPermissionsApi;
  static ReportComments = ReportCommentsApi;
  static HrTemplates = HrTemplateApi;
  static ApproverPermissions = AppoverPermissionsApi;
}

export const potashReportingApi = new PotashReportingApi().getInstance();
