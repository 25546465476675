export interface Node {
  children?: Node[];
}
/**
 * Convert flat data to tree
 * @param list {Node[]}
 * @param id {string}
 * @param parentId {string}
 * @return {Node}
 */
export const listToTree = (list: Node[], id: string, parentId: string) => {
  const map = {};
  const roots = [];
  let node, i;

  for (i = 0; i < list.length; i += 1) {
    map[list[i][id]] = i; // initialize the map
    list[i].children = []; // initialize the children
  }

  for (i = 0; i < list.length; i += 1) {
    node = list[i];
    if (map[node[parentId]] !== undefined) {
      list[map[node[parentId]]].children.push(node);
    } else {
      roots.push(node);
    }
  }
  return roots;
};
