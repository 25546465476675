import { compare } from "fast-json-patch";
import {
  ListOfValuesSchema,
  ListOfValuesSchemaListResponse,
  QueryOptions,
  CreateListOfValuesSchemaBody,
  ListOfValuesSchemaDocumentResponse,
  ListOfValuesField,
  ListOfValuesSchemaPermission,
  Layout,
  ListOfValuesSchemaItemsListResponse
} from "../../types";

import { LayoutItem } from "../../types/common-data/list-of-values/schema/layout";
import { urlPathWithQueryParams } from "../../utils";

import { listOfValuesApi } from ".";
import { findIndexPathByField } from "@packages/utils";

/*
 * Get a list of schemas
 * @param {string} siteId
 * @param {string} searchTerm
 * @param {boolean} allSites
 * @param {QueryOptions} [queryOptions={}]
 * @return {Promise<PeopleListResponse>}
 */
const getSchemas = async (
  query?: Partial<ListOfValuesSchema>,
  queryOptions: QueryOptions = {}
): Promise<ListOfValuesSchemaListResponse> => {
  const baseQuery = {
    ...query,
    from: queryOptions?.page || 0,
    size: queryOptions?.size || 10
  };
  return listOfValuesApi.get(urlPathWithQueryParams("lov", baseQuery));
};

/**
 * Get Schema By schemaId or schemaKey
 * @param idOrKey {string}
 * @returns {SchemaDocumentResponse}
 */
export const getSchemaByIdOrKey = async (
  idOrKey: string
): Promise<ListOfValuesSchemaDocumentResponse> => {
  return listOfValuesApi.get(`lov/${idOrKey}`);
};

/**
 * Get schema items by `schemaId` or `schemaKey`.
 *
 * @param idOrKey {string}
 * @returns {ListOfValuesSchemaItemsListResponse}
 */
export const listSchemaItemsByIdOrKey = async (
  idOrKey: string
): Promise<ListOfValuesSchemaItemsListResponse> => {
  return listOfValuesApi.get(`lov/${idOrKey}/items`);
};

/**
 * Create new schema
 * @param schema {CreateListOfValuesSchemaBody}
 * @returns {SchemaDocumentResponse}
 */
export const createSchema = async (
  schema: CreateListOfValuesSchemaBody
): Promise<ListOfValuesSchemaDocumentResponse> => {
  return listOfValuesApi.post("lov", schema);
};

/**
 * Edit Schema schema
 * @param idOrKey {string} schemaId or schemaKey
 * @param actionKey {string} action key
 * @returns {SchemaDocumentResponse}
 */
export const updateSchema = async (
  idOrKey: string,
  previousSchema: CreateListOfValuesSchemaBody,
  updates: Partial<CreateListOfValuesSchemaBody>
): Promise<ListOfValuesSchemaDocumentResponse> => {
  const patchUpdates = compare(previousSchema, updates);
  return await listOfValuesApi.patch(`lov/${idOrKey}`, patchUpdates);
};

/**
 * Delete a schema
 * @param idOrKey {string} schemaId or schemaKey
 * @returns {void}
 */
export const deleteSchema = async (idOrKey: string): Promise<void> => {
  return listOfValuesApi.delete(`lov/${idOrKey}`);
};

/**
 * Create new field on a schema
 * @param schema {CreateListOfValuesSchemaBody}
 * @returns {SchemaDocumentResponse}
 */
export const createSchemaField = async (
  schemaId: string,
  field: Partial<ListOfValuesField>
): Promise<ListOfValuesSchemaDocumentResponse> => {
  return listOfValuesApi.post(`lov/${schemaId}/fields`, field);
};

/**
 * Delete field from schema
 * @param idOrKey {string} schemaId or schemaKey
 * @param fieldKey {string} key of field to delete
 * @returns {SchemaDocumentResponse}
 */
export const deleteSchemaField = async (
  idOrKey: string,
  fieldKey: string
): Promise<ListOfValuesSchemaDocumentResponse> => {
  return listOfValuesApi.delete(`lov/${idOrKey}/fields/${fieldKey}`);
};

/**
 * Update field from schema
 * @param idOrKey {string} schemaId or schemaKey
 * @param fieldKey {string} key of field to delete
 * @returns {SchemaDocumentResponse}
 */
export const updateSchemaField = async (
  idOrKey: string,
  fieldKey: string,
  previousField: ListOfValuesField,
  field: Partial<ListOfValuesField>
): Promise<ListOfValuesSchemaDocumentResponse> => {
  let patchUpdates;
  const { manualEntry } = field;

  if (manualEntry) {
    const { notExpected, expected, notAllowed, ...restField } = manualEntry;

    const { manualEntry: entry } = previousField;

    const {
      notExpected: preNotExpected,
      expected: preExpected,
      notAllowed: preNotAllowed,
      ...preRestField
    } = entry;

    const isUpdated =
      notExpected?.length !== preNotExpected?.length ||
      expected?.length !== preExpected?.length ||
      notAllowed?.length !== preNotAllowed?.length;
    const newData = { ...field, manualEntry: isUpdated ? restField : field.manualEntry };
    const previousData = {
      ...previousField,
      manualEntry: isUpdated ? preRestField : previousField.manualEntry
    };

    patchUpdates = compare(previousData, newData);

    if (isUpdated) {
      patchUpdates.push(
        {
          op: "replace",
          value: notExpected,
          path: "manualEntry/notExpected"
        },
        {
          op: "replace",
          value: expected,
          path: "manualEntry/expected"
        },
        {
          op: "replace",
          value: notAllowed,
          path: "manualEntry/notAllowed"
        }
      );
    }
  } else {
    patchUpdates = compare(previousField, field);
  }

  return listOfValuesApi.patch(`lov/${idOrKey}/fields/${fieldKey}`, patchUpdates);
};

/**
 * Update or Add layout from schema
 * @param schemaId {string} schemaId or schemaKey
 * @param itemPathKey {string} itemPathKey
 * @param action {string} action to be executed. Could be add or update.
 * @param value {Partial<LayoutItem>} a layout item
 * @param currentGridLayout {Layout} key of field to be add/update
 * @returns {SchemaDocumentResponse}
 */
export const updateSchemaGridLayout = (
  schemaId: string,
  itemPathKey: string,
  action: string,
  value: Partial<LayoutItem>,
  currentGridLayout: Layout
): Promise<ListOfValuesSchemaDocumentResponse> => {
  let path = "";

  // In case of add a new grid to the root of the table. The path should be the root as well. eg: path:"/-"
  if (action === "add" && itemPathKey === "root") {
    path = "/-";
  }

  // In case the action is an "add" operation. We should add "/layout/-" to the patch json add a new element to the end of the object. eg:
  // If the return of findIndexPathByField is "/1/layout/0" then, in order to add, it needs to be like "/1/layout/0/layout/-"
  else if (action === "add") {
    path = findIndexPathByField(itemPathKey, currentGridLayout);
    path = `${path}/layout/-`;
  }

  // In caso the layout is not an "add", in other words, is a "edit" we don't need add anything to the end of the path. eg: "/1/layout/0"
  else {
    path = findIndexPathByField(itemPathKey, currentGridLayout);
  }

  const nextGridLayout = {
    op: action,
    path: path,
    value: value
  };

  return listOfValuesApi.patch(`lov/${schemaId}/layout/grid`, [nextGridLayout]);
};

export const updateSchemaFormLayout = (
  schemaId: string,
  prevLayout: Layout,
  layout: Layout
): Promise<ListOfValuesSchemaDocumentResponse> => {
  const patchUpdates = compare(prevLayout, layout);
  return listOfValuesApi.patch(`lov/${schemaId}/layout/form`, patchUpdates);
};

/**
 * Update permissions from schema
 * @param idOrKey {string} schemaId or schemaKey
 * @param actionKey {string} action key
 * @returns {SchemaDocumentResponse}
 */
export const updateSchemaPermissions = async (
  idOrKey: string,
  actionType: string,
  previousPermissions: ListOfValuesSchemaPermission[],
  permissions: Partial<ListOfValuesSchemaPermission[]>
): Promise<ListOfValuesSchemaDocumentResponse> => {
  const patchUpdates = compare(previousPermissions, permissions);
  return listOfValuesApi.patch(`lov/${idOrKey}/permissions/${actionType}`, patchUpdates);
};

export const SchemasApi = {
  create: createSchema,
  update: updateSchema,
  delete: deleteSchema,
  createSchemaField: createSchemaField,
  deleteSchemaField: deleteSchemaField,
  updateSchemaField: updateSchemaField,
  updateSchemaPermissions: updateSchemaPermissions,
  get: getSchemas,
  getByIdOrKey: getSchemaByIdOrKey,
  listSchemaItemsByIdOrKey: listSchemaItemsByIdOrKey,
  updateSchemaFormLayout: updateSchemaFormLayout,
  updateSchemaGridLayout: updateSchemaGridLayout
};
