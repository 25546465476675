import * as React from "react";
import { Box, Paper, Typography, IconButton, CircularProgress } from "@mui/material";
import { styled } from "@mui/material/styles";

import { Action } from "./types";
import { ToolbarActions } from "./ToolbarActions";
import { ApplicationIcon } from "../icons";

interface Props {
  children?: React.ReactNode;
  goBack?: () => void;
  leftActions?: Action[];
  loading?: boolean;
  rightActions?: Action[];
  title?: React.ReactNode | string;
  subtitle?: React.ReactNode | string;
}

const Item = styled("div")(({ theme }) => ({
  display: "flex",
  padding: theme.spacing(1),
  alignItems: "center",
  [theme.breakpoints.down("sm")]: {
    marginLeft: 0
  }
}));

const Wrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flex: 1,
  justifyContent: "space-between",
  alignItems: "center",
  margin: theme.spacing(1)
}));

const PageToolbar: React.FC<Props> = (props: Props) => {
  const { title, subtitle, rightActions, leftActions, children, goBack, loading } = props;
  return (
    <Box my={2}>
      <Paper>
        {loading && (
          <Wrapper sx={{ justifyContent: "center" }}>
            <Item>
              <CircularProgress size={28} sx={{ m: 1 }} />
            </Item>
          </Wrapper>
        )}

        {!loading && (!!rightActions || !!leftActions || !!title) && (
          <Wrapper>
            <Item sx={{ ml: goBack !== undefined ? 0 : 1 }}>
              {goBack && (
                <IconButton onClick={goBack} sx={{ mr: 1 }} color="primary">
                  <ApplicationIcon name="chevron-left" />
                </IconButton>
              )}
              {leftActions && (
                <Item>
                  <ToolbarActions actions={leftActions} justifyContent="flex-start" />
                </Item>
              )}
              {title && (
                <Box>
                  <Typography className="title" variant="h5">
                    {title}
                  </Typography>
                  {subtitle && (
                    <Typography className="subtitle1" variant="body1">
                      {subtitle}
                    </Typography>
                  )}
                </Box>
              )}
            </Item>
            {rightActions && (
              <Item>
                <ToolbarActions actions={rightActions} justifyContent="flex-end" />
              </Item>
            )}
          </Wrapper>
        )}

        {!loading && !!children && (
          <Box p={2} sx={{ display: "flex", justifyContent: "space-between" }}>
            {children}
          </Box>
        )}
      </Paper>
    </Box>
  );
};

export default PageToolbar;
