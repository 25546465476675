import { potashReportingApi } from ".";
import {
  ExistingFileResponse,
  PresignedUrlResponse,
  UploadedFile,
  UploadedFileResponse
} from "../../types";
import { uploadAttachment } from "../shared";
/**
 * Upload a new hr template file for a template
 *
 * @param {string} locationId
 * @param {File} file
 * @param {string} startDate
 * @return {*}  {Promise<ExistingFileResponse>}
 */
export const uploadHrTemplateFile = async (
  uploadFile: UploadedFile
): Promise<ExistingFileResponse> => {
  const presignedUrlResponse = await potashReportingApi.post(
    `generate-upload-presignedUrl`,
    uploadFile
  );
  const { presignedUrl } = presignedUrlResponse["data"];

  if (!presignedUrl) {
    throw new Error("Failed to retrieve presignedUrl for this file");
  }

  await uploadAttachment({
    request: { presignedUploadUrl: presignedUrl, attachment: uploadFile.file }
  });
  return presignedUrlResponse["data"];
};

/**
 * Get uploaded file details
 * @param locationId {string}
 * @param reportTypeId {number}
 * @param sectionType {string}
 * @param startDate {string}
 * @param monthNumber {number}
 * @returns {UploadedFileResponse}
 */
export const getUploadedFileDetails = async (
  locationId: string,
  reportTypeId: number,
  sectionType: string,
  startDate: string,
  monthNumber: number
): Promise<UploadedFileResponse> => {
  return await potashReportingApi.get(
    `location/${locationId}/start-date/${startDate}/report-type/${reportTypeId}/section-type/${sectionType}/month-number/${monthNumber}/uploaded-file-details`
  );
};

/**
 * Move file to drive
 * @param locationId {string}
 * @param uploadedDataId {number}
 * @returns {boolean}
 */
export const moveFileToDrive = async (
  locationId: string,
  uploadedDataId: number
): Promise<boolean> => {
  return await potashReportingApi.get(
    `location/${locationId}/uploaded-dataId/${uploadedDataId}/s3-to-smb`
  );
};

/**
 * Download Template file
 * @param locationId {string}
 * @param reportTypeId {number}
 * @param sectionType {string}
 * @param startDate {string}
 * @returns {ExistingFileResponse}
 */
export const downloadTemplateFile = async (
  locationId: string,
  reportTypeId: number,
  sectionType: string,
  startDate: string
): Promise<ExistingFileResponse> => {
  return await potashReportingApi.get(
    `location/${locationId}/start-date/${startDate}/section-type/${sectionType}/report-type/${reportTypeId}/download-template`
  );
};

/**
 * Download Existing file
 * @param locationId {string}
 * @param reportTypeId {number}
 * @param sectionType {string}
 * @param startDate {string}
 * @returns {PresignedUrlResponse}
 */
export const downloadExistingFile = async (
  locationId: string,
  uploadedFileId
): Promise<ExistingFileResponse> => {
  return await potashReportingApi.get(
    `location/${locationId}/uploaded-dataId/${uploadedFileId}/uploaded-inputData-presignedUrl`
  );
};

/**
 * Download template
 * @param locationId {string}
 * @param sectionType {string}
 * @returns {PresignedUrlResponse}
 */
export const downloadTemplate = async (
  locationId: string,
  sectionType: string
): Promise<PresignedUrlResponse> => {
  return await potashReportingApi.get(`location/${locationId}/section-type/${sectionType}/generate-template-presignedUrl
 `);
};

export const HrTemplateApi = {
  downloadTemplateFile: downloadTemplateFile,
  downloadTemplate: downloadTemplate,
  getUploadedFileDetails: getUploadedFileDetails,
  uploadTemplateFile: uploadHrTemplateFile,
  downloadExistingFile: downloadExistingFile,
  moveFileToDrive: moveFileToDrive
};
