export default {
  // ========== BELOW are STYLE overrides for all font within our components. ==========
  fontFamily: "'Open Sans', sans-serif",
  h1: { fontSize: "40px", lineHeight: "110%", fontWeight: 300 },
  h2: { fontSize: "34px", lineHeight: "110%", fontWeight: 300 },
  h3: { fontSize: "28px", lineHeight: "110%", fontWeight: 300 },
  h4: { fontSize: "24px", lineHeight: "150%", fontWeight: 300 },
  h5: { fontSize: "20px", lineHeight: "150%", fontWeight: 300 },
  h6: { fontSize: "16px", lineHeight: "150%", fontWeight: 300 },
  subtitle1: { fontSize: "16px" },
  subtitle2: { fontSize: "14px", fontWeight: 600 },
  body1: { fontSize: "16px" },
  body2: { fontSize: "14px" },
  button: { fontSize: "14px", fontWeight: 700 },
  caption: { fontSize: "12px" },
  overline: { fontSize: "10px" }
};
