import { listOfValuesApi } from ".";
import {
  ListOfValuesItem,
  ListOfValuesItemDocumentResponse,
  ListOfValuesItemListResponse,
  ListOfValuesSchema
} from "../../types";
import { getPatchJsonOperations } from "../../utils";

export const getItemsBySchemaId = async (
  schemaIdOrKey: string
): Promise<ListOfValuesItemListResponse> => {
  return listOfValuesApi.get(`lov/${schemaIdOrKey}/items`);
};
export const getItemByItemId = async (
  schemaIdOrKey: string,
  itemId: string
): Promise<ListOfValuesItemDocumentResponse> => {
  return listOfValuesApi.get(`lov/${schemaIdOrKey}/items/${itemId}`);
};

export const createItem = async (
  schemaIdOrKey: string,
  itemAttributes: ListOfValuesItem["attributes"]
): Promise<ListOfValuesItemDocumentResponse> => {
  return listOfValuesApi.post(`lov/${schemaIdOrKey}/items`, { attributes: itemAttributes });
};

export const deleteItem = async (
  schemaIdOrKey: string,
  itemId: string
): Promise<ListOfValuesItemDocumentResponse> => {
  return listOfValuesApi.delete(`lov/${schemaIdOrKey}/items/${itemId}`);
};

export const updateItem = async (
  schemaIdOrKey: string,
  prevItem: ListOfValuesItem,
  nextAttributes: Partial<ListOfValuesItem["attributes"]>,
  schema: ListOfValuesSchema
): Promise<ListOfValuesItemDocumentResponse> => {
  // pick out array paths on the list of values schema
  // because patch json schema does not work well with array items
  const arrayPaths = schema.fields
    .filter((field) => field.type.match(/\[\]$/))
    .map((field) => field.fieldKey);

  // get operations within the context of `attributes`
  // fully replace array paths
  const patchOperations = getPatchJsonOperations(prevItem.attributes, nextAttributes, {
    replacePaths: arrayPaths
  });

  // prepend nested `/attributes` because we send the full item containing attributes
  for (const item of patchOperations) {
    item.path = `/attributes${item.path}`;
  }

  return listOfValuesApi.patch(`lov/${schemaIdOrKey}/items/${prevItem.itemId}`, patchOperations);
};

export const ItemsApi = {
  create: createItem,
  delete: deleteItem,
  getBySchemaId: getItemsBySchemaId,
  getByItemId: getItemByItemId,
  update: updateItem
};
