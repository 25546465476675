import * as React from "react";
import { List } from "@mui/material";

import { MenuItem } from "@nutrien-operations/config";
import SideBarMenuItem from "./SideBarMenuItem";
import { getUuid } from "@packages/utils";

type Props = {
  applicationId: string;
  menu: MenuItem[];
  collapsed?: boolean;
};

const SideBarMenu: React.FC<Props> = (props) => {
  const { applicationId, collapsed, menu } = props;

  return (
    <List
      component="nav"
      disablePadding
      sx={{
        ...(collapsed
          ? {
              display: "flex",
              flexDirection: "column",
              alignItems: "center"
            }
          : {})
      }}
    >
      {menu.map((item) => (
        <SideBarMenuItem
          applicationId={applicationId}
          item={item}
          key={getUuid()}
          sidebarCollapsed={collapsed}
        />
      ))}
    </List>
  );
};

export default SideBarMenu;
