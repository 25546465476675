import * as React from "react";
import { styled } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";

const Center = styled("div")(() => ({
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  position: "absolute",
  zIndex: 1,
  display: "flex",
  alignItems: "center"
}));

const Container = styled("div")(() => ({
  "&.loading": {
    pointerEvents: "none",
    height: "inherit",
    "& .loader-children": {
      opacity: 0.2
    }
  }
}));

interface Props {
  loading: boolean;
  children?: React.ReactNode;
}

export const CircularProgressBar: React.FC = () => {
  return (
    <Center>
      <CircularProgress />
    </Center>
  );
};

export const WithLoader: React.FC<Props> = (props) => {
  return (
    <Container className={props.loading ? "loading" : ""}>
      {props.loading && (
        <Center>
          <CircularProgress />
        </Center>
      )}
      <span className="loader-children">{props.children}</span>
    </Container>
  );
};
