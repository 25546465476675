import { compare } from "fast-json-patch";
import { QueryOptions } from "../../types";
import {
  ShiftLogsTemplateListResponse,
  ShiftLogsTemplate,
  ShiftLogsTemplateDocumentResponse,
  AssignedLocationsDocumentResponse
} from "../../types/shift-logs-templates";
import { urlPathWithQueryParams } from "../../utils";
import { shiftLogsApi } from ".";

/**
 * Get Templates by siteId
 * @param siteId {string}
 * @returns {ShiftLogsTemplateListResponse}
 */
export const getTemplatesBySiteId = async (
  siteId: string,
  queryOptions: QueryOptions = {}
): Promise<ShiftLogsTemplateListResponse> => {
  return await shiftLogsApi.get(urlPathWithQueryParams(`templates`, { siteId, ...queryOptions }));
};

/**
 * Get Templates by locationId
 * @param locationId {string}
 * @returns {ShiftLogsTemplateListResponse}
 */
export const getTemplatesByLocationId = async (
  locationId: string,
  queryOptions: QueryOptions = {}
): Promise<ShiftLogsTemplateListResponse> => {
  return await shiftLogsApi.get(
    urlPathWithQueryParams(`templates`, { locationId, ...queryOptions })
  );
};

/**
 * Update existing Template
 * @param templateId {string}
 * @param updatedTemplate {ShiftLogsTemplate}
 * @param templates {ShiftLogsTemplate}
 * @return {Promise<ShiftLogsTemplateDocumentResponse>}
 */
const updateTemplate = async (
  updatedTemplate: ShiftLogsTemplate | Partial<ShiftLogsTemplate>,
  templates: ShiftLogsTemplate
): Promise<ShiftLogsTemplateDocumentResponse> => {
  const patchUpdates = compare(templates, updatedTemplate);
  return await shiftLogsApi.patch(`templates/${templates.shiftLogTemplateId}`, patchUpdates);
};

/**
 * assign Template
 * @param templateId {string}
 * @param locationId {string}
 * @return {Promise<AssignedLocationsDocumentResponse>}
 */
const assignTemplateToLocation = async (
  templateId: string,
  locationId: string
): Promise<AssignedLocationsDocumentResponse> => {
  return await shiftLogsApi.post(`templates/${templateId}/assignedLocations`, {
    locationId
  });
};

/**
 * unassign Template
 * @param templateId {string}
 * @param locationId {string}
 * @return {Promise<AssignedLocationsDocumentResponse>}
 */
const unassignTemplateFromLocation = async (
  templateId: string,
  locationId: string
): Promise<AssignedLocationsDocumentResponse> => {
  return await shiftLogsApi.delete(`templates/${templateId}/assignedLocations/${locationId}`);
};

/**
 * make default Template
 * @param templateId {string}
 * @param locationId {string}
 * @param isDefault {boolean}
 * @return {Promise<AssignedLocationsDocumentResponse>}
 */
const makeDefaultTemplateToLocation = async (
  templateId: string,
  locationId: string,
  isDefault: boolean
): Promise<AssignedLocationsDocumentResponse> => {
  return await shiftLogsApi.put(
    `templates/${templateId}/assignedLocations/${locationId}/isDefault`,
    { isDefault }
  );
};

/**
 * delete Template
 * @param templateId {string}
 * @return {Promise<void>}
 */
const deleteTemplate = async (templateId: string): Promise<void> => {
  return await shiftLogsApi.delete(`templates/${templateId}`);
};

/**
 * Get template detail by id
 * @param templateId {string}
 * @returns {Location}
 */
const getTemplateDetail = async (templateId: string): Promise<ShiftLogsTemplateDocumentResponse> =>
  shiftLogsApi.get(`templates/${templateId}`);

/**
 * Create new Template
 * @param template {ShiftLogsTemplate}
 * @returns {ShiftLogsTemplateDocumentResponse}
 */
export const createTemplate = async (
  shiftLogTemplate: ShiftLogsTemplate
): Promise<ShiftLogsTemplateDocumentResponse> => {
  return await shiftLogsApi.post("templates", shiftLogTemplate);
};

export const TemplatesApi = {
  getTemplatesBySiteId,
  getTemplatesByLocationId,
  makeDefaultTemplateToLocation,
  assignTemplateToLocation,
  unassignTemplateFromLocation,
  getOne: getTemplateDetail,
  update: updateTemplate,
  create: createTemplate,
  delete: deleteTemplate
};
