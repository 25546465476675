import { ReactNode } from "react";
import { Box, Paper, Tooltip, Typography } from "@mui/material";

import { noop } from "@packages/utils";

import { ApplicationIcon, ApplicationIconName } from "..";

export const AttachmentTypeIcon = ({
  label,
  iconName,
  tooltipText,
  onClick,
  isActive,
  disabled
}: {
  label: string;
  iconName: ApplicationIconName;
  tooltipText: string | ReactNode;
  onClick: () => void;
  isActive: boolean;
  disabled: boolean;
}) => (
  <Tooltip title={tooltipText}>
    <Paper>
      <Box
        onClick={disabled ? noop : onClick}
        sx={(theme) => ({
          padding: 4,
          display: "grid",

          borderColor: isActive ? "primary.main" : "transparent",
          borderRadius: "5px",
          borderStyle: "solid",
          borderWidth: 1,
          backgroundColor: isActive ? "primary.light" : theme.palette.grey[100],
          transition: "background-color 0.3s ease-in-out",
          width: 125,
          "&:hover": disabled
            ? {
                cursor: "not-allowed"
              }
            : {
                cursor: isActive ? "auto" : "pointer",
                backgroundColor: isActive ? "primary.light" : theme.palette.grey[200]
              }
        })}
      >
        <Typography
          variant="body1"
          sx={{
            color: "primary.main",
            display: "flex",
            justifyContent: "center"
          }}
        >
          <ApplicationIcon name={iconName} size="large" />
        </Typography>

        <Typography
          variant="body2"
          sx={{
            marginTop: 1,
            display: "flex",
            justifyContent: "center"
          }}
        >
          {label}
        </Typography>
      </Box>
    </Paper>
  </Tooltip>
);
