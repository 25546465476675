import { SearchRequest } from "@elastic/elasticsearch/lib/api/types";
import { acceleratorApi } from ".";
import { TagNotificationListResponse, TagNotificationResponse } from "../../types/accelerator";

/**
 * Get Tag Notifications
 *
 * @return {Promise<TagNotificationListResponse>}
 */
export const getTagNotifications = async (): Promise<TagNotificationListResponse> => {
  const searchBody: SearchRequest = {
    from: 0,
    size: 1000
  };
  return await acceleratorApi.post("tagNotifications/search", searchBody);
};

/**
 * Patch Tag Notification
 * @param tagNotificationId: @type string
 * @param resolved: @type boolean
 * @return {Promise<AssetResponse>}
 */
export const patchTagNotification = (
  tagNotificationId: string,
  resolved: boolean
): Promise<TagNotificationResponse> => {
  return acceleratorApi.patch(`tagNotifications/${tagNotificationId}`, [
    {
      op: "replace",
      path: "/resolved",
      value: resolved
    }
  ]);
};

export const TagNotificationsApi = {
  get: getTagNotifications,
  update: patchTagNotification
};
