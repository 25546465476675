import { Application } from "@nutrien-operations/config";

const landing: Application = {
  name: "@nutrien-operations/landing",
  path: {
    value: "/",
    exact: true
  }
};

export default landing;
