import { potashReportingApi } from ".";
import { ReportApprovalResponse, ReportInstance, ReportInstanceResponse } from "../../types";
import {
  PotashReportingSite,
  PotashReportingSiteResponse
} from "../../types/potash-reporting/sites";
import {
  CommentDocumentResponse,
  CommentsResponse,
  IncidentDocumentResponse,
  ReportComment
} from "../../types/report-comments";
import { ReportStatusResponse } from "../../types/report-status";

/**
 * Gets report comments
 * @param locationId {string}
 * @param reportTypeId {number}
 * @param startDate {string}
 * @returns {CommentsResponse}
 */
export const getReportComments = async (
  locationId: string,
  reportTypeId: number,
  startDate: string
): Promise<CommentsResponse> => {
  return await potashReportingApi.get(
    `locations/${locationId}/report-type/${reportTypeId}/start-date/${startDate}/report-comments`
  );
};

/**
 * Update a report comment
 * @param ReportComment {ReportComment}
 * @return {Promise<CommentDocumentResponse>}
 */
const updateReportComment = async (
  reportComment: ReportComment
): Promise<CommentDocumentResponse> => {
  return await potashReportingApi.patch(`report-comment`, reportComment);
};

/**
 * Update a report instance
 * @param ReportInstance {ReportInstance}
 * @return {Promise<ReportInstanceResponse>}
 */
const updateReportInstance = async (
  reportInstance: ReportInstance
): Promise<ReportInstanceResponse> => {
  return await potashReportingApi.post(`report-instance`, reportInstance);
};

/**
 * Update incident details
 * @param ReportComment {ReportComment}
 * @return {Promise<CommentDocumentResponse>}
 */
const updateIncidentDetails = async (
  reportComment: ReportComment
): Promise<CommentDocumentResponse> => {
  return await potashReportingApi.post(`safety-Incident`, reportComment);
};

/**
 * Get incident details
 * @param locationId {string}
 * @param startDate {string}
 * @returns {IncidentDocumentResponse}
 */
export const getIncidentDetails = async (
  locationId: string,
  startDate: string
): Promise<IncidentDocumentResponse> => {
  return await potashReportingApi.get(
    `location/${locationId}/safety-IncidentHana?startdate=${startDate}`
  );
};

/**
 * Get incident records
 * @param locationId {string}
 * @param startDate {string}
 * @returns {CommentDocumentResponse}
 */
export const getIncidentRecords = async (
  locationId: string,
  startDate: string
): Promise<CommentDocumentResponse> => {
  return await potashReportingApi.get(
    `locations/${locationId}/start-date/${startDate}/safety-Incident`
  );
};

/**
 * Get LTI overrides
 * @param locationId {string}
 * @returns {PotashReportingSiteResponse}
 */
export const getLTIOverrides = async (locationId: string): Promise<PotashReportingSiteResponse> => {
  return await potashReportingApi.get(`location/${locationId}/last-LTIOverride`);
};

/**
 * Get Finalize report status
 * @param locationId {string}
 * @param reportTypeId {number}
 * @param startDate {string}
 * @param userGuid {string}
 * @returns {ReportStatusResponse}
 */
export const getFinalizeReportStatus = async (
  locationId: string,
  reportTypeId: number,
  startDate: string,
  userGuid: string
): Promise<ReportStatusResponse> => {
  return await potashReportingApi.get(
    `location/${locationId}/user-guid/${userGuid}/start-date/${startDate}/report-type/${reportTypeId}/finalize-report-status`
  );
};

/**
 * Update report approval status
 * @param locationId {string}
 * @param reportTypeId {number}
 * @param startDate {string}
 * @param userGuid {string}
 * @returns {ReportApprovalResponse}
 */

const updateReportApprovalStatus = async (
  locationId: string,
  reportTypeId: number,
  startDate: string
): Promise<ReportApprovalResponse> => {
  return await potashReportingApi.post(
    `location/${locationId}/start-date/${startDate}/report-type/${reportTypeId}/finalize-report`,
    reportTypeId
  );
};

/**
 * Update report approval status
 * @param locationId {string}
 * @param reportTypeId {number}
 * @param startDate {string}
 * @param notifyEmail {string}
 * @returns {ReportApprovalResponse}
 */

const updateReportInstanceApproval = async (
  locationId: string,
  reportTypeId: number,
  startDate: string,
  notifyEmail: string
): Promise<ReportApprovalResponse> => {
  return await potashReportingApi.post(
    `location/${locationId}/start-date/${startDate}/report-type/${reportTypeId}/approve-report?notify=${notifyEmail}`,
    reportTypeId
  );
};

/**
 * Update LTI overrides
 * @param PotashReportingSite {PotashReportingSite}
 * @return {Promise<PotashReportingSiteResponse>}
 */
const updateLTIOverrides = async (
  site: PotashReportingSite
): Promise<PotashReportingSiteResponse> => {
  return await potashReportingApi.post(`site-detail`, site);
};

export const ReportCommentsApi = {
  get: getReportComments,
  update: updateReportComment,
  getIncidentDetails: getIncidentDetails,
  getIncidentRecords: getIncidentRecords,
  getLTIOverrides: getLTIOverrides,
  updateLTIOverrides: updateLTIOverrides,
  updateIncidentDetails: updateIncidentDetails,
  updateReportInstance: updateReportInstance,
  getFinalizeReportStatus: getFinalizeReportStatus,
  finalizeReport: updateReportApprovalStatus,
  approveReportAndNotify: updateReportInstanceApproval
};
