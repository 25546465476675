import { onlineLogSheetsApi } from "..";
import { ManualEntryListResponse, ManualEntry } from "../../../types";
import { urlPathWithQueryParams } from "../../../utils";

/** Get Manual entries
 * @param sheetIdOrKey {string}
 * @param startDate {string}
 * @return {Promise<ManualEntryListResponse>}
 */
const getEntries = async (
  sheetIdOrKey: string,
  startDate: string
): Promise<ManualEntryListResponse> => {
  return await onlineLogSheetsApi.get(
    urlPathWithQueryParams(`manual-entry/${sheetIdOrKey}/entries`, { startDate })
  );
};

/**
 * Update Manual entries
 * @param sheetIdOrKey {string}
 * @param manualEntries {ManualEntry[]}
 * @returns {ManualEntryListResponse}
 */
export const updateEntries = async (
  sheetIdOrKey: string,
  manualEntries: ManualEntry[]
): Promise<ManualEntryListResponse> => {
  return await onlineLogSheetsApi.post(`manual-entry/${sheetIdOrKey}/entries`, manualEntries);
};

export const EntriesApi = {
  get: getEntries,
  updateEntries
};
