import React from "react";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

export type DrawerLayoutProps = {
  title: React.ReactElement | string | React.ReactNode | JSX.Element;
  content: React.ReactElement | string | React.ReactNode | JSX.Element;
  actions?: React.ReactElement | string | React.ReactNode | JSX.Element;
  onClose?: () => void;
};

export function DrawerLayout({ title, content, actions, onClose }: DrawerLayoutProps) {
  return (
    <StyledRoot>
      <DrawerTitle>
        <Title>{title}</Title>

        {onClose ? (
          <IconButton size="medium" aria-label="close" onClick={onClose} edge="end">
            <CloseIcon color="primary" fontSize="medium" />
          </IconButton>
        ) : null}
      </DrawerTitle>

      <DrawerContent>{content}</DrawerContent>

      {!!actions && <DrawerActions>{actions}</DrawerActions>}
    </StyledRoot>
  );
}

const StyledRoot = styled("div")(() => ({
  display: "grid",
  gridTemplateRows: "auto 1fr auto",
  height: "100vh"
}));

const DrawerActions = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  flexDirection: "row-reverse",
  borderTop: "1px solid rgba(0, 0, 0, 0.12)",
  padding: 16
}));

const DrawerTitle = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  padding: "0px 16px",
  overflow: "hidden",
  borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
  height: 56
}));

const DrawerContent = styled("div")(() => ({
  overflow: "auto"
}));

const Title = styled("div")(() => ({
  flex: 1,
  fontWeight: 600,
  fontSize: "1.25rem",
  lineHeight: 1.6,
  letterSpacing: "0.0075em",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  overflow: "hidden"
}));
