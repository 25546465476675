import React from "react";
import { Box, Divider, IconButton, Tooltip, Typography, useTheme } from "@mui/material";
import SideBarMenu from "./SideBarMenu";
import { Application } from "@nutrien-operations/config";
import { Location } from "@packages/service-api";
import { ApplicationIcon, ApplicationIconName } from "../icons";
import { useAppSidebar } from "./state";
import SidebarLocation from "./SidebarLocation";

type Props = {
  appConfig: Application;
  hideLocationbar?: boolean;
  onLocationChange?: (nextLocation: Location) => void;
};

const LocationSidebar: React.FC<Props> = (props) => {
  const { appConfig, onLocationChange, hideLocationbar } = props;
  const { applicationId, displayName, icon, menu } = appConfig;

  const theme = useTheme();
  const { collapsed, setCollapsed, width: sidebarWidth } = useAppSidebar();

  return (
    <Box
      sx={{
        position: "fixed",
        height: `calc(100vh - ${Number(theme?.mixins?.toolbar?.minHeight || 0)}px)`,
        elevation: 2,
        backgroundColor: "white",
        borderRadius: 0,
        width: sidebarWidth,
        overflow: "auto",
        zIndex: 1175 // squeeze between "app bar" and "drawer"
      }}
    >
      <Box
        sx={{
          p: 2,
          borderRadius: 0
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: collapsed ? "center" : "space-between"
          }}
        >
          <Box>
            {collapsed ? (
              <Tooltip title={displayName} placement="right" arrow>
                <Box
                  onClick={() => setCollapsed(!collapsed)}
                  sx={{
                    border: 1,
                    borderColor: "primary.light",
                    elevation: 1,
                    borderRadius: 5,
                    display: "flex",
                    alignItems: "center",
                    p: 1,
                    bgcolor: "primary.main",
                    "&> svg": { color: "white", height: 24, width: 24 },
                    "&:hover": {
                      cursor: "pointer",
                      elevation: 2
                    }
                  }}
                >
                  <ApplicationIcon name={icon as ApplicationIconName} />
                </Box>
              </Tooltip>
            ) : (
              <Typography variant="h5">{displayName}</Typography>
            )}
          </Box>

          {!collapsed && (
            <Box>
              <IconButton size="small" onClick={() => setCollapsed(!collapsed)} sx={{ p: 0, m: 0 }}>
                <ApplicationIcon name="chevron-left" />
              </IconButton>
            </Box>
          )}
        </Box>
      </Box>

      <Divider sx={{ color: "black", mx: collapsed ? 1 : 0 }} />

      <Box sx={{ pt: 1 }}>
        {menu && (
          <SideBarMenu
            collapsed={collapsed}
            applicationId={applicationId}
            menu={menu.children || []}
          />
        )}
      </Box>

      <Divider sx={{ mx: 2, my: 2 }} />

      <SidebarLocation
        hideLocationbar={hideLocationbar}
        onLocationChange={onLocationChange}
        applicationId={applicationId}
      />
    </Box>
  );
};

export default LocationSidebar;
