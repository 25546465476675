import { UseQueryOptions, useQuery } from "react-query";
import { HTTPError, LocationDocumentResponse } from "../../../types";
import { CommonDataApi } from "../../..";
import { locationById } from "./query-cache";

/**
 * Hook to get a location by ID
 */
export const useGetLocationById = (locationId: string) => {
  const queryOptions: UseQueryOptions<LocationDocumentResponse, HTTPError> = {
    queryKey: locationById(locationId),
    queryFn: () => CommonDataApi.Locations.getOne(locationId),
    enabled: !!locationId
  };

  const { data, isLoading, isError, isSuccess } = useQuery(queryOptions);

  return {
    location: data?.data,
    loading: isLoading,
    isError,
    isSuccess
  };
};
