import React from "react";
import { Alert, AlertTitle, Box, Button, Collapse, Typography } from "@mui/material";

import { useGetLocationById, useGetPermissionsByApplicationId, useUserProfile } from "../hooks";

type Props = {
  applicationId?: string;
  locationId?: string;
  permissions: string[];
};

const NoPermissionsAlert: React.FC<Props> = (props) => {
  const { applicationId, locationId, permissions } = props;
  const { permissions: appPermissions } = useGetPermissionsByApplicationId(applicationId);
  const { location } = useGetLocationById(locationId);
  const userProfile = useUserProfile();

  const [showDetails, setShowDetails] = React.useState<boolean>(false);

  const renderPermissions = () => {
    const resourcePermissions = appPermissions
      .filter((p) => permissions.includes(p.permissionTypeId))
      .sort((a, b) => (a.name < b.name ? -1 : 1));

    return resourcePermissions.map((perm) => {
      return (
        <li key={perm.permissionTypeId}>
          {perm.name} ({perm.permissionTypeId})
        </li>
      );
    });
  };
  return (
    <Box sx={{ m: 2, p: 2 }}>
      <Alert severity="error">
        <AlertTitle sx={{ fontWeight: "bold", color: "error.dark" }}>
          Insufficient Permissions
        </AlertTitle>
        <Typography>
          <b>
            {userProfile?.firstName} {userProfile?.lastName} ({userProfile.email})
          </b>{" "}
          does not have permissions to view this resource.
          <Button
            color="error"
            onClick={() => setShowDetails((show) => !show)}
            sx={{
              ml: 1.6,
              mt: -0.2,
              p: 0
            }}
            variant="text"
          >
            {showDetails ? "Hide" : "View"} Details
          </Button>
        </Typography>
        <Collapse in={showDetails} timeout="auto" unmountOnExit>
          <Typography sx={{ mt: 2 }}>
            This resource requires one of the following permissions which the user does not have.
          </Typography>
          <ul>{renderPermissions()}</ul>

          <Typography sx={{ mt: 2 }}>
            {locationId ? (
              <>
                This resource requires one of the permissions at the location {location?.name} (
                {locationId}).
              </>
            ) : (
              <>This resource requires one of the permissions at any location.</>
            )}
          </Typography>
        </Collapse>
      </Alert>
    </Box>
  );
};

export default NoPermissionsAlert;
