export const extractFileNameFromContentDisposition = (value: string): string | undefined => {
  if (!value) {
    throw new Error("Content disposition header value missing");
  }

  const filenameRegex = /filename="([^"]+)"/;

  // Use the exec() method to extract the matched groups
  const matches = filenameRegex.exec(value);

  // Check if a match is found
  if (matches && matches.length > 1) {
    // Extracted filename is in the second group (index 1)
    return matches[1];
  }
};

export const downloadBlob = (fileName: string, blob: Blob) => {
  // Convert your blob into a Blob URL (a special url that points to an object in the browser's memory)
  const blobUrl = URL.createObjectURL(blob);

  // Create a link element
  const link = document.createElement("a");

  // Set link's href to point to the Blob URL
  link.href = blobUrl;
  link.download = fileName;

  // Append link to the body
  document.body.appendChild(link);

  // Dispatch click event on the link
  // This is necessary as link.click() does not work on the latest firefox
  link.dispatchEvent(
    new MouseEvent("click", {
      bubbles: true,
      cancelable: true,
      view: window
    })
  );

  // Remove link from body
  document.body.removeChild(link);
};
