import { compare } from "fast-json-patch";
import { SiteSettings, SiteSettingsDocumentResponse } from "../../types/site-settings";
import { materialBalanceApi } from ".";

/**
 * Get a site settings by siteId
 * @param siteId {string}
 * @returns {void}
 */
export const getSiteSettings = async (siteId: string): Promise<SiteSettingsDocumentResponse> => {
  return materialBalanceApi.get(`sites/${siteId}/configuration`);
};

/**
 * Patch a site-settings
 * @param siteId {string}
 * @param previousSiteSettings (SiteSettings)
 * @param siteSettings {Partial<SiteSettings>}
 * @returns {SiteSettingsDocumentResponse}
 */
export const patchSiteSettings = async (
  siteId: string,
  previousSiteSettings: SiteSettings,
  siteSettings: Partial<SiteSettings>
): Promise<SiteSettingsDocumentResponse> => {
  const patchUpdates = compare(previousSiteSettings, { ...previousSiteSettings, ...siteSettings });
  return materialBalanceApi.patch(`sites/${siteId}/configuration`, patchUpdates);
};

/**
 * Create a site-settings
 * @param siteId {string}
 * @param siteSettings {Partial<SiteSettings>}
 * @returns {SiteSettingsDocumentResponse}
 */
export const createSiteSettings = async (
  siteId: string,
  siteSettings: SiteSettings
): Promise<SiteSettingsDocumentResponse> => {
  return materialBalanceApi.post(`sites/${siteId}/configuration`, siteSettings);
};

export const SiteSettingsApi = {
  getSiteSettings,
  patchSiteSettings,
  createSiteSettings: createSiteSettings
};
