import { Application } from "@nutrien-operations/config";
import React from "react";

export interface AppSidebarState {
  appConfig: Application | undefined;
  collapsed: boolean;
  setCollapsed: (nextCollapsed: boolean) => void;
}

const collapsedWidth = 60;
const expandedWidth = 260;

export const AppSidebarContext = React.createContext<AppSidebarState>({
  appConfig: undefined,
  collapsed: false,
  setCollapsed: (nextCollapsed: boolean) => nextCollapsed
});

export const useAppSidebar = () => {
  const { appConfig, collapsed, setCollapsed } =
    React.useContext<AppSidebarState>(AppSidebarContext);

  return {
    appConfig,
    collapsed,
    setCollapsed,
    width: collapsed ? collapsedWidth : expandedWidth
  };
};

type ProviderProps = {
  appConfig: Application;
  children: React.ReactNode;
  defaultCollapsed?: boolean;
};

export const AppSidebarProvider: React.FC<ProviderProps> = ({
  appConfig,
  children,
  defaultCollapsed
}) => {
  const [collapsed, setCollapsedState] = React.useState<boolean>(defaultCollapsed || false);

  const setCollapsed = React.useCallback((nextCollapsed: boolean) => {
    setCollapsedState(nextCollapsed);
  }, []);

  return (
    <AppSidebarContext.Provider value={{ appConfig, collapsed, setCollapsed }}>
      {children}
    </AppSidebarContext.Provider>
  );
};
