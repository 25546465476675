import { useMutation } from "react-query";

import Auth from "../../auth/auth";
import { Preferences } from "../../types";

export const useUpdateUserPreferences = () => {
  const { mutateAsync, isLoading } = useMutation((updates: Partial<Preferences>) =>
    Auth.updateUserPreferences(updates)
  );

  return {
    saving: isLoading,
    updateUserPreferences: mutateAsync
  };
};
