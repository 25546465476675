import { potashReportingApi } from ".";
import { TabPermission, TabPermissionResponse, TabResponse } from "../../types/tab-permission";

/**
 * Gets tab permissions
 * @param locationId {string}
 * @param userId {string}
 * @returns {TabPermissionResponse}
 */
export const getTabPermissions = async (
  locationId: string,
  userId: string
): Promise<TabPermissionResponse> => {
  return await potashReportingApi.get(`locations/${locationId}/users/${userId}/tab-permissions`);
};

/**
 * Gets tabs
 * @param locationId {string}
 * @param reportTypeId {number}
 * @returns {TabResponse}
 */
export const getTabs = async (locationId: string, reportTypeId: number): Promise<TabResponse> => {
  return await potashReportingApi.get(
    `locations/${locationId}/report-type/${reportTypeId}/report-tabs`
  );
};

/**
 * Add tab permissions
 * @param TabPermission {TabPermission[]}
 * @param userId {string}
 * @return {Promise<TabPermissionResponse>}
 */
const addTabPermissions = async (
  tabPermissions: TabPermission[],
  userId: string
): Promise<TabPermissionResponse> => {
  return await potashReportingApi.post(`users/${userId}/tab-permissions`, tabPermissions);
};

export const TabPermissionsApi = {
  get: getTabPermissions,
  getTabs: getTabs,
  add: addTabPermissions
};
