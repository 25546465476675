import { compare, Operation } from "fast-json-patch";

import { acceleratorApi } from ".";
import {
  AlarmMode,
  AlarmModeDocumentResponse,
  AlarmModeListResponse
} from "../../types/accelerator/alarm-modes";
import { escapeCharacters } from "../../utils";
import { QueryDslQueryContainer, SearchRequest } from "@elastic/elasticsearch/lib/api/types";
import { QueryOptions, SearchFilter } from "../../types";
import { SortModelItem } from "../../hooks/accelerator/alarm-modes/useSearchAlarmModes";

/**
 * Get Alarm Modes
 *
 * @return {Promise<AlarmModeListResponse>}
 */
export const getAlarmModes = async (): Promise<AlarmModeListResponse> => {
  return acceleratorApi.get<AlarmModeListResponse>("alarmModes");
};

/**
 * Get Alarm Modes By ID
 *
 * @param alarmModeId: @type String
 *
 * @return {Promise<AlarmModeDocumentResponse>}
 */
export const getAlarmModeById = async (alarmModeId: string): Promise<AlarmModeDocumentResponse> => {
  return acceleratorApi.get<AlarmModeDocumentResponse>(`alarmModes/${alarmModeId}`);
};

/**
 * Search Templates
 * @return {Promise<AlarmModeListResponse>}
 */
export const searchAlarmModes = async (
  searchTerm: string,
  filter: SearchFilter<AlarmMode>,
  sortModel: SortModelItem[],
  options: QueryOptions = {}
): Promise<AlarmModeListResponse> => {
  const from = options.from || 0;
  const size = options.size || 20;

  const searchBody: SearchRequest = {
    from,
    size,
    query: {
      bool: {
        should: [],
        must: [],
        must_not: [],
        filter: []
      }
    },
    sort: sortModel
  };

  if (searchTerm) {
    searchBody.query.bool.must = {
      multi_match: {
        query: escapeCharacters(searchTerm),
        type: "phrase_prefix",
        fields: ["*"]
      }
    };
  }

  if (filter) {
    Object.entries(filter).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        (searchBody.query.bool.filter as QueryDslQueryContainer[]).push({
          terms: { [`${key}.keyword`]: value }
        });
      } else if (value || value === null) {
        (searchBody.query.bool.filter as QueryDslQueryContainer[]).push({
          term: { [`${key}.keyword`]: value }
        });
      }
    });
  }

  return await acceleratorApi.post<SearchRequest, AlarmModeListResponse>(
    "alarmModes/search",
    searchBody
  );
};

/**
 * Create AlarmMode
 *
 * @param alarmMode: @type Partial<AlarmMode>
 *
 * @return {Promise<AlarmMode>}
 */
export const createAlarmMode = async (
  alarmMode: Partial<AlarmMode>
): Promise<AlarmModeDocumentResponse> => {
  return acceleratorApi.post<Partial<AlarmMode>, AlarmModeDocumentResponse>(
    "alarmModes",
    alarmMode
  );
};

/**
 * Update AlarmMode
 *
 * @param alarmModeUpdate: @type AlarmMode
 *
 * @return {Promise<AlarmModeDocumentResponse>}
 */
export const updateAlarmMode = async (
  alarmModeUpdate: Partial<AlarmMode>
): Promise<AlarmModeDocumentResponse> => {
  return acceleratorApi.put<Partial<AlarmMode>, AlarmModeDocumentResponse>(
    "alarmModes",
    alarmModeUpdate
  );
};

/**
 * Patch AlarmMode
 *
 * @param alarmModeId: @type String
 * @param previousValue: @type AlarmMode
 * @param nextValue: @type Partial<AlarmMode>
 *
 * @return {Promise<AlarmModeDocumentResponse>}
 */
export const patchAlarmMode = async (
  alarmModeId: string,
  previousValue: AlarmMode,
  nextValue: Partial<AlarmMode>
): Promise<AlarmModeDocumentResponse> => {
  const patchUpdates = compare(previousValue, { ...previousValue, ...nextValue });

  return acceleratorApi.patch<Operation[], AlarmModeDocumentResponse>(
    `alarmModes/${alarmModeId}`,
    patchUpdates
  );
};

/**
 * Delete AlarmMode
 *
 * @param alarmModeId: @type String
 *
 * @return {Promise<void>}
 */
export const deleteAlarmMode = async (alarmModeId: string): Promise<void> => {
  await acceleratorApi.delete(`alarmModes/${alarmModeId}`);
};

export const AlarmModesApi = {
  create: createAlarmMode,
  delete: deleteAlarmMode,
  get: getAlarmModes,
  getById: getAlarmModeById,
  patch: patchAlarmMode,
  search: searchAlarmModes,
  update: updateAlarmMode
};
