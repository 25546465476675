import { compare } from "fast-json-patch";
import { Permission } from "./../../types/permission";
import { QueryOptions } from "../../types";
import { PermissionListResponse, PermissionDocumentResponse } from "../../types/permission";
import { urlPathWithQueryParams } from "../../utils/utils";
import { commonDataApi } from ".";

/**
 * Get all Permissions by application
 * @param {string} applicationId
 * @return {Promise<PermissionListResponse>}
 */
const getPermissions = async (
  applicationId: string,
  queryOptions: QueryOptions = {}
): Promise<PermissionListResponse> => {
  return await commonDataApi.get(
    urlPathWithQueryParams(`applications/${applicationId}/permissions`, queryOptions)
  );
};

/**
 * Get all Permissions
 * @param {string} applicationId
 * @return {Promise<PermissionListResponse>}
 */
const getAllPermissions = async (
  queryOptions: QueryOptions = {}
): Promise<PermissionListResponse> => {
  return await commonDataApi.get(urlPathWithQueryParams("permissions", queryOptions));
};

/**
 * Get Permissions by permissionTypeId
 * @param {string} permissionTypeId
 * @return {Promise<PermissionDocumentResponse>}
 */
const getPermissionsById = async (
  permissionTypeId: string
): Promise<PermissionDocumentResponse> => {
  return await commonDataApi.get(`permissions/${permissionTypeId}`);
};

/**
 * Get Permissions by application
 * @param {string} applicationId
 * @return {Promise<PermissionListResponse>}
 */
const getPermissionsByIds = async (
  permissionTypeIds: string[],
  queryOptions: QueryOptions = {}
): Promise<PermissionListResponse> => {
  return await commonDataApi.get(
    urlPathWithQueryParams(`permissions`, {
      ...queryOptions,
      permissionTypeId: permissionTypeIds.join()
    })
  );
};

/**
 * Get all Permissions By ApplicationId
 * @param {string} applicationId
 * @param {string} siteId
 * @param {string} locationId
 * @return {Promise<PermissionListResponse>}
 */
const getPermissionsByLocationId = async (
  applicationId: string,
  siteId: string,
  locationId: string
): Promise<PermissionListResponse> => {
  return await commonDataApi.get(
    `applications/${applicationId}/sites/${siteId}/locations/${locationId}/permissions`
  );
};

/**
 * Create Permission
 * @param {Permission | Partial<Permission>} permission
 * @return {Promise<PermissionDocumentResponse>}
 */
const createPermissions = async (permission: Permission): Promise<PermissionDocumentResponse> => {
  return await commonDataApi.post("permissions", permission);
};

/**
 * Update Permission
 * @param {string} permissionId
 * @param {(Permission | Partial<Permission>)} updatedPermission
 * @param {Permission} permission
 * @return {Promise<PermissionDocumentResponse>}
 */
const updatePermissions = async (
  permissionId: string,
  updatedPermission: Permission | Partial<Permission>,
  permission: Permission
): Promise<PermissionDocumentResponse> => {
  const patchUpdates = compare(permission, updatedPermission);
  return await commonDataApi.patch(`permissions/${permissionId}`, patchUpdates);
};

/**
 * Delete Permissions
 * @param {string} permissionId
 * @return {Promise<void>}
 */
const deletePermissions = async (permissionId: string): Promise<void> => {
  return await commonDataApi.delete(`permissions/${permissionId}`);
};

export const PermissionsApi = {
  get: getPermissions,
  getById: getPermissionsById,
  getByLocationId: getPermissionsByLocationId,
  create: createPermissions,
  update: updatePermissions,
  delete: deletePermissions,
  getPermissionsByIds,
  getAllPermissions
};
