import { Event } from "@sentry/react";

const blackListErrorMessages = ["No current user"];

export const beforeSend = (event: Event): PromiseLike<Event | null> | Event | null => {
  if (event.message && blackListErrorMessages.includes(event.message)) {
    return null;
  }

  return event;
};
