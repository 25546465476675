import { AcceleratorCsvListResponse } from "../../types";
import { acceleratorApi } from ".";

/**
 * CSV file upload
 * @param file: @type File
 * @param pageConfigKey: @type String
 * @return {Promise<AcceleratorCsvListResponse>}
 */
const csvUpload = async (
  file: File,
  pageConfigKey: string
): Promise<AcceleratorCsvListResponse> => {
  return acceleratorApi.uploadFile(`${pageConfigKey}/csv`, file);
};

/**
 * CSV sample download
 * @param pageConfigKey: @type String
 * @return {Promise<string>}
 */
const getSampleCsv = async (pageConfigKey: string): Promise<string> => {
  return acceleratorApi.get(`${pageConfigKey}/sampleCsv`);
};

export const CsvUploadApi = {
  csvUpload,
  getSampleCsv
};
