import React from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import { useShiftLocationState } from "@packages/service-api";
import { MessageBox } from "../MessageBox/MessageBox";

type Props = {
  applicationId: string;
  children: React.ReactNode | React.ReactNode[];
};

const EnsureShiftConfiguration: React.FC<Props> = (props) => {
  const { applicationId, children } = props;
  const shiftConfig = useShiftLocationState(applicationId);

  const { loading, notEnabled, ready } = React.useMemo(() => {
    return {
      loading: shiftConfig.loading,
      notEnabled: shiftConfig.state?.status === "not-enabled" || false,
      ready: shiftConfig.state?.status === "ready" || false
    };
  }, [shiftConfig]);

  if (loading) {
    return (
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
        <CircularProgress />
      </Box>
    );
  }

  if (notEnabled) {
    return (
      <MessageBox>
        <Typography>There is no shift schedule configured at this location</Typography>
      </MessageBox>
    );
  }

  if (ready) {
    return <>{children}</>;
  }

  return null;
};

export default EnsureShiftConfiguration;
