export const isEmpty = (value: string | unknown[] | object): boolean => {
  if (value === null || value === undefined) {
    return true;
  }

  if (typeof value === "string" && value.length === 0) {
    return true;
  }

  if (Array.isArray(value) && value.length === 0) {
    return true;
  }

  if (typeof value === "object" && Object.entries(value).length === 0) {
    return true;
  }

  return false;
};
