import { FC, ReactElement, useCallback, useRef, useState } from "react";
import { Box, Button, Slide, Tooltip } from "@mui/material";

import { ApplicationIcon, Dialog, DialogActionButton } from "..";

import { AttachmentForm, AttachmentFormProps, AttachmentFormRef } from ".";
import { useMediaQuery } from "../../hooks";

enum AttachmentView {
  ATTACHMENTS_LISTING = "ATTACHMENTS_LISTING",
  ADD_ATTACHMENT = "ADD_ATTACHMENT"
}

export type AttachmentDialogProps = AttachmentFormProps & {
  attachmentCount: number;
  children?: ReactElement; // Children is used to renderi existing attachments depending on the context
  onSuccess?: () => void | Promise<void>; // (Optional) After success event handler
};

const TRANSITION_TIMEOUT = 400;

export const AttachmentDialog: FC<AttachmentDialogProps> = (props) => {
  const { attachmentCount, children, loading, isSuccess, isError, onSubmit, onSuccess } = props;

  const formRef = useRef<AttachmentFormRef>();

  const { smUp } = useMediaQuery();

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const [showAttachmentsButtonIcon, setShowAttachmentsButtonIcon] = useState<boolean>(false);

  const [currentView, setCurrentView] = useState<AttachmentView>();

  const showModal = useCallback(() => {
    setIsModalOpen(true);
    setCurrentView(AttachmentView.ATTACHMENTS_LISTING);
  }, []);

  const closeModal = useCallback(() => {
    setIsModalOpen(false);
    setCurrentView(AttachmentView.ADD_ATTACHMENT);
  }, []);

  const handleSaveAttachment = async () => {
    await formRef.current?.onSubmit();

    typeof onSuccess === "function" && (await onSuccess());

    setTimeout(() => {
      setCurrentView(AttachmentView.ATTACHMENTS_LISTING);
    }, 500);
  };

  const actions: DialogActionButton[] =
    currentView === AttachmentView.ATTACHMENTS_LISTING
      ? [
          {
            text: "Add attachment",
            action: () => setCurrentView(AttachmentView.ADD_ATTACHMENT),
            variant: "contained",
            disabled: loading,
            loading: loading
          },
          {
            text: "Close",
            action: closeModal,
            variant: "text",
            disabled: loading
          }
        ]
      : [
          {
            text: "Save attachment",
            action: handleSaveAttachment,
            variant: "contained",
            disabled: loading,
            loading: loading
          },
          {
            text: "Discard",
            action: () => setCurrentView(AttachmentView.ATTACHMENTS_LISTING),
            variant: "text",
            disabled: loading
          }
        ];

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "end",
        alignItems: "center"
      }}
    >
      <Tooltip
        title="Click to add attachment(s)"
        placement="left"
        sx={{
          "& button": {
            display: "flex",
            alignItems: "center"
          }
        }}
      >
        <Button
          sx={{
            color: "text.primary",
            textTransform: "none",
            display: "flex !important",
            alignItems: "center"
          }}
          startIcon={<ApplicationIcon name="folder-open" />}
          endIcon={showAttachmentsButtonIcon ? <ApplicationIcon name="plus-circle" /> : null}
          onClick={showModal}
          onMouseEnter={() => setShowAttachmentsButtonIcon(true)}
          onMouseLeave={() => setShowAttachmentsButtonIcon(false)}
        >
          <Box component="b" sx={{ fontSize: 13, paddingLeft: 1 }}>
            <Box component="span" sx={{ mr: 1 }}>
              Attachments
            </Box>
            <Box component="span">
              {typeof attachmentCount === "number" ? `(${attachmentCount})` : null}
            </Box>
          </Box>
        </Button>
      </Tooltip>

      <Dialog
        isDraggable={smUp}
        disableBackdropClick
        open={isModalOpen}
        title="Attachments"
        actions={actions}
        onClose={closeModal}
        sx={{ zIndex: 1500 }}
        maxWidth="xl"
      >
        <Box sx={{ minHeight: 500, padding: 1 }}>
          {currentView === AttachmentView.ATTACHMENTS_LISTING && (
            <Slide in={true} direction="up" timeout={TRANSITION_TIMEOUT}>
              <Box>{children}</Box>
            </Slide>
          )}

          {currentView === AttachmentView.ADD_ATTACHMENT && (
            <Slide in={true} direction="down" timeout={TRANSITION_TIMEOUT}>
              <Box>
                <AttachmentForm
                  ref={formRef}
                  loading={loading}
                  isSuccess={isSuccess}
                  isError={isError}
                  onSubmit={onSubmit}
                />
              </Box>
            </Slide>
          )}
        </Box>
      </Dialog>
    </Box>
  );
};
