import { potashReportingApi } from ".";
import {
  SectionPermission,
  SectionPermissionResponse,
  SectionResponse
} from "../../types/section-permission";

/**
 * Gets section permissions
 * @param locationId {string}
 * @param userId {string}
 * @returns {SectionPermissionResponse}
 */
export const getSectionPermissions = async (
  locationId: string,
  userId: string
): Promise<SectionPermissionResponse> => {
  return await potashReportingApi.get(
    `locations/${locationId}/users/${userId}/section-permissions`
  );
};

/**
 * Gets sections
 * @param locationId {string}
 * @param reportTypeId {number}
 * @returns {SectionResponse}
 */
export const getSections = async (
  locationId: string,
  reportTypeId: number
): Promise<SectionResponse> => {
  return await potashReportingApi.get(
    `locations/${locationId}/report-type/${reportTypeId}/report-sections`
  );
};

/**
 * Add section permissions
 * @param SectionPermission {SectionPermission[]}
 * @param userId {string}
 * @return {Promise<SectionPermissionResponse>}
 */
const addSectionPermissions = async (
  sectionPermissions: SectionPermission[],
  userId: string
): Promise<SectionPermissionResponse> => {
  return await potashReportingApi.post(`users/${userId}/section-permissions`, sectionPermissions);
};

export const SectionPermissionsApi = {
  get: getSectionPermissions,
  getSections: getSections,
  add: addSectionPermissions
};
