import React from "react";
import { Box } from "@mui/material";

import { Application } from "@nutrien-operations/config";
import { useUserProfile, Location } from "@packages/service-api";

import LocationSidebar from "../AppSidebar/LocationSidebar";
import { LocationToolbar } from "../AppSidebar/LocationToolbar";
import { useAppSidebar } from "../AppSidebar/state";
import { useMediaQuery } from "../../hooks";
import EnsureShiftConfiguration from "../EnsureShiftConfiguration/EnsureShiftConfiguration";

type Props = {
  appConfig: Application;
  children: React.ReactNode;
  hideToolbars?: boolean;
  hideLocationbar?: boolean;
  onLocationChange?: (nextLocation: Location) => void;
};

const PageContainerContent: React.FC<Props> = (props) => {
  const { appConfig, children, hideToolbars, onLocationChange, hideLocationbar } = props;

  const userProfile = useUserProfile();
  const { mdDown } = useMediaQuery();
  const { width: sidebarWidth } = useAppSidebar();

  const locationToolbarPosition = React.useMemo(() => {
    if (!userProfile) {
      return undefined;
    }

    return userProfile.preferences?.ui?.locationToolbarPosition || "left";
  }, [userProfile]);

  if (hideToolbars) {
    return <React.Fragment>{children}</React.Fragment>;
  }

  if ((mdDown || locationToolbarPosition === "above") && !hideLocationbar) {
    return (
      <Box sx={{ mx: 1 }}>
        <Box sx={{ pt: 1 }}>
          <LocationToolbar applicationId={appConfig?.applicationId} />
        </Box>
        <Box sx={{ mt: 2 }}>{children}</Box>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row"
      }}
    >
      <LocationSidebar
        hideLocationbar={hideLocationbar}
        onLocationChange={onLocationChange}
        appConfig={appConfig}
      />

      <Box sx={{ marginLeft: `${sidebarWidth}px`, p: 2, width: "100%" }}>
        {appConfig.shifts?.showSelector ? (
          <EnsureShiftConfiguration applicationId={appConfig.applicationId}>
            {children}
          </EnsureShiftConfiguration>
        ) : (
          children
        )}
      </Box>
    </Box>
  );
};

export default PageContainerContent;
