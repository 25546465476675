import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import {
  Container,
  Typography,
  Grid,
  ThemeProvider as MUIThemeProvider,
  Divider
} from "@mui/material";
import {
  ThemeProvider,
  AppContainer,
  ApplicationIcon,
  PageContainer,
  ApplicationIconName
} from "@packages/theme-mui-v5";
import AppCard from "./AppCard/AppCard";
import { Auth, WithPermissions } from "@packages/service-api";
import { ApplicationGroup, groupedApplications } from "@nutrien-operations/config";

import { initializeSentry } from "@packages/sentry";
import applicationConfig from "./config";

initializeSentry(applicationConfig.name);

const Landing: React.FC = () => {
  const applicationsWithAccess = Object.entries(groupedApplications).reduce(
    (all, [key, applicationGroup]) => {
      return {
        ...all,
        [key]: {
          ...applicationGroup,
          applications: applicationGroup.applications.filter((app) =>
            Auth.checkPermission({
              applicationId: app.applicationId,
              permissions: Object.values(app.permissions),
              skipSiteLocationCheck: true
            })
          )
        }
      };
    },
    {}
  );

  const renderApplicationGroup = (applicationGroup: ApplicationGroup) => {
    if (applicationGroup.applications.length === 0) {
      return null;
    }

    return (
      <PageContainer title="Dashboard" hideToolbars>
        <Typography
          variant="h3"
          sx={{
            pt: 4,
            mb: 2,
            textTransform: "uppercase"
          }}
        >
          {applicationGroup.displayName}
        </Typography>
        {applicationGroup.applications.length === 0 && (
          <Typography variant="h5">
            You do not have access to any applications at this site
          </Typography>
        )}
        <Grid container spacing={2}>
          {applicationGroup.applications.map((app) => (
            <WithPermissions
              key={app.applicationId}
              applicationId={app.applicationId}
              permissions={Object.values(app.permissions || {})}
              skipSiteLocationCheck={app.siteAgnostic}
              hidePermissionsAlert={true}
            >
              <Grid item sm={4} md={3} key={app.displayName}>
                <AppCard
                  icon={<ApplicationIcon name={app.icon as ApplicationIconName} />}
                  name={app.displayName}
                  path={app.path.value}
                />
              </Grid>
            </WithPermissions>
          ))}
        </Grid>
      </PageContainer>
    );
  };

  return (
    <AppContainer skipPermissionsCheck>
      <Router>
        <ThemeProvider provider={MUIThemeProvider}>
          <Container>
            {Object.keys(applicationsWithAccess).map((key, i) => (
              <React.Fragment key={key}>
                {renderApplicationGroup(applicationsWithAccess[key])}
                {i < Object.keys(applicationsWithAccess).length - 1 && <Divider sx={{ mt: 4 }} />}
              </React.Fragment>
            ))}
          </Container>
        </ThemeProvider>
      </Router>
    </AppContainer>
  );
};

export default Landing;
