import { QueryDslQueryContainer, SearchRequest } from "@elastic/elasticsearch/lib/api/types";
import { Operation, compare } from "fast-json-patch";
import { acceleratorApi } from ".";
import {
  AssetNameplateData,
  AssetNameplateDataDocumentResponse,
  AssetNameplateDataListResponse
} from "../../types/accelerator/asset-nameplate-data";
import { QueryOptions, SearchFilter } from "../../types";

/**
 * Search Nameplate Data
 * @return {Promise<AssetNameplateDataListResponse>}
 */
export const searchAssetNameplateData = async (
  searchTerm: string,
  filter: SearchFilter<AssetNameplateData>,
  options: QueryOptions = {}
): Promise<AssetNameplateDataListResponse> => {
  const from = options.from || 0;
  const size = options.size || 20;

  const searchBody: SearchRequest = {
    from,
    size,
    query: {
      bool: {
        should: [],
        must: [],
        must_not: []
      }
    }
  };

  if (filter) {
    Object.entries(filter).forEach(([key, value]) => {
      if (value || value === null) {
        (searchBody.query.bool.must as QueryDslQueryContainer[]).push({
          match: { [key]: value as string }
        });
      }
    });
  }

  const response = await acceleratorApi.post<SearchRequest, AssetNameplateDataListResponse>(
    "assetNameplateData/search",
    searchBody
  );

  return response;
};
/**
 * Get Nameplate Data
 * @return {Promise<AssetNameplateDataListResponse>}
 */
export const getAssetNameplateDataByAssetId = async (
  assetId: string
): Promise<AssetNameplateDataListResponse> => {
  return searchAssetNameplateData("", { assetId }, { from: 0, size: 10000 });
};

/**
 * Get NameplateData  By ID
 *
 * @param assetNameplateDataId: @ String
 *
 * @return {Promise<AssetNameplateDataDocumentResponse>}
 */
export const getAssetNameplateDataById = async (
  assetNameplateDataId: string
): Promise<AssetNameplateDataDocumentResponse> => {
  return acceleratorApi.get<AssetNameplateDataDocumentResponse>(
    `assetNameplateData/${assetNameplateDataId}`
  );
};

/**
 * Patch Nameplate Data
 *
 * @param assetNameplateData: @type String
 * @param previousValue: @type NameplateDataType
 * @param nextValue: @type Partial<NameplateDataType>
 *
 * @return {Promise<AssetNameplateDataDocumentResponse>}
 */
export const patchAssetNameplateData = async (
  previousValue: AssetNameplateData,
  nextValue: Partial<AssetNameplateData>
) => {
  const patchUpdates = compare(previousValue, { ...previousValue, ...nextValue });
  return acceleratorApi.patch<Operation[], AssetNameplateDataDocumentResponse>(
    `assetNameplateData/${previousValue.id}`,
    patchUpdates
  );
};

export const AssetNameplateDataApi = {
  getByAssetId: getAssetNameplateDataByAssetId,
  search: searchAssetNameplateData,
  getById: getAssetNameplateDataById,
  update: patchAssetNameplateData
};
