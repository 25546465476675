import { compare } from "fast-json-patch";
import { QueryOptions, PeopleFilterType, UserProfileDocumentResponse } from "../../types";
import { People, PeopleListResponse, PeopleDocumentResponse } from "../../types/people";
import { escapeCharacters } from "../../utils";
import { urlPathWithQueryParams } from "../../utils/utils";
import { commonDataApi } from ".";

/**
 * @param {string} siteId
 * @param {string} searchTerm
 * @param {boolean} allSites
 * @param {QueryOptions} [queryOptions={}]
 * @return {Promise<PeopleListResponse>}
 */
const getPeople = async (
  searchTerm: string,
  siteId?: string,
  queryOptions: QueryOptions = {}
): Promise<PeopleListResponse> => {
  const query = {
    from: queryOptions?.page || 0,
    size: queryOptions?.size || 10,
    query: {
      bool: {
        must: {
          multi_match: {
            query: `${escapeCharacters(searchTerm)}*`,
            fields: ["fullName", "firstName", "lastName", "userName"]
          }
        },
        ...(!!siteId && { filter: [{ term: { ["siteId.keyword"]: siteId } }] })
      }
    },
    sort: { _score: { order: "desc" } }
  };
  return await commonDataApi.post("people/search", query);
};

/**
 * Get user lists
 * @return {Promise<PeopleListResponse>}
 */
const getAllPeople = async (
  searchTerm: string,
  filters: PeopleFilterType,
  queryOptions: QueryOptions = {}
): Promise<PeopleListResponse> => {
  const query = {
    from: queryOptions?.page,
    size: queryOptions?.size,
    query: {
      bool: {
        must: null,
        filter: []
      }
    },
    sort: { _score: { order: "desc" } }
  };

  if (searchTerm) {
    query.query.bool.must = {
      multi_match: {
        query: `${escapeCharacters(`${searchTerm}`)}*`,
        fields: ["firstName", "lastName", "userName", "fullName", "email"]
      }
    };
  }

  const filterQuery = [
    ...("siteId" in filters ? [{ terms: { "siteId.keyword": filters.siteId } }] : []),
    ...("isSysAdmin" in filters ? [{ terms: { "isSysAdmin.keyword": filters.isSysAdmin } }] : []),
    ...("isActive" in filters ? [{ terms: { "isActive.keyword": filters.isActive } }] : [])
  ];

  if (filterQuery.length) {
    query.query.bool.filter.push({
      bool: {
        must: filterQuery
      }
    });
  }

  return await commonDataApi.post("people/search", query);
};

/**
 * Get user details
 * @return {Promise<PeopleDocumentResponse>}
 */
const getPeopleById = async (personId: string): Promise<PeopleDocumentResponse> => {
  return await commonDataApi.get(`people/${personId}`);
};

/**
 * Update user details
 * @param {string} personId
 * @param {People} group
 * @return {Promise<PeopleDocumentResponse>}
 */
const updatePeople = async (
  personId: string,
  updatedPerson: People | Partial<People>,
  person: People
): Promise<PeopleDocumentResponse> => {
  const patchUpdates = compare(person, updatedPerson);
  return await commonDataApi.patch(`people/${personId}`, patchUpdates);
};

/**
 * Get Users by locationId
 * @param {string} applicationId
 * @param {string} siteId
 * @param {string} locationId
 * @return {Promise<PeopleListResponse>}
 */
const getPeopleByLocation = async (
  applicationId: string,
  siteId: string,
  locationId: string,
  queryOptions: QueryOptions = {}
): Promise<PeopleListResponse> => {
  return await commonDataApi.get(
    urlPathWithQueryParams(
      `applications/${applicationId}/sites/${siteId}/locations/${locationId}/members`,
      { ...queryOptions }
    )
  );
};

/**
 * Start/Extend Impersonate People session
 * @param {string} userIdOrEmail
 * @return {Promise<UserProfileDocumentResponse>}
 */
const impersonatePeople = async (userIdOrEmail: string): Promise<UserProfileDocumentResponse> => {
  return await commonDataApi.post(`people/impersonate?userIdOrEmail=${userIdOrEmail}`, {});
};

/**
 * End Impersonate People session
 * @return {Promise<void>}
 */
const deleteImpersonatePeople = async (): Promise<void> => {
  return await commonDataApi.delete(`people/impersonate`);
};

export const PeopleApi = {
  get: getPeople,
  getPeopleById,
  updatePeople,
  getByLocation: getPeopleByLocation,
  getAllPeople,
  impersonatePeople,
  deleteImpersonatePeople
};
