import React from "react";
import { Location } from "../../types/location";
import { useUserProfile } from "./useUserProfile";
import { useGetLocationById } from "../common-data/locations/useGetLocationById";

type HookResponse = {
  loading: boolean;
  selectedLocation: Location | undefined;
  siteId: string;
};

/**
 * Get the location the user has selected to use in an application
 * @param applicationId {string} application to find selected location if
 * @returns {Object} response hook response
 * @returns {boolean} response.loading selected location is loading
 * @returns {Location | undefined} response.selectedLocation location object of user's selected location in application
 */
export const useSelectedLocation = (applicationId: string): HookResponse => {
  const profile = useUserProfile();
  const siteId = profile?.preferences.selectedSiteId || profile.siteId;

  const selectedLocationId = React.useMemo(() => {
    return profile.preferences[applicationId]?.[siteId]?.selectedLocationId;
  }, [applicationId, profile]);

  const { location, loading } = useGetLocationById(selectedLocationId);

  return { loading, selectedLocation: location, siteId };
};
