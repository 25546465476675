import {
  CreateNodeBody,
  NodeDocumentResponse,
  NodeListResponse,
  Node,
  DiscrepancyAllocationUpdate
} from "../../types/node";
import { compare } from "fast-json-patch";
import { AuditLogListResponse } from "../../types";
import { materialBalanceApi } from ".";

/**
 * Gets all the nodes
 * @returns {NodeListResponse}
 */
export const getNodes = async (): Promise<NodeListResponse> => {
  return await materialBalanceApi.get(`nodes`);
};

/**
 * Gets all nodes by productId
 * @returns {NodeListResponse}
 */
export const getByProductId = async (productId: string): Promise<NodeListResponse> => {
  return await materialBalanceApi.get(`products/${productId}/nodes`);
};

/**
 * Get Node by node id
 * @param nodeId {string}
 * @returns {NodeDocumentResponse}
 */
export const getNodeById = async (nodeId: string): Promise<NodeDocumentResponse> => {
  return await materialBalanceApi.get(`nodes/${nodeId}`);
};

/**
 * Get the audit log of a node
 * @param nodeId {string}
 * @param includeChildren {boolean}
 * @returns {AuditLogListResponse}
 */
export const getNodeAuditLog = async (
  nodeId: string,
  includeChildren?: boolean
): Promise<AuditLogListResponse> => {
  const products: AuditLogListResponse = await materialBalanceApi.get(
    `nodes/${nodeId}/auditLogs${includeChildren ? "?includeChildren=true" : ""}`
  );
  return products;
};

/**
 * Create new node
 * @param node {CreateNodeBody}
 * @returns {NodeDocumentResponse}
 */
export const createNode = async (node: CreateNodeBody): Promise<NodeDocumentResponse> => {
  return await materialBalanceApi.post(`nodes`, node);
};

/**
 * Update node
 * @param node {Node}
 * @returns {NodeDocumentResponse}
 */
export const updateNode = async (node: Node): Promise<NodeDocumentResponse> => {
  return await materialBalanceApi.put(`nodes/${node.productNodeId}`, {
    json: node
  });
};

/**
 * Patch node
 * @param previousNode {Node}
 * @param node {Partial<Node>}
 * @returns {NodeDocumentResponse}
 */
export const patchNode = async (
  previousNode: Node,
  node: Partial<Node>
): Promise<NodeDocumentResponse> => {
  const patchUpdates = compare(previousNode, node);
  return materialBalanceApi.patch(`nodes/${node.productNodeId}`, patchUpdates);
};

/**
 * Delete node
 * @param productNodeId {string}
 * @returns {void}
 */
export const deleteNode = async (productNodeId: string): Promise<void> => {
  return materialBalanceApi.delete<void>(`nodes/${productNodeId}`);
};

/**
 * Update discrepancy allocations of nodes
 * @param updates {DiscrepancyAllocationUpdate[]}
 * @returns {void}
 */
export const updateDiscrepancyAllocations = async (
  updates: DiscrepancyAllocationUpdate[]
): Promise<void> => {
  return materialBalanceApi.post(`nodes/batch`, updates);
};

/**
 * Update the order of all nodes of a product
 * @param productId {string} id of product to update nodes from
 * @param nodeIds {string[]} ordered array of nodeIds to use to set the new oder from
 * @returns {void}
 */
export const reorderNodes = async (productId: string, nodeIds: string[]): Promise<void> => {
  return materialBalanceApi.post(`products/${productId}/nodes/order`, nodeIds);
};

export const NodesApi = {
  get: getNodes,
  getOne: getNodeById,
  getByProductId: getByProductId,
  getAuditLog: getNodeAuditLog,
  create: createNode,
  delete: deleteNode,
  update: patchNode,
  updateDiscrepancyAllocations,
  reorder: reorderNodes
};
