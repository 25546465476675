import { CssBaseline } from "@mui/material";
import { lightTheme } from "./theme/theme";
import { ThemeProvider as MUIThemeProvider } from "@mui/material/styles";

type Props = {
  children: React.ReactNode;
  provider: typeof MUIThemeProvider;
};

export const ThemeProvider: React.FC<Props> = (props: Props) => {
  const { children, provider: LocalThemeProvider } = props;

  return (
    <MUIThemeProvider theme={lightTheme}>
      <LocalThemeProvider theme={lightTheme}>
        <CssBaseline />
        <main>{children}</main>
      </LocalThemeProvider>
    </MUIThemeProvider>
  );
};
