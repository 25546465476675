import { QueryDslQueryContainer, SearchRequest } from "@elastic/elasticsearch/lib/api/types";
import { Operation, compare } from "fast-json-patch";
import { acceleratorApi } from ".";
import {
  AssetNameplateTemplate,
  AssetNameplateTemplateListResponse,
  AssetNameplateTemplateDocumentResponse
} from "../../types/accelerator/asset-nameplate-templates";
import {
  AcceleratorCsv,
  AcceleratorCsvListResponse,
  QueryOptions,
  SearchFilter
} from "../../types";
import { escapeCharacters } from "../../utils";
import { NameplateLabelsApi } from "./nameplate-labels";

/**
 * Search Nameplate Templates
 * @return {Promise<AssetNameplateTemplateListResponse>}
 */
export const searchAssetNameplateTemplates = async (
  searchTerm: string,
  filter: SearchFilter<AssetNameplateTemplate>,
  options: QueryOptions = {}
): Promise<AssetNameplateTemplateListResponse> => {
  const from = options.from || 0;
  const size = options.size || 20;

  const searchBody: SearchRequest = {
    from,
    size,
    query: {
      bool: {
        should: [],
        must: [],
        must_not: []
      }
    },
    sort: {
      "assetNameplateLabel.name.keyword": { order: "asc" }
    }
  };

  if (searchTerm !== "") {
    const matchingLabels = await NameplateLabelsApi.search(searchTerm, undefined, { size: 10000 });

    const matchingLabelQueries = matchingLabels.data.reduce((ids, nameplateLabel) => {
      ids.push({
        match: { assetNameplateLabelId: nameplateLabel.id }
      });
      return ids;
    }, []);

    (searchBody.query.bool.must as QueryDslQueryContainer[]).push({
      bool: {
        should: [
          ...matchingLabelQueries,
          {
            multi_match: {
              query: escapeCharacters(searchTerm),
              type: "phrase_prefix",
              fields: ["*"]
            }
          }
        ],
        minimum_should_match: 1
      }
    });
  }

  if (filter) {
    Object.entries(filter).forEach(([key, value]) => {
      if (value || value === null) {
        (searchBody.query.bool.must as QueryDslQueryContainer[]).push({
          match: { [key]: value as string }
        });
      }
    });
  }

  const response = await acceleratorApi.post<SearchRequest, AssetNameplateTemplateListResponse>(
    "assetNameplateTemplates/search",
    searchBody
  );

  return response;
};
/**
 * Get Nameplate Templates
 * @return {Promise<AssetNameplateTemplateListResponse>}
 */
export const getAssetNameplateTemplates = async (): Promise<AssetNameplateTemplateListResponse> => {
  return acceleratorApi.get<AssetNameplateTemplateListResponse>("assetNameplateTemplates");
};

/**
 * Get NameplateTemplate  By ID
 *
 * @param assetNameplateTemplateId: @ String
 *
 * @return {Promise<AssetNameplateTemplateDocumentResponse>}
 */
export const getAssetNameplateTemplateById = async (
  assetNameplateTemplateId: string
): Promise<AssetNameplateTemplateDocumentResponse> => {
  return acceleratorApi.get<AssetNameplateTemplateDocumentResponse>(
    `assetNameplateTemplates/${assetNameplateTemplateId}`
  );
};

/**
 * Create Nameplate Template
 *
 * @param assetNameplateTemplate: @ Partial<NameplateTemplate>
 *
 * @return {Promise<NameplateTemplate>}
 */
export const createAssetNameplateTemplate = async (
  assetNameplateTemplate: Partial<AssetNameplateTemplate> | Partial<AssetNameplateTemplate>[]
): Promise<AssetNameplateTemplateDocumentResponse | AssetNameplateTemplateListResponse> => {
  return acceleratorApi.post<
    Partial<AssetNameplateTemplate> | Partial<AssetNameplateTemplate>[],
    Promise<AssetNameplateTemplateDocumentResponse | AssetNameplateTemplateListResponse>
  >(
    `assetNameplateTemplates${Array.isArray(assetNameplateTemplate) ? "/bulk" : ""}`,
    assetNameplateTemplate
  );
};

/**
 * Patch Nameplate Template
 *
 * @param assetNameplateTemplate: @type String
 * @param previousValue: @type NameplateTemplateType
 * @param nextValue: @type Partial<NameplateTemplateType>
 *
 * @return {Promise<AssetNameplateTemplateDocumentResponse>}
 */
export const patchAssetNameplateTemplate = async (
  previousValue: AssetNameplateTemplate,
  nextValue: Partial<AssetNameplateTemplate>
) => {
  const patchUpdates = compare(previousValue, { ...previousValue, ...nextValue });
  return acceleratorApi.patch<Operation[], AssetNameplateTemplateDocumentResponse>(
    `assetNameplateTemplates/${previousValue.id}`,
    patchUpdates
  );
};

/**
 * Delete NameplateTemplate
 *
 * @param assetNameplateTemplateId: @ String
 *
 * @return {Promise<void>}
 */
export const deleteAssetNameplateTemplate = async (
  assetNameplateTemplateId: string
): Promise<void> => {
  await acceleratorApi.delete(`assetNameplateTemplates/${assetNameplateTemplateId}`);
};

/**
 * CSV file upload
 * @param file: @type String
 * @param pageConfigKey: @type String
 * @return {Promise<AcceleratorCsvListResponse>}
 */
const csvUpload = async (
  file: string,
  pageConfigKey: string
): Promise<AcceleratorCsvListResponse> => {
  const data: AcceleratorCsv[] = await acceleratorApi.post(`${pageConfigKey}/bulk`, file);

  const response: AcceleratorCsvListResponse = {
    data,
    hasMore: true,
    total: Infinity
  };

  return response;
};

export const AssetNameplateTemplatesApi = {
  create: createAssetNameplateTemplate,
  delete: deleteAssetNameplateTemplate,
  get: getAssetNameplateTemplates,
  search: searchAssetNameplateTemplates,
  getById: getAssetNameplateTemplateById,
  update: patchAssetNameplateTemplate,
  upload: csvUpload
};
