import PubSub from "pubsub-js";

import { MessageTypes } from "./message-types";

// User profile is partially typed here as we are not able to import the @nutrien-operations/auth package
// because it would cause a circular dependancy error
type UserProfile = {
  preferences?: {
    selectedSiteId?: string;
  };
  siteId: string;
};

class MessageBus {
  constructor(debug?: boolean) {
    if (debug) {
      this.subscribe("*", (message, data) => {
        // eslint-disable-next-line no-console
        console.log("Message Bus | message, data:", message, data);
      });
    }

    this.subscribe<UserProfile>("auth.profile", (message, data) => {
      this.publish("sites.selected", {
        selectedSiteId: data?.preferences?.selectedSiteId || data?.siteId || null
      });
    });
  }
  subscribe = <T = unknown>(message: MessageTypes, func: PubSubJS.SubscriptionListener<T>) => {
    return PubSub.subscribe(message, func);
  };

  unsubscribe = (token: string) => {
    PubSub.unsubscribe(token);
  };

  publish = <T = unknown>(message: MessageTypes, data: T) => {
    return PubSub.publish(message, data);
  };
}

export default new MessageBus();
