import React from "react";
import { styled } from "@mui/material/styles";
import { Drawer, DrawerProps, Hidden } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const DrawerRoot = styled(Drawer)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    position: "relative"
  },
  minWidth: "100%",
  [theme.breakpoints.up(700)]: {
    minWidth: 500
  },
  zIndex: 1500,
  "& > .MuiDrawer-paperAnchorRight": {
    borderRadius: 0
  },
  "& .MuiDrawer-paper": {
    [theme.breakpoints.down(700)]: {
      width: "100%"
    }
  }
}));

const Dragger = styled("div")(({ theme }) => ({
  width: 4,
  cursor: "ew-resize",
  padding: "4px 0 0",
  borderTop: "1px solid #ddd",
  position: "absolute",
  top: 0,
  left: 0,
  bottom: 0,
  zIndex: "100",
  backgroundColor: theme.palette.primary.main
}));

const WIDTH_MAX = 900;
const WIDTH_DEFAULT = 500;

export type DrawerResizableProps = DrawerProps;

/**
 * Returns a material ui root `Drawer` with resizable width.
 */
export function DrawerResizable({ children, ...drawerProps }: DrawerResizableProps) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up(700));
  const maxWidth = matches ? WIDTH_MAX : "100%";
  const initialWidth = matches ? WIDTH_DEFAULT : "100%";
  const minWidth = initialWidth;

  const [width, setWidth] = React.useState<string | number>(initialWidth);
  const [isResizing, setIsResizing] = React.useState<boolean>(false);

  const onMouseMove = (e) => {
    if (!isResizing) {
      return;
    }

    const offsetRight = document.body.offsetWidth - (e.clientX - document.body.offsetLeft);

    if (
      typeof maxWidth === "number" &&
      typeof minWidth === "number" &&
      offsetRight > minWidth &&
      offsetRight < maxWidth
    ) {
      setWidth(offsetRight);
    }
  };

  const onMouseDown = () => {
    setIsResizing(true);
  };

  const onMouseUp = () => {
    setIsResizing(false);
  };

  React.useEffect(() => {
    document.addEventListener("mouseup", onMouseUp);
    return () => {
      document.removeEventListener("mouseup", onMouseUp);
    };
  }, []);

  React.useEffect(() => {
    document.addEventListener("mousemove", onMouseMove);
    return () => {
      document.removeEventListener("mousemove", onMouseMove);
    };
  }, [isResizing]);

  return (
    <DrawerRoot anchor="right" PaperProps={{ style: { width } }} {...drawerProps}>
      <Hidden smDown>
        <Dragger id="dragger" onMouseDown={onMouseDown} className="dragger" />
      </Hidden>
      {children}
    </DrawerRoot>
  );
}
