import Config from "@nutrien-operations/config";

import { ApiInstance } from "../api-instance";

import { LocationConfigurationApi } from "./location-configuration";
import { CommonApplicationShifts } from "../common-application-shifts";
import { TemplatesApi } from "./templates";
import { LogsEntryApi } from "./logs-entry";
import { LogAttachmentsApi } from "./log-attachments";

export class ShiftLogsApi {
  protected _instance: ApiInstance | null = null;

  constructor() {
    const prefixUrl = Config.API["shift-logs"];
    this._instance = new ApiInstance(prefixUrl);
  }

  getInstance = () => this._instance;

  static LocationConfiguration = LocationConfigurationApi;
  static LogsEntry = LogsEntryApi;
  static LogAttachments = LogAttachmentsApi;
  static Shifts = CommonApplicationShifts(new ShiftLogsApi().getInstance());
  static Templates = TemplatesApi;
}

export const shiftLogsApi = new ShiftLogsApi().getInstance();
