import React from "react";

import MessageBus from "@packages/message-bus";
import { UserProfile } from "../../types";
import Auth from "../../auth/auth";

export const useUserProfile = () => {
  const [userProfile, setUserProfile] = React.useState<UserProfile>(Auth.userProfile);

  React.useEffect(() => {
    MessageBus.subscribe<UserProfile>("auth.profile", (message, profile) => {
      setUserProfile(profile);
    });
  }, []);

  return userProfile;
};
