// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.92.0_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.92.0_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.snackbar-container-root {
  /* 1500 is the layer of the drawer, so ensure the snackbar displays above the drawer */
  z-index: 1510;
}
`, "",{"version":3,"sources":["webpack://./../../packages/theme-mui-v5/src/components/Snackbar/snackbar-styles.css"],"names":[],"mappings":"AAAA;EACE,sFAAsF;EACtF,aAAa;AACf","sourcesContent":[".snackbar-container-root {\n  /* 1500 is the layer of the drawer, so ensure the snackbar displays above the drawer */\n  z-index: 1510;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
