import { FC, ReactNode, useMemo } from "react";
import IconButton from "@mui/material/IconButton";
import LoadingButton from "@mui/lab/LoadingButton";
import MUIDialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { Breakpoint, SxProps, Theme } from "@mui/material";
import Paper, { PaperProps } from "@mui/material/Paper";
import Draggable from "react-draggable";

export type DialogActionButton = {
  action: () => void;
  disabled?: boolean;
  loading?: boolean;
  text: string;
  color?: "inherit" | "primary" | "secondary" | "success" | "error" | "info" | "warning";
  variant?: "text" | "outlined" | "contained";
};

type DialogProps = {
  disableBackdropClick?: boolean;
  onClose?: () => void;
  actions?: DialogActionButton[];
  isDraggable?: boolean;
  title?: string;
  bodyText?: string;
  children?: ReactNode;
  footerLeft?: ReactNode;
  fullWidth?: boolean;
  fullScreen?: boolean;
  maxWidth?: Breakpoint;
  open?: boolean;
  sx?: SxProps<Theme>;
  contentSx?: SxProps<Theme>;
};

const PaperComponent = (props: PaperProps) => {
  return (
    <Draggable handle=".draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
};

export const Dialog: FC<DialogProps> = (props) => {
  const {
    actions,
    disableBackdropClick,
    bodyText,
    children,
    fullWidth = true,
    fullScreen = false,
    isDraggable = false,
    onClose,
    maxWidth = fullScreen ? undefined : "md",
    open = true,
    sx = {},
    contentSx = {},
    title,
    footerLeft
  } = props;

  const handleClose = (_event, reason) => {
    if (disableBackdropClick && reason === "backdropClick") {
      return;
    }
    onClose();
  };

  const paperComponent = useMemo(() => {
    return isDraggable ? { PaperComponent } : {};
  }, [isDraggable]);

  return (
    <MUIDialog
      open={open}
      onClose={handleClose}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      fullScreen={fullScreen}
      disableEnforceFocus
      sx={sx}
      {...paperComponent}
    >
      <DialogTitle className="draggable-dialog-title" style={isDraggable ? { cursor: "move" } : {}}>
        {title}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon color="primary" />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers sx={contentSx}>
        {bodyText && <DialogContentText>{bodyText}</DialogContentText>}
        {children}
      </DialogContent>
      {actions && (
        <DialogActions
          style={isDraggable ? { cursor: "move" } : {}}
          className="draggable-dialog-title"
          sx={footerLeft ? { pl: 2, pr: 2, justifyContent: "space-between" } : {}}
        >
          {footerLeft}
          <div>
            {actions.map((action, index) => (
              <LoadingButton
                key={`${index}-text`}
                color={action.color}
                onClick={action.action}
                variant={action.variant}
                disabled={action.disabled}
                loading={action.loading}
                sx={{ ml: 2 }}
              >
                {action.text}
              </LoadingButton>
            ))}
          </div>
        </DialogActions>
      )}
    </MUIDialog>
  );
};
