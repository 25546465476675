import { CommonDataApi, GroupListResponse, HTTPError } from "../../..";
import { UseMutationOptions, useMutation, useQueryClient } from "react-query";
import { Group, GroupDocumentResponse } from "../../..";
import { groupsByLocationId } from "./query-cache";

interface Variables {
  updatedGroup: Group;
  group: Group | Partial<Group>;
}

/**
 * Hook to update a group
 */
export const useUpdateGroup = (groupId: string) => {
  const queryClient = useQueryClient();

  const queryOptions: UseMutationOptions<GroupDocumentResponse, HTTPError, Variables> = {
    mutationFn: ({ updatedGroup, group }: Variables) =>
      CommonDataApi.Groups.update(groupId, updatedGroup, group as Group),
    onSuccess: (response, variables) => {
      queryClient.setQueryData<GroupListResponse | undefined>(
        groupsByLocationId(variables.group.applicationId, variables.group.locationId),
        (prevState) => {
          if (!prevState)
            return {
              data: [response.data],
              hasMore: false,
              total: 1
            };

          const index = prevState.data.findIndex(
            (group) => group.groupId === response.data.groupId
          );

          if (index > -1) {
            prevState.data.splice(index, 1, response.data);
          }

          return { ...prevState, data: [...prevState.data] };
        }
      );
    }
  };

  const { mutateAsync, isLoading, isError, data, isSuccess } = useMutation(queryOptions);

  return {
    groupData: data,
    updateGroup: mutateAsync,
    loading: isLoading,
    isError,
    isSuccess
  };
};
