export const permissionsList = () => ["common-data", "permissions", "list"];

export const permissionById = (permissionId: string) => [
  "common-data",
  "permissions",
  "single",
  permissionId
];

export const permissionsOfLocation = (locationId: string) => [
  "common-data",
  "permissions",
  locationId
];

export const permissionsOfApplication = (applicationId: string) => [
  "common-data",
  "permissions",
  "by-application",
  applicationId
];

export const permissionsByIds = (permissionIds: string[]) => [
  "common-data",
  "permissions",
  "by-ids",
  permissionIds?.join()
];
