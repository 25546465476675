export const Video = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="lucide lucide-file-video-2"
    >
      <path d="M4 8V4a2 2 0 0 1 2-2h8.5L20 7.5V20a2 2 0 0 1-2 2H4" />
      <polyline points="14 2 14 8 20 8" />
      <path d="m10 15.5 4 2.5v-6l-4 2.5" />
      <rect width="8" height="6" x="2" y="12" rx="1" />
    </svg>
  );
};
