import React from "react";
import { Card, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";

type Props = {
  name: string;
  path: string;
  icon: React.ReactNode;
};

const IconWrapper = styled("div")(({ theme }) => ({
  alignItems: "center",
  background: theme.palette.common.white,
  display: "flex",
  flex: 0.6,
  justifyContent: "center",
  "&> svg": {
    height: 32,
    width: 32,
    color: theme.palette.primary.main
  }
}));

const AppNameWrapper = styled("div")(() => ({
  alignItems: "center",
  background: "#F9F9F9",
  display: "flex",
  flex: 0.4,
  justifyContent: "center"
}));

const RouterLink = styled(Link)(() => ({
  color: "inherit",
  textDecoration: "inherit"
}));

const AppCard: React.FC<Props> = ({ icon, name, path }) => {
  return (
    <RouterLink to={path}>
      <Card
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          height: 116,
          "&:hover": {
            cursor: "pointer"
          }
        }}
      >
        <IconWrapper>{icon}</IconWrapper>
        <AppNameWrapper>
          <Typography variant="h5" component="div">
            {name}
          </Typography>
        </AppNameWrapper>
      </Card>
    </RouterLink>
  );
};

export default AppCard;
