import { Operation, compare } from "fast-json-patch";
import { acceleratorApi } from ".";
import {
  AssetType,
  AssetTypeListResponse,
  AssetTypeDocumentResponse
} from "../../types/accelerator/assets";
import { urlPathWithQueryParams } from "../../utils";

/**
 * Get Asset Types
 * @return {Promise<AssetTypeListResponse>}
 */
export const getAssetTypes = async (): Promise<AssetTypeListResponse> => {
  return acceleratorApi.get<AssetTypeListResponse>(
    urlPathWithQueryParams("assetTypes", { page: 0, size: 1000 })
  );
};

/**
 * Get Asset Type By ID
 *
 * @param assetTypeId: @type String
 *
 * @return {Promise<AssetTypeDocumentResponse>}
 */
export const getAssetTypeById = async (assetTypeId: string): Promise<AssetTypeDocumentResponse> => {
  return acceleratorApi.get<AssetTypeDocumentResponse>(`assetTypes/${assetTypeId}`);
};

/**
 * Create Asset Type
 *
 * @param assetType: @type Partial<AssetType>
 *
 * @return {Promise<AssetType>}
 */
export const createAssetType = async (
  assetType: Partial<AssetType>
): Promise<AssetTypeDocumentResponse> => {
  return acceleratorApi.post<Partial<AssetType>, AssetTypeDocumentResponse>(
    "assetTypes",
    assetType
  );
};

/**
 * Update Asset Type
 *
 * @param assetTypeUpdate: @type AssetType
 *
 * @return {Promise<AssetTypeDocumentResponse>}
 */
export const updateAssetType = async (
  assetTypeUpdate: Partial<AssetType>
): Promise<AssetTypeDocumentResponse> => {
  return acceleratorApi.put<Partial<AssetType>, AssetTypeDocumentResponse>(
    "assetTypes",
    assetTypeUpdate
  );
};

/**
 * Patch Asset Type
 *
 * @param assetTypeId: @type String
 * @param previousValue: @type AssetType
 * @param nextValue: @type Partial<AssetType>
 *
 * @return {Promise<AssetTypeDocumentResponse>}
 */
export const patchAssetType = async (
  assetTypeId: string,
  previousValue: AssetType,
  nextValue: Partial<AssetType>
): Promise<AssetTypeDocumentResponse> => {
  const patchUpdates = compare(previousValue, { ...previousValue, ...nextValue });
  return acceleratorApi.patch<Operation[], AssetTypeDocumentResponse>(
    `assetTypes/${assetTypeId}`,
    patchUpdates
  );
};

/**
 * Delete Asset Type
 *
 * @param assetTypeId: @type String
 *
 * @return {Promise<void>}
 */
export const deleteAssetType = async (assetTypeId: string): Promise<void> => {
  await acceleratorApi.delete(`assetTypes/${assetTypeId}`);
};

export const AssetTypesApi = {
  getAssetTypes,
  patchAssetType,
  getById: getAssetTypeById,
  deleteAssetType,
  createAssetType,
  updateAssetType
};
