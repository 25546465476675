import { acceleratorApi } from ".";
import { SteadyState, SteadyStateResponse } from "../../types/accelerator/steady-state";

function create(steadyState: SteadyState) {
  return acceleratorApi.post<SteadyState, SteadyStateResponse>("steadyStates", steadyState);
}

function del(id: string) {
  return acceleratorApi.delete(`steadyStates/${id}`);
}

function getById(id: string) {
  return acceleratorApi.get<SteadyStateResponse>(`steadyStates/${id}`);
}

function search() {
  // TODO filtering / searching / sorting
  const payload = {};
  return acceleratorApi.post("steadyStates/search", payload);
}

function update(id: string, payload: SteadyState) {
  return acceleratorApi.put(`steadyStates/${id}`, payload);
}

export const SteadyStateApi = {
  create,
  delete: del,
  getById,
  search,
  update
};
