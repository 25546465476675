import { compare } from "fast-json-patch";

import { SiteDocumentResponse, SiteListResponse, CreateSiteBody, Site } from "../../types/site";
import { QueryOptions } from "../../types/api-definitions";
import { urlPathWithQueryParams } from "../../utils/utils";
import { commonDataApi } from ".";

/**
 * Gets all the sites
 * @returns {SiteListResponse}
 */
export const getSites = async (
  query: Partial<Site> = {},
  queryOptions: QueryOptions = {}
): Promise<SiteListResponse> => {
  const sites: SiteListResponse = await commonDataApi.get(
    urlPathWithQueryParams("sites", { ...query, ...queryOptions })
  );

  // remove empties
  sites.data = sites.data.filter((site) => {
    return site.siteCode != null;
  });
  return sites;
};

/**
 * Create new site
 * @param site {CreateSiteBody}
 * @returns {SiteDocumentResponse}
 */
export const createSite = async (site: CreateSiteBody): Promise<SiteDocumentResponse> => {
  return await commonDataApi.post(`sites`, site);
};

/**
 * Get Site by site id
 * @param siteId {string}
 * @returns {SiteDocumentResponse}
 */
export const getSiteById = async (siteId: string): Promise<SiteDocumentResponse> => {
  return await commonDataApi.get(`sites/${siteId}`);
};

/**
 * patch site
 * @param previousSite {Site}
 * @param site {Partial<Site>}
 * @returns {SiteDocumentResponse}
 */
export const patchSite = async (
  previousSite: Site,
  site: Partial<Site>
): Promise<SiteDocumentResponse> => {
  const patchUpdates = compare(previousSite, site);
  return commonDataApi.patch(`sites/${previousSite.siteId}`, patchUpdates);
};

/**
 * Delete a Site
 * @param string {siteId}
 * @return {Promise<void>}
 */
export const deleteSite = async (siteId: string): Promise<void> => {
  return await commonDataApi.delete(`sites/${siteId}`);
};

export const SitesApi = {
  get: getSites,
  getOne: getSiteById,
  create: createSite,
  update: patchSite,
  delete: deleteSite
};
