import * as React from "react";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";

import { Dialog } from "../Dialog/Dialog";
import {
  Location,
  useGetLocations,
  useSelectedLocation,
  useUpdateUserPreferences
} from "@packages/service-api";
import { useUserProfile } from "@packages/service-api";

import { WithLoader } from "../WithLoader";

import LocationTree from "./LocationTree";

export interface TreeNode {
  children?: TreeNode[];
}

type Props = {
  applicationId: string;
  onClose: () => void;
};

const LocationDialog: React.FC<Props> = (props) => {
  const { applicationId, onClose } = props;
  const userProfile = useUserProfile();

  const { selectedLocation: currentSelectedLocation, siteId } = useSelectedLocation(applicationId);
  const { locations, loading: locationsLoading } = useGetLocations(applicationId, siteId);
  const { updateUserPreferences, saving } = useUpdateUserPreferences();

  const [selected, setSelected] = React.useState<Location>(currentSelectedLocation);

  const handleUpdateLocation = (nextLocation: Location) => {
    if (nextLocation) {
      setSelected(nextLocation);
    }
  };

  const handleUpdateUserPreferences = async () => {
    await updateUserPreferences({
      [applicationId]: { [siteId]: { selectedLocationId: selected.locationId } }
    });

    onClose();
  };

  const loading = locationsLoading || saving;

  return (
    <Dialog
      title="Select Location"
      onClose={onClose}
      actions={[
        {
          text: "Update Location",
          action: handleUpdateUserPreferences,
          disabled: loading
        }
      ]}
    >
      <Box>
        <WithLoader loading={loading}>
          <LocationTree onChange={handleUpdateLocation} locations={locations} selected={selected} />

          {selected && userProfile?.isSysAdmin && (
            <Box px={1}>
              <Typography>
                <b>Location Id:</b> {selected.locationId}
              </Typography>
              <Typography>
                <b>Site Id:</b> {selected.siteId}
              </Typography>
              <Typography>
                <b>Inherited Rights Block:</b> {String(selected.isInheritedRightsBlock)}
              </Typography>
            </Box>
          )}
        </WithLoader>
      </Box>
    </Dialog>
  );
};

export default LocationDialog;
