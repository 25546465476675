// import React from "react";
// import {
//   CheckCircleIcon,
//   AlertTriangleFeather,
//   InfoFeather,
// } from "../icons";
import materialPalette from "./materialPalette";
// disabled temporarily until we have the them finalized
// eslint-disable-next-line
const args: any = {
  props: {
    // MuiAlert: {
    //   iconMapping: {
    //     error: (
    //       <InfoFeather
    //         color="error"
    //         fill={materialPalette.error.main}
    //         stroke={materialPalette.common.white}
    //         strokeWidth={2}
    //       />
    //     ),
    //     info: (
    //       <InfoFeather
    //         color="info"
    //         fill={materialPalette.info.main}
    //         stroke={materialPalette.common.white}
    //         strokeWidth={2}
    //       />
    //     ),
    //     success: (
    //       <CheckCircleIcon
    //         color="success"
    //         fill={materialPalette.success.main}
    //         stroke={materialPalette.common.white}
    //         strokeWidth={2}
    //       />
    //     ),
    //     warning: (
    //       <AlertTriangleFeather
    //         color="warning"
    //         fill={materialPalette.warning.main}
    //         stroke={materialPalette.common.white}
    //         strokeWidth={2}
    //       />
    //     ),
    //   },
    //   variant: "standard",
    // },

    MuiDialog: {
      transitionDuration: 0
    },
    MuiDrawer: {
      transitionDuration: 0
    }
    // ========== BELOW are overrides of default props for MaterialUI components. ==========
  },
  overrides: {
    MuiButton: {
      root: {
        fontFamily: "'Open Sans', sans-serif",
        height: "44px",
        boxShadow: "none!important",
        textTransform: "none",
        padding: 0,
        justifyContent: "flex-start"
      },
      label: {
        fontWeight: 600,
        fontFamily: "'Open Sans', sans-serif",
        fontSize: "16px",
        padding: "0 5px"
      },

      outlined: { padding: 0 },
      text: { padding: 0 }
    },
    MuiOutlinedInput: {
      root: { padding: "0", minHeight: "44px" },
      input: {
        padding: "12px",
        fontFamily: "'Open Sans', sans-serif"
      },
      multiline: { padding: "12px" }
    },
    MuiInput: {
      underline: {
        "&:hover:not(.Mui-disabled):before": {
          border: "none"
        },
        "&::before": {
          border: "none"
        },
        "&.MuiInput-underline.Mui-disabled:before": {
          borderBottom: "none"
        }
      }
    },
    MuiInputAdornment: {
      positionStart: { marginLeft: "auto" },
      positionEnd: { marginRight: "auto" }
    },
    MuiInputBase: {
      root: {
        padding: "0px",
        minHeight: "44px"
      },
      input: {
        color: "currentColor",
        WebkitTextFillColor: "currentColor"
      }
    },
    MuiFilledInput: {
      root: {
        backgroundColor: materialPalette.primary.light,
        padding: "0",
        minHeight: "44px"
      },
      input: {
        padding: "12px",
        fontFamily: "'Open Sans', sans-serif"
      },
      multiline: { padding: "12px" }
    },
    MuiSelect: {
      select: {
        "&:focus": {
          backgroundColor: "transparent"
        },
        paddingRight: "32px !important"
      }
    },
    MuiListItem: {
      button: {
        fontFamily: "'Open Sans', sans-serif!important",
        backgroundColor: "transparent!important",
        padding: "6px 12px",
        "&:hover, &:focus, &:active": {
          backgroundColor: materialPalette.grey[100] + "!important"
        }
      }
    },
    MuiMenu: {
      paper: {
        transition: "none!important"
      }
    },
    MuiTabs: {
      scroller: {
        borderBottom: `1px solid ${materialPalette.grey[300]}`
      },
      scrollButtons: {
        borderBottom: `1px solid ${materialPalette.grey[300]} !important`
      }
    },
    PrivateTabScrollButton: {
      root: {
        color: materialPalette.primary.main,
        "&:not(.MuiButtonBase-root)": {
          borderBottom: "none"
        }
      }
    },
    MuiTab: {
      root: {
        fontFamily: "'Open Sans', sans-serif",
        fontWeight: 600,
        textTransform: "capitalize",
        letterSpacing: "1.25px",
        fontSize: "14px",
        borderBottom: `0px`,
        "&:hover": {
          backgroundColor: "rgba(42, 171, 63, 0.06)",
          color: materialPalette.primary.main,
          borderBottom: `2px solid ${materialPalette.primary.main}`
        },
        padding: "4px 16px"
      }
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: materialPalette.grey["900"]
      },
      arrow: {
        color: materialPalette.grey["900"]
      }
    },
    MuiSnackbarContent: {
      root: {
        flexWrap: "nowrap",
        justifyContent: "center",
        minWidth: "200px!important",
        fontSize: "14px",
        lineheight: "20px"
      }
    },
    MuiCard: {
      root: {
        borderRadius: "8px !important"
      }
    },
    MuiToggleButton: {
      root: {
        height: "44px",
        color: materialPalette.common.black,
        textTransform: "none",
        letterSpacing: "1.25px",
        border: `1px solid ${materialPalette.grey[600]} !important`,
        "&$disabled": {
          backgroundColor: materialPalette.action.disabledBackground,
          color: materialPalette.text.disabled
        },
        "&$selected": {
          color: materialPalette.common.black,
          backgroundColor: materialPalette.primary.main + "14"
        },
        "&$selected&:hover": {
          color: materialPalette.common.black,
          backgroundColor: materialPalette.primary.light
        },
        "&:hover": {
          backgroundColor: materialPalette.primary.light,
          border: `1px solid ${materialPalette.grey[600]}`
        }
      }
    },
    MuiBadge: {
      colorError: {
        color: materialPalette.common.white,
        backgroundColor: materialPalette.error.main
      }
    }
  },
  MuiButton: {
    styleOverrides: {
      colorDefault: {
        backgroundColor: "red"
      }
    }
  }
};

export default args;
