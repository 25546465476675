import { compare } from "fast-json-patch";
import { QueryOptions } from "../../types";
import {
  ShiftSchedulesListResponse,
  ShiftSchedule,
  ShiftScheduleDocumentResponse
} from "../../types/shift-schedule";
import { urlPathWithQueryParams } from "../../utils";
import { commonDataApi } from ".";

/**
 * Get list of shift schedules by locationId
 * @param applicationId {string}
 * @param locationId {string}
 * @return {Promise<ShiftSchedulesListResponse>}
 */
const getShiftScheduleByLocation = async (
  locationId: string,
  queryOptions: QueryOptions = {}
): Promise<ShiftSchedulesListResponse> => {
  return await commonDataApi.get(
    urlPathWithQueryParams(`locations/${locationId}/shiftSchedules`, queryOptions)
  );
};

/**
 * Get  shift schedules by locationId and ate
 * @param applicationId {string}
 * @param date {string}
 * @return {Promise<ShiftSchedulesListResponse>}
 */
const getByLocationAndDate = async (
  locationId: string,
  date: string,
  queryOptions: QueryOptions = {}
): Promise<ShiftScheduleDocumentResponse> => {
  return commonDataApi.get(
    urlPathWithQueryParams(`locations/${locationId}/shiftSchedules/${date}`, queryOptions)
  );
};

/**
 * Get shift rotation detail by id
 * @param shiftScheduleId {string}
 * @return {Promise<ShiftRotationDocumentResponse>}
 */
const getShiftScheduleDetails = async (
  shiftScheduleId: string
): Promise<ShiftScheduleDocumentResponse> => {
  return await commonDataApi.get(`shiftSchedules/${shiftScheduleId}`);
};

/**
 * Create a shift schedules
 * @param shiftSchedule {ShiftSchedule}
 * @return {Promise<ShiftScheduleDocumentResponse>}
 */
const createShiftSchedule = async (
  shiftSchedule: ShiftSchedule
): Promise<ShiftScheduleDocumentResponse> => {
  return await commonDataApi.post("shiftSchedules", shiftSchedule);
};

/**
 * Update existing shift schedules
 * @param shiftScheduleId {string}
 * @param updatedShiftSchedule {shiftSchedule}
 * @param ShiftSchedules {shiftSchedule}
 * @return {Promise<ShiftScheduleDocumentResponse>}
 */
const updateShiftSchedule = async (
  shiftScheduleId: string,
  updatedShiftSchedule: Partial<ShiftSchedule>,
  shiftSchedule: Partial<ShiftSchedule>
): Promise<ShiftScheduleDocumentResponse> => {
  const patchUpdates = compare(shiftSchedule, updatedShiftSchedule);
  return await commonDataApi.patch(`shiftSchedules/${shiftScheduleId}`, patchUpdates);
};

/**
 * Delete existing shift schedules
 * @param shiftScheduleId {string}
 * @return {Promise<void>}
 */
const deleteShiftSchedule = async (shiftScheduleId: string): Promise<void> => {
  return await commonDataApi.delete(`shiftSchedules/${shiftScheduleId}`);
};

export const ShiftSchedulesApi = {
  getByLocation: getShiftScheduleByLocation,
  getByLocationAndDate: getByLocationAndDate,
  create: createShiftSchedule,
  update: updateShiftSchedule,
  delete: deleteShiftSchedule,
  getDetails: getShiftScheduleDetails
};
