import Config from "@nutrien-operations/config";

import { ApiInstance } from "../api-instance";

import { CalculationsApi } from "./calculations";

export class CalculationsEngineApi {
  protected _instance: ApiInstance | null = null;

  constructor() {
    const prefixUrl = Config.API["calculation-engine"];
    this._instance = new ApiInstance(prefixUrl);
  }

  getInstance = () => this._instance;

  static Calculations = CalculationsApi;
}

export const calculationsEngineApi = new CalculationsEngineApi().getInstance();
