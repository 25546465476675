import { compare } from "fast-json-patch";

import {
  ApplicationDocumentResponse,
  ApplicationsListResponse,
  CreateApplicationBody,
  Application
} from "../../types/application";
import { QueryOptions } from "../../types/api-definitions";
import { urlPathWithQueryParams } from "../../utils/utils";
import { SiteListResponse } from "../../types";
import { commonDataApi } from ".";

/**
 * Gets all the applications
 * @returns {ApplicationsListResponse}
 */
export const getApplications = async (
  query: Partial<Application> = {},
  queryOptions: QueryOptions = {}
): Promise<ApplicationsListResponse> => {
  const applications: ApplicationsListResponse = await commonDataApi.get(
    urlPathWithQueryParams("applications", { ...query, ...queryOptions })
  );

  return applications;
};

/**
 * Create new application
 * @param application {CreateApplicationBody}
 * @returns {ApplicationDocumentResponse}
 */
export const createApplication = async (
  application: CreateApplicationBody
): Promise<ApplicationDocumentResponse> => {
  return await commonDataApi.post(`applications`, application);
};

/**
 * Get Application by application id
 * @param applicationId {string}
 * @returns {ApplicationDocumentResponse}
 */
export const getApplicationById = async (
  applicationId: string
): Promise<ApplicationDocumentResponse> => {
  return await commonDataApi.get(`applications/${applicationId}`);
};

/**
 * Get Application's Site by application id
 * @param applicationId {string}
 * @returns {ApplicationDocumentResponse}
 */
export const getSitesByApplicationId = async (applicationId: string): Promise<SiteListResponse> => {
  return await commonDataApi.get(`applications/${applicationId}/sites`);
};

/**
 * Update application
 * @param application {Application}
 * @returns {ApplicationDocumentResponse}
 */
export const updateApplication = async (
  application: Application
): Promise<ApplicationDocumentResponse> => {
  return await commonDataApi.put(`applications/${application.applicationId}`, {
    json: application
  });
};

/**
 * Delete application
 * @param application {Application}
 * @returns {ApplicationDocumentResponse}
 */
export const deleteApplication = async (applicationId: string): Promise<void> => {
  return await commonDataApi.delete(`applications/${applicationId}`);
};

/**
 * patch application
 * @param previousApplication {Application}
 * @param application {Partial<Application>}
 * @returns {ApplicationDocumentResponse}
 */
export const patchApplication = async (
  previousApplication: Application,
  application: Partial<Application>
): Promise<ApplicationDocumentResponse> => {
  const patchUpdates = compare(previousApplication, { ...previousApplication, ...application });

  return commonDataApi.patch(`applications/${previousApplication.applicationId}`, patchUpdates);
};

export const ApplicationsApi = {
  get: getApplications,
  getOne: getApplicationById,
  update: patchApplication,
  create: createApplication,
  delete: deleteApplication,
  getSitesByApplicationId
};
