import React from "react";
import ReactDOM from "react-dom";
import singleSpaReact from "single-spa-react";

import MessageBus from "@packages/message-bus";
import { errorBoundary } from "@packages/theme-mui-v5";

import Landing from "./Landing";

const lifeCycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: Landing,
  errorBoundary
});

export async function mount(props) {
  MessageBus.publish("app.navbar.title", { title: "Dashboard" });
  MessageBus.publish("app.load", { title: "Dashboard" });
  return lifeCycles.mount(props);
}

export async function unmount(props) {
  MessageBus.publish("app.unload", { title: "Dashboard" });
  return lifeCycles.unmount(props);
}

export const { bootstrap } = lifeCycles;
