import { potashReportingApi } from ".";
import { ApproverPermission, ApproverPermissionResponse } from "../../types";

/**
 * Gets approver permission
 * @param locationId {string}
 * @param reportTypeId {number}
 * @param userGuid {string}
 * @returns {ApproverPermissionResponse}
 */
export const getApproverPermission = async (
  locationId: string,
  reportTypeId: number,
  userGuid: string
): Promise<ApproverPermissionResponse> => {
  return await potashReportingApi.get(
    `location/${locationId}/user-guid/${userGuid}/report-type/${reportTypeId}/report-approver-permission`
  );
};

/**
 * Update approver permission
 * @param locationId {string}
 * @param ApproverPermission {ApproverPermission}
 * @return {Promise<ApproverPermissionResponse>}
 */
const updateApproverPermission = async (
  locationId: string,
  approverPermission: ApproverPermission
): Promise<ApproverPermissionResponse> => {
  return await potashReportingApi.post(
    `location/${locationId}/report-approver-permission`,
    approverPermission
  );
};

export const AppoverPermissionsApi = {
  get: getApproverPermission,
  update: updateApproverPermission
};
