import React from "react";
import { Paper, Typography, Grid, Box } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import { useGetLocations } from "@packages/service-api";
import { useSelectedLocation } from "@packages/service-api";

import LocationDialog from "./LocationDialog";
import { CircularProgressBar } from "../WithLoader";

export type Props = {
  applicationId: string;
  disabled?: boolean;
};

export const LocationToolbar = (props: Props) => {
  const { applicationId } = props;
  const [open, setOpen] = React.useState<boolean>(false);

  const { selectedLocation, siteId } = useSelectedLocation(applicationId);
  const { locations, loading } = useGetLocations(applicationId, siteId);

  const { selectedLocationTitle } = React.useMemo(() => {
    if (!selectedLocation) {
      return {};
    }

    const selectedLocationTitle = [];
    const selectedAllLocationIds = [];

    const getParent = (locationId) => {
      const location = locations.find((data) => data.locationId === locationId);
      if (location) {
        selectedLocationTitle.unshift(location.name);
        selectedAllLocationIds.unshift(location.locationId);
        getParent(location.parentLocationId);
      }
    };

    getParent(selectedLocation.locationId);

    return {
      selectedLocationTitle: selectedLocationTitle.join(" > "),
      selectedAllLocationIds
    };
  }, [locations, selectedLocation]);

  return (
    <Paper>
      <Box sx={{ minHeight: 74, position: "relative" }}>
        {loading ? (
          <CircularProgressBar />
        ) : (
          <>
            <Grid
              p={2}
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              flexWrap="nowrap"
            >
              <Typography
                pr={2}
                variant="h6"
                color="primary"
                sx={{ cursor: "pointer" }}
                onClick={() => setOpen(true)}
              >
                Location
              </Typography>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                width="100%"
                border="1px solid rgba(224, 224, 224)"
                borderRadius={1}
                p={1}
                sx={{ cursor: "pointer" }}
                onClick={() => setOpen(true)}
              >
                <Typography variant="h6">
                  {selectedLocationTitle ||
                    (!selectedLocation && <>Please select a location to continue</>)}
                </Typography>
                <KeyboardArrowDownIcon color="primary" />
              </Grid>
            </Grid>
            {open && (
              <LocationDialog applicationId={applicationId} onClose={() => setOpen(false)} />
            )}
          </>
        )}
      </Box>
    </Paper>
  );
};
