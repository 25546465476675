import { useTheme } from "@mui/material/styles";
import { useMediaQuery as useMedia } from "@mui/material";

export const useMediaQuery = () => {
  const theme = useTheme();
  return {
    smUp: useMedia(theme.breakpoints.up("sm")),
    mdUp: useMedia(theme.breakpoints.up("md")),
    smDown: useMedia(theme.breakpoints.down("sm")),
    mdDown: useMedia(theme.breakpoints.down("md"))
  };
};
