import ky from "ky";
import { KyHeadersInit } from "ky/distribution/types/options";

import { AttachmentType } from "@packages/utils";

import {
  ApiListResponse,
  AttachmentEntity,
  CreateAttachmentFunction,
  CreateAttachmentRequest,
  UploadAttachmentFunction
} from "../../types";

// Create attachment entity
export const createAttachment: CreateAttachmentFunction = async ({
  apiInstance,
  endpointUrl,
  request: createAttachmentRequest
}): Promise<AttachmentEntity> => {
  const response = await apiInstance.post<
    CreateAttachmentRequest[],
    ApiListResponse<AttachmentEntity>
  >(endpointUrl, [createAttachmentRequest]);

  const createAttachmentResponse = response.data[0];

  if (createAttachmentRequest.attachmentType !== AttachmentType.Link) {
    const uploadRequest = {
      attachment: createAttachmentRequest.attachment,
      presignedUploadUrl: createAttachmentResponse.attachmentLocation
    };

    await uploadAttachment({ request: uploadRequest });
  }

  return createAttachmentResponse;
};

// Upload attachment to AWS S3
export const uploadAttachment: UploadAttachmentFunction = async ({ request }): Promise<void> => {
  const { attachment, presignedUploadUrl } = request;

  const headers: KyHeadersInit = {
    "Content-Type": attachment.type ?? "application/octet-stream"
  };

  await ky.put(presignedUploadUrl, {
    body: attachment,
    headers
  });
};
