import { compare } from "fast-json-patch";
import { acceleratorApi } from ".";
import {
  ProductionUnit,
  ProductionUnitListResponse,
  ProductionUnitResponse
} from "../../types/accelerator/production-units";
import { SearchRequest } from "@elastic/elasticsearch/lib/api/types";

/**
 * Get Production Units
 * @return {Promise<ProductionUnitListResponse>}
 */
export const getProductionUnits = async (): Promise<ProductionUnitListResponse> => {
  const searchBody: SearchRequest = {
    from: 0,
    size: 1000,
    sort: {
      "name.keyword": "asc"
    }
  };
  return await acceleratorApi.post(`productionUnits/search`, searchBody);
};

/**
 * Get Production Units By ID
 *
 * @param productionUnitId: @ String
 *
 * @return {Promise<ProductionUnitResponse>}
 */
export const getProductionUnitById = async (
  productionUnitId: string
): Promise<ProductionUnitResponse> => {
  return acceleratorApi.get(`productionUnits/${productionUnitId}`);
};

/**
 * Create Production Unit
 *
 * @param productionUnit: @ Partial<ProductionUnit>
 *
 * @return {Promise<ProductionUnitResponse>}
 */
export const createProductionUnit = async (
  productionUnit: Partial<ProductionUnit>
): Promise<ProductionUnitResponse> => {
  return acceleratorApi.post("productionUnits", productionUnit);
};

/**
 * Patch Production Unit
 * @param previousValue: @type ProductionUnit
 * @param nextValue: @type Partial<ProductionUnit>
 *
 * @return {Promise<AssetResponse>}
 */
export const patchProductionUnit = (
  previousValue: ProductionUnit,
  nextValue: Partial<ProductionUnit>
): Promise<ProductionUnitResponse> => {
  const patchUpdates = compare(previousValue, { ...previousValue, ...nextValue });
  return acceleratorApi.patch(`productionUnits/${previousValue.id}`, patchUpdates);
};

/**
 * Delete Production Unit
 *
 * @param productionUnitId: @ String
 *
 * @return {Promise<void>}
 */
export const deleteProductionUnit = async (productionUnitId: string): Promise<void> => {
  await acceleratorApi.delete(`productionUnits/${productionUnitId}`);
};

export const ProductionUnitsApi = {
  get: getProductionUnits,
  getById: getProductionUnitById,
  create: createProductionUnit,
  update: patchProductionUnit,
  delete: deleteProductionUnit
};
