export const locationsList = () => ["common-data", "locations", "list"];

export const locationById = (locationId: string) => [
  "common-data",
  "locations",
  "single",
  locationId
];

export const locationsOfSite = (applicationId: string, siteId: string) => [
  "common-data",
  "locations",
  siteId,
  applicationId
];

export const locationsOfApplication = (applicationId: string) => [
  "common-data",
  "locations",
  "by-application",
  applicationId
];

export const locationsOfApplications = (applicationIds: string[]) => [
  "common-data",
  "locations",
  "by-applications",
  applicationIds?.join()
];
