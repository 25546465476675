import Config from "@nutrien-operations/config";

import { ApiInstance } from "../api-instance";
import { ApplicationsApi } from "./applications";
import { GroupsApi } from "./groups";
import { LocationsApi } from "./location";
import { PeopleApi } from "./people";
import { PermissionsApi } from "./permissions";
import { SitesApi } from "./sites";
import { ShiftSchedulesApi } from "./shift-schedules";
import { ShiftRotationApi } from "./shift-rotation";

export class CommonDataApi {
  protected _instance: ApiInstance | null = null;

  constructor() {
    const prefixUrl = Config.API["common-data"];
    this._instance = new ApiInstance(prefixUrl);
  }

  getInstance = () => this._instance;

  static Applications = ApplicationsApi;
  static Groups = GroupsApi;
  static Locations = LocationsApi;
  static People = PeopleApi;
  static Permissions = PermissionsApi;
  static Sites = SitesApi;
  static ShiftSchedules = ShiftSchedulesApi;
  static ShiftRotation = ShiftRotationApi;
}

export const commonDataApi = new CommonDataApi().getInstance();
