import { compare } from "fast-json-patch";
import { SearchRequest } from "@elastic/elasticsearch/lib/api/types";
import { acceleratorApi } from ".";
import { SubAreaListResponse, SubAreaResponse, SubArea } from "../../types/accelerator/sub-areas";

/**
 * Get Sub Areas
 *
 * @return {Promise<SubAreaListResponse>}
 */
export const getSubAreas = async (): Promise<SubAreaListResponse> => {
  const searchBody: SearchRequest = {
    from: 0,
    size: 1000,
    sort: {
      "name.keyword": "asc"
    }
  };
  return await acceleratorApi.post(`subAreas/search`, searchBody);
};

/**
 * Get sub Areas By ID
 *
 * @param subAreaId: @ String
 *
 * @return {Promise<SubAreaResponse>}
 */
export const getSubAreaById = async (subAreaId: string): Promise<SubAreaResponse> => {
  return acceleratorApi.get(`subAreas/${subAreaId}`);
};

/**
 * Create sub Area
 *
 * @param subArea: @ Partial<SubArea>
 *
 * @return {Promise<SubAreaResponse>}
 */
export const createSubArea = async (subArea: Partial<SubArea>): Promise<SubAreaResponse> => {
  return acceleratorApi.post("subAreas", subArea);
};

/**
 * Patch sub Area
 * @param previousValue: @type SubArea
 * @param nextValue: @type Partial<SubArea>
 *
 * @return {Promise<SubAreaResponse>}
 */
export const patchSubArea = (
  previousValue: SubArea,
  nextValue: Partial<SubArea>
): Promise<SubAreaResponse> => {
  const patchUpdates = compare(previousValue, { ...previousValue, ...nextValue });
  return acceleratorApi.patch(`subAreas/${previousValue.id}`, patchUpdates);
};

/**
 * Delete sub Area
 *
 * @param subAreaId: @ String
 *
 * @return {Promise<void>}
 */
export const deleteSubArea = async (subAreaId: string): Promise<void> => {
  await acceleratorApi.delete(`subAreas/${subAreaId}`);
};

export const SubAreasApi = {
  get: getSubAreas,
  getById: getSubAreaById,
  create: createSubArea,
  update: patchSubArea,
  delete: deleteSubArea
};
