import { potashReportingApi } from ".";
import {
  SiteReportSubscription,
  SubscriptionsResponse,
  SubscriptionDocumentResponse
} from "../../types/site-report-subscription";

/**
 * Gets report subscriptions by site id
 * @param siteId {string}
 * @param locationId {string}
 * @param reportTypeId {number}
 * @returns {SubscriptionsResponse}
 */
export const getReportSubscriptionsBySiteId = async (
  siteId: string,
  locationId: string,
  reportTypeId: number
): Promise<SubscriptionsResponse> => {
  return await potashReportingApi.get(
    `getMailingList/location/${locationId}/reportType/${reportTypeId}`
  );
};

/**
 * Add a report subscription to the site mailing list
 * @param locationId {string}
 * @param subscription {SiteReportSubscription}
 * @param siteId {string}
 * @return {Promise<SubscriptionDocumentResponse>}
 */
const addReportSubscriptionToSiteMailingList = async (
  subscription: SiteReportSubscription
): Promise<SubscriptionDocumentResponse> => {
  return await potashReportingApi.post(`mailing-list`, subscription);
};

/**
 * Delete subscription from site mailing list
 * @param locationId {string}
 * @param subscription {SiteReportSubscription}
 * @param siteId {string}
 * @returns {void}
 */
export const deleteSubscriptionFromSiteMailingList = async (
  locationId: string,
  subscription: SiteReportSubscription
): Promise<void> => {
  return potashReportingApi.delete<void>(
    `location/${locationId}/reportType/${subscription.reportTypeId}/mailing-list?emailId=${subscription.email}`
  );
};

export const SiteReportSubscriptionsApi = {
  add: addReportSubscriptionToSiteMailingList,
  delete: deleteSubscriptionFromSiteMailingList,
  get: getReportSubscriptionsBySiteId
};
