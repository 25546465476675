import { default as baseDayJS } from "dayjs";

import isBetween from "dayjs/plugin/isBetween";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { isEmpty } from "./isEmpty";

baseDayJS.extend(isBetween);
baseDayJS.extend(timezone);
baseDayJS.extend(utc);

export const dayjs = baseDayJS;

export const DEFAULT_DATE_TIME_FORMAT = "YYYY-MM-DD h:mm a";

export const DEFAULT_DATE_FORMAT = "YYYY-MM-DD";

export const getDateInLocalTime = (
  dateString: Date | string,
  format = DEFAULT_DATE_TIME_FORMAT
) => {
  if (typeof dateString == "string" && isEmpty(dateString)) {
    return "";
  }
  return dayjs.utc(dateString).tz(dayjs.tz.guess()).format(format);
};
