import { potashReportingApi } from ".";
import {
  ReportSubscription,
  ReportSubscriptionDocumentResponse,
  ReportSubscriptionsResponse
} from "../../types/report-subscription";
import { urlPathWithQueryParams } from "../../utils";
import { QueryOptions } from "../../types/api-definitions";

/**
 * Gets report subscriptions
 * @param locationId {string}
 * @param reportTypeId {number}
 * @returns {ReportSubscriptionsResponse}
 */
export const getReportSubscriptions = async (
  locationId: string,
  reportTypeId: number,
  queryOptions: QueryOptions = {}
): Promise<ReportSubscriptionsResponse> => {
  return await potashReportingApi.get(
    urlPathWithQueryParams(`getcorpmailingList/location/${locationId}/reporttype/${reportTypeId}`, {
      ...queryOptions
    })
  );
};

/**
 * Add a report subscription
 * @param ReportSubscription {ReportSubscription}
 * @param locationId {string}
 * @return {Promise<ReportSubscriptionDocumentResponse>}
 */
const addReportSubscription = async (
  reportSubscription: ReportSubscription,
  locationId: string
): Promise<ReportSubscriptionDocumentResponse> => {
  return await potashReportingApi.post(
    `postCorpMailingList/location/${locationId}`,
    reportSubscription
  );
};

/**
 * Delete subscription
 * @param locationId {string}
 * @param ReportSubscription {ReportSubscription}
 */
export const deleteSubscription = async (
  locationId: string,
  reportSubscription: ReportSubscription
): Promise<void> => {
  return potashReportingApi.delete<void>(
    `deleteCorpMailingList/location/${locationId}/reportType/${reportSubscription.reportTypeId}/email/?emailId=${reportSubscription.email}`
  );
};

export const ReportSubscriptionsApi = {
  add: addReportSubscription,
  delete: deleteSubscription,
  getReportSubscriptions: getReportSubscriptions
};
