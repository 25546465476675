import React from "react";
import { Box } from "@mui/material";

import { Application } from "@nutrien-operations/config";
import { WithPermissions } from "@packages/service-api";

import { AppSidebarProvider } from "../AppSidebar/state";

type Props = {
  appConfig?: Application;
  children: React.ReactNode;
  defaultCollapsed?: boolean;
  redirectOnNoPermissions?: boolean;
  skipPermissionsCheck?: boolean;
};

const AppContainer: React.FC<Props> = (props) => {
  const { appConfig, children, defaultCollapsed, redirectOnNoPermissions, skipPermissionsCheck } =
    props;

  const renderContent = () => (
    <>
      <AppSidebarProvider appConfig={appConfig} defaultCollapsed={defaultCollapsed}>
        <Box
          sx={(theme) => ({
            mt: `${Number(theme.mixins.toolbar.minHeight) + 8}px`,
            pr: 0,
            pl: 0
          })}
        >
          {children}
        </Box>
      </AppSidebarProvider>
    </>
  );

  if (skipPermissionsCheck) {
    return renderContent();
  }

  return (
    <WithPermissions
      applicationId={appConfig.applicationId}
      permissions={Object.values(appConfig?.permissions ?? {})}
      redirect={redirectOnNoPermissions ?? true}
    >
      {renderContent()}
    </WithPermissions>
  );
};

export default AppContainer;
