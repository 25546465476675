import { compare } from "fast-json-patch";
import {
  LocationConfiguration,
  LocationConfigurationDocumentResponse
} from "../../types/location-configuration";
import { shiftLogsApi } from ".";

/**
 * Gets LocationConfiguration by locationId
 * @param locationId {string}
 * @returns {LocationConfigurationDocumentResponse}
 */
export const getLocationConfigurationById = async (
  locationId: string
): Promise<LocationConfigurationDocumentResponse> => {
  return shiftLogsApi.get(`locations/${locationId}/configuration`);
};

/**
 * Create new LocationConfiguration
 * @param locationConfiguration {LocationConfiguration}
 * @returns {LocationConfigurationDocumentResponse}
 */
export const createLocationConfiguration = async (
  locationConfiguration: LocationConfiguration
): Promise<LocationConfigurationDocumentResponse> => {
  return await shiftLogsApi.post(
    `locations/${locationConfiguration.locationId}/configuration`,
    locationConfiguration
  );
};

/**
 * patch LocationConfiguration
 * @param previousLocationConfiguration {Partial<LocationConfiguration>}
 * @param locationConfiguration {Partial<LocationConfiguration>}
 * @returns {locationConfigurationDocumentResponse}
 */
export const patchLocationConfiguration = async (
  previousLocationConfiguration: Partial<LocationConfiguration>,
  locationConfiguration: Partial<LocationConfiguration>
): Promise<LocationConfigurationDocumentResponse> => {
  const patchUpdates = compare(previousLocationConfiguration, locationConfiguration);
  return shiftLogsApi.patch(
    `locations/${previousLocationConfiguration.locationId}/configuration`,
    patchUpdates
  );
};

export const LocationConfigurationApi = {
  get: getLocationConfigurationById,
  create: createLocationConfiguration,
  update: patchLocationConfiguration
};
