import { compare } from "fast-json-patch";
import { QueryOptions } from "../../types";
import {
  ShiftRotationListResponse,
  ShiftRotation,
  ShiftRotationDocumentResponse
} from "../../types/shift-rotation";
import { Shift } from "../../types/shift";
import { urlPathWithQueryParams } from "../../utils";
import { commonDataApi } from ".";

/**
 * Get list of shift rotations by locationId
 * @param applicationId {string}
 * @param locationId {string}
 * @return {Promise<ShiftRotationListResponse>}
 */
const getShiftRotation = async (
  queryOptions: QueryOptions = {}
): Promise<ShiftRotationListResponse> => {
  return await commonDataApi.get(urlPathWithQueryParams(`shiftRotations`, queryOptions));
};

/**
 * Get shift rotation detail by id
 * @param shiftRotationId {string}
 * @return {Promise<ShiftRotationDocumentResponse>}
 */
const getShiftRotationDetails = async (
  shiftRotationId: string
): Promise<ShiftRotationDocumentResponse> => {
  return await commonDataApi.get(`shiftRotations/${shiftRotationId}`);
};

/**
 * Create a shift rotation
 * @param ShiftRotation {ShiftRotation}
 * @return {Promise<ShiftRotationDocumentResponse>}
 */
const createShiftRotation = async (
  shiftRotation: ShiftRotation
): Promise<ShiftRotationDocumentResponse> => {
  return await commonDataApi.post("shiftRotations", shiftRotation);
};

/**
 * Add shifts to shift rotation
 * @param shifts {Shift}
 * @return {Promise<ShiftRotationListResponse>}
 */
const addShiftToShitRotation = async (shifts: Shift[]): Promise<ShiftRotationListResponse> => {
  return await commonDataApi.post("shifts/batch", shifts);
};

/**
 * Delete existing shift
 * @param shiftId {string}
 * @return {Promise<void>}
 */
const deleteShift = async (shiftId: string): Promise<void> => {
  return await commonDataApi.delete(`shifts/${shiftId}`);
};

/**
 * Update existing shift rotation
 * @param shiftRotationId {string}
 * @param updatedShiftRotation {ShiftRotation}
 * @param shiftRotation {ShiftRotation}
 * @return {Promise<ShiftRotationDocumentResponse>}
 */
const updateShiftRotation = async (
  shiftRotationId: string,
  shiftRotation: ShiftRotation | Partial<ShiftRotation>,
  updatedShiftRotation: ShiftRotation | Partial<ShiftRotation>
): Promise<ShiftRotationDocumentResponse> => {
  const patchUpdates = compare(shiftRotation, updatedShiftRotation);
  return await commonDataApi.patch(`shiftRotations/${shiftRotationId}`, patchUpdates);
};

/**
 * Delete existing shift rotation
 * @param shiftRotationId {string}
 * @return {Promise<void>}
 */
const deleteShiftRotations = async (shiftRotationId: string): Promise<void> => {
  return await commonDataApi.delete(`shiftRotations/${shiftRotationId}`);
};

/**
 * update shift rotation status
 * @param shiftRotationId {string}
 * @param status {string}
 * @return {Promise<ShiftRotationDocumentResponse>}
 */
const updateShiftRotationStatus = async (
  shiftRotationId: string,
  status: string
): Promise<ShiftRotationDocumentResponse> => {
  return await commonDataApi.put(`shiftRotations/${shiftRotationId}/status`, { status });
};

export const ShiftRotationApi = {
  get: getShiftRotation,
  create: createShiftRotation,
  update: updateShiftRotation,
  addShifts: addShiftToShitRotation,
  delete: deleteShiftRotations,
  getDetails: getShiftRotationDetails,
  deleteShift,
  updateStatus: updateShiftRotationStatus
};
