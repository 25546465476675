import { shiftLogsApi } from ".";
import {
  ShiftLogAttachment,
  CreateAttachmentRequest,
  ShiftLogAttachmentListResponse
} from "../../types";
import { createAttachment } from "../shared";

/**
 * Get Shift Log Attachments
 *
 * @param shiftLogId: @type String
 *
 * @return {Promise<ShiftLogAttachmentListResponse>}
 */
export const getShiftLogAttachments = async ({
  shiftLogId
}: {
  shiftLogId: string;
}): Promise<ShiftLogAttachmentListResponse> => {
  return await shiftLogsApi.get<ShiftLogAttachmentListResponse>(`logs/${shiftLogId}/attachments`);
};

/**
 * Create Shift Log Attachment
 *
 * @param shiftLogId: @type String
 *
 * @return {Promise<ShiftLogAttachmentListResponse>}
 */
export const createShiftLogAttachment = async ({
  shiftLogId,
  request
}: {
  shiftLogId: string;
  request: CreateAttachmentRequest;
}): Promise<ShiftLogAttachment> => {
  const response = await createAttachment({
    apiInstance: shiftLogsApi,
    endpointUrl: `logs/${shiftLogId}/attachments`,
    request
  });

  return response as ShiftLogAttachment;
};

// const response = await initiateAttachmenthandler(API_URL, { body: formData });

/**
 * Upload Shift Log Attachment
 *
 * @param shiftLogId: @type String
 *
 * @return {Promise<ShiftLogAttachmentListResponse>}
 */
export const uploadShiftLogAttachment = async ({
  shiftLogId
}: {
  shiftLogId: string;
}): Promise<unknown> => {
  return await shiftLogsApi.post<unknown, unknown>(`logs/${shiftLogId}/attachments`, {
    json: {}
  });
};

/**
 * Delete Shift Log Attachment
 *
 * @param shiftLogId: @type String
 * @param shiftLogAttachmentId: @type String
 *
 * @return {Promise<void>}
 */
export const deleteShiftLogAttachment = async ({
  shiftLogId,
  shiftLogAttachmentId
}: {
  shiftLogId: string;
  shiftLogAttachmentId: string;
}): Promise<void> => {
  await shiftLogsApi.delete(`logs/${shiftLogId}/attachments/${shiftLogAttachmentId}`);
};

export const LogAttachmentsApi = {
  getShiftLogAttachments,
  createShiftLogAttachment,
  deleteShiftLogAttachment
};
