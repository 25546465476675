import { Autocomplete as MUIAutocomplete, TextField, createFilterOptions } from "@mui/material";
import React from "react";
import { Control, Controller, Path } from "react-hook-form";
import { FieldValues } from "react-hook-form/dist/types/fields";

interface Option {
  id: string | number;
  label: string;
  value: string | number;
  onlyLabel?: string;
}

interface AutoCompleteProps<T> {
  control?: Control<T>;
  disabled?: boolean;
  helperText?: Path<T>;
  label: string;
  name: Path<T>;
  options?: Option[];
  required?: boolean;
}

const filter = createFilterOptions<Option>();

function Autocomplete<TFieldValues extends FieldValues>({
  control,
  disabled,
  label,
  name,
  options,
  required
}: AutoCompleteProps<TFieldValues>): JSX.Element {
  const [selectedOption, setSelectedOption] = React.useState<Option>(null);
  return (
    <Controller
      render={({ field: { value, onChange }, fieldState: { invalid, error } }) => (
        <MUIAutocomplete
          value={value || ""}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          disabled={disabled}
          options={options}
          freeSolo
          onChange={(event, newValue: Option) => {
            setSelectedOption(newValue);
            if (newValue?.value) {
              onChange(newValue.value);
            } else {
              onChange(newValue?.onlyLabel || "");
            }
          }}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);
            const { inputValue } = params;
            // Suggest the creation of a new value
            const isExisting = options.some((option) => inputValue === option.label);
            if (inputValue !== "" && !isExisting) {
              filtered.push({
                ...selectedOption,
                label: `Add "${inputValue}"`,
                onlyLabel: inputValue
              });
            }
            return filtered;
          }}
          renderOption={(props, option) => (
            <li {...props} key={option.id}>
              {option.label}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              required={required}
              fullWidth
              variant="standard"
              {...params}
              label={label}
              error={invalid}
              helperText={error ? error.message : null}
              InputLabelProps={{ shrink: true }}
            />
          )}
        />
      )}
      name={name}
      control={control}
    />
  );
}
export default Autocomplete;
