import { forwardRef, useImperativeHandle, useMemo, useState } from "react";
import {
  Alert,
  Box,
  FormGroup,
  Grid,
  IconButton,
  LinearProgress,
  Tooltip,
  Typography
} from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { DropEvent, FileRejection, useDropzone } from "react-dropzone";

import { AttachmentType, formatFileSize, isEmpty, isValidURL } from "@packages/utils";

import { AttachmentTypeIcon, getSupportedFormats } from ".";
import { ApplicationIcon } from "..";
import { TextField } from "../form";

const FILE_EXTENSION_REGEX = /\..+$/;

const MAXIMUM_ALLOWED_ATTACHMENT_SIZE = 100 * 1024 * 1024; // 100 Megabyte

export type AttachmentFormValues = {
  attachmentType?: AttachmentType;
  attachment?: File;
  fileName?: string;
  uri?: string;
};

const attachmentFormSchema: yup.ObjectSchema<AttachmentFormValues> = yup
  .object<AttachmentFormValues>()
  .shape({
    attachmentType: yup
      .mixed<AttachmentType>()
      .oneOf(Object.values(AttachmentType))
      .required("Attachment type is required")
      .nullable(),
    attachment: yup.mixed<File>().optional().nullable(),
    fileName: yup.string().required("File name is required"),
    uri: yup.string().optional()
  })
  .test({
    name: "additional-form-validation",
    test: (values: AttachmentFormValues, context): boolean | yup.ValidationError => {
      const { attachmentType, attachment, uri } = values;

      if (attachmentType === AttachmentType.Link) {
        // Validate URI
        if (isEmpty(uri)) {
          return context.createError({
            path: "uri",
            message: "File URL should be provided"
          });
        } else if (!isValidURL(uri)) {
          return context.createError({
            path: "uri",
            message: "Providded file URL is invalid"
          });
        } else {
          return true;
        }
      } else {
        // Validate attachment
        if (isEmpty(attachment) || attachment?.size === 0) {
          return context.createError({
            path: "attachment",
            message: `Attachment file is required. Either no file has been selected, or selected file is empty`
          });
        } else if (attachment?.size > MAXIMUM_ALLOWED_ATTACHMENT_SIZE) {
          return context.createError({
            path: "attachment",
            message: `Attached file exceeds size limit of ${formatFileSize(
              MAXIMUM_ALLOWED_ATTACHMENT_SIZE
            )}`
          });
        } else {
          return true;
        }
      }
    }
  });

export type AttachmentFormRef = {
  onSubmit: () => void | Promise<void>;
};

export type AttachmentFormProps = {
  loading: boolean;
  isSuccess: boolean;
  isError: boolean;
  onSubmit: (values: AttachmentFormValues) => unknown | Promise<unknown>;
};

export const AttachmentForm = forwardRef<AttachmentFormRef, AttachmentFormProps>((props, ref) => {
  const { onSubmit, loading, isSuccess, isError } = props;

  const [showNotifications, setShowNotifications] = useState<boolean>(false);

  const {
    control,
    clearErrors,
    formState,
    handleSubmit,
    getValues,
    setError,
    setValue,
    trigger,
    watch
  } = useForm<AttachmentFormValues>({
    defaultValues: {
      attachmentType: null,
      attachment: null,
      fileName: "",
      uri: ""
    },
    resolver: yupResolver(attachmentFormSchema)
  });

  const { errors, isSubmitting } = formState;

  const attachmentType = watch("attachmentType");
  const attachment = watch("attachment");
  const fileName = watch("fileName");

  const handleFormSubmit = async () => {
    const isValid = await trigger();

    if (!isValid) {
      throw Error("Attachment form is invalid");
    }

    const attachmentType = getValues("attachmentType");
    const attachment = getValues("attachment");
    const fileName = getValues("fileName");

    // Append file name extension
    if (attachmentType !== AttachmentType.Link) {
      const fileExtension = FILE_EXTENSION_REGEX.exec(attachment?.name ?? "")?.[0];

      if (!isEmpty(fileExtension) && !fileName.endsWith(fileExtension)) {
        setValue("fileName", fileName + fileExtension);
      }
    }

    try {
      setShowNotifications(true);
      await handleSubmit(onSubmit)();
    } finally {
      // Display success/error banner for 2 seconds
      setTimeout(() => setShowNotifications(false), 1000);
    }
  };

  useImperativeHandle(
    ref,
    () => ({
      onSubmit: handleFormSubmit
    }),
    []
  );

  const setAttachmentType = (selectedAttachmentType: AttachmentType) => {
    setValue("attachmentType", selectedAttachmentType);
    setValue("attachment", null);
    clearErrors("attachment");
  };

  const { supportedFormats, supportedExtensions } = useMemo(() => {
    const supportedFormats = getSupportedFormats(attachmentType);
    const supportedExtensions = (Object.values(supportedFormats)?.[0] ?? []).join(", ");
    return { supportedFormats, supportedExtensions };
  }, [attachmentType]);

  const attachmentError = errors?.["attachment"]?.message;

  const onDrop = (acceptedFiles: File[], fileRejections: FileRejection[], _event: DropEvent) => {
    // Validate file size and type
    if (!isEmpty(fileRejections)) {
      const rejectedFile = fileRejections[0].file;

      if (rejectedFile.size > MAXIMUM_ALLOWED_ATTACHMENT_SIZE) {
        setError("attachment", {
          type: "custom",
          message: `Attached file exceeds size limit of ${formatFileSize(
            MAXIMUM_ALLOWED_ATTACHMENT_SIZE
          )}`
        });
      } else {
        setError("attachment", {
          type: "custom",
          message: `File type not supported. Make sure you select a correct file format. Supported extensions are: ${supportedExtensions}`
        });
      }
    } else {
      clearErrors("attachment");

      if (isEmpty(acceptedFiles)) {
        resetAttachment();
      } else {
        const attachment = acceptedFiles[0];
        setValue("attachment", attachment);

        const fileName = attachment.name.replace(FILE_EXTENSION_REGEX, "");
        setValue("fileName", fileName);
        trigger();
      }
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: supportedFormats,
    multiple: false,
    onDrop,
    minSize: 0,
    maxSize: MAXIMUM_ALLOWED_ATTACHMENT_SIZE
  });

  const resetAttachment = () => {
    setValue("attachment", null);
    setValue("fileName", null);
    trigger();
  };

  const isAttachmentForm = [
    AttachmentType.Audio,
    AttachmentType.Document,
    AttachmentType.Image,
    AttachmentType.Video
  ].includes(attachmentType);

  const isLinkForm = attachmentType === AttachmentType.Link;

  const displayFileName = () => {
    if (isEmpty(attachment)) {
      return null;
    }

    const fileExtension = FILE_EXTENSION_REGEX.exec(attachment.name ?? "")?.[0] ?? "";
    const fileSize = `(${formatFileSize(attachment.size)})`;

    if (isEmpty(fileName)) {
      return [attachment.name, fileSize].join(" ");
    } else {
      if (isEmpty(fileExtension) || fileName.endsWith(fileExtension)) {
        return [fileName, fileSize].join(" ");
      } else {
        return [fileName + fileExtension, fileSize].join(" ");
      }
    }
  };

  return (
    <Box>
      <FormGroup onSubmit={handleFormSubmit}>
        <Grid container spacing={3} sx={{ justifyContent: "center" }}>
          <Grid
            item
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <Typography variant="body2">Select attachment type</Typography>
          </Grid>
          <Grid item>
            <AttachmentTypeIcon
              label="Image"
              iconName="image"
              tooltipText="Attach image file"
              onClick={() => setAttachmentType(AttachmentType.Image)}
              isActive={attachmentType === AttachmentType.Image}
              disabled={isSubmitting}
            />
          </Grid>

          <Grid item>
            <AttachmentTypeIcon
              label="Video"
              iconName="video"
              tooltipText="Attach video file"
              onClick={() => setAttachmentType(AttachmentType.Video)}
              isActive={attachmentType === AttachmentType.Video}
              disabled={isSubmitting}
            />
          </Grid>

          <Grid item>
            <AttachmentTypeIcon
              label="Audio"
              iconName="audio"
              tooltipText="Attach audio file"
              onClick={() => setAttachmentType(AttachmentType.Audio)}
              isActive={attachmentType === AttachmentType.Audio}
              disabled={isSubmitting}
            />
          </Grid>

          <Grid item>
            <AttachmentTypeIcon
              label="Document"
              iconName="text"
              tooltipText="Attach document file"
              onClick={() => setAttachmentType(AttachmentType.Document)}
              isActive={attachmentType === AttachmentType.Document}
              disabled={isSubmitting}
            />
          </Grid>

          <Grid item>
            <AttachmentTypeIcon
              label="URL"
              iconName="url"
              tooltipText="Link file URL"
              onClick={() => setAttachmentType(AttachmentType.Link)}
              isActive={attachmentType === AttachmentType.Link}
              disabled={isSubmitting}
            />
          </Grid>
        </Grid>

        <Box
          sx={{
            marginTop: 2,
            visibility: (isAttachmentForm && attachment) || isLinkForm ? "visible" : "hidden"
          }}
        >
          <TextField
            name="fileName"
            label="Enter file name"
            control={control}
            autoComplete="off"
            disabled={isSubmitting}
            required
          />
        </Box>

        {isAttachmentForm && (
          <>
            {!isEmpty(attachmentError) && (
              <Box marginY={1}>
                <Alert severity="error">{attachmentError}</Alert>
              </Box>
            )}

            {attachment ? (
              <Box
                sx={(theme) => ({
                  marginY: 1,
                  paddingTop: 0.25,
                  paddingX: 1,
                  height: "30px",
                  border: `1px solid ${theme.palette.primary.main}`,
                  visibility: attachment ? "visible" : "hidden"
                })}
              >
                <Grid container>
                  <Grid
                    item
                    xs={11}
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center"
                    }}
                  >
                    <Box
                      sx={{
                        paddingY: 0.5,
                        marginRight: 1,
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center"
                      }}
                    >
                      {attachmentType === AttachmentType.Audio && <ApplicationIcon name="audio" />}
                      {attachmentType === AttachmentType.Document && (
                        <ApplicationIcon name="text" />
                      )}
                      {attachmentType === AttachmentType.Image && <ApplicationIcon name="image" />}
                      {attachmentType === AttachmentType.Link && <ApplicationIcon name="link" />}
                      {attachmentType === AttachmentType.Video && <ApplicationIcon name="video" />}
                    </Box>

                    <Typography variant="body1" sx={{ fontWeight: "bold", fontSize: "14px" }}>
                      {displayFileName()}
                    </Typography>
                  </Grid>

                  <Grid item xs={1}>
                    <Box sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                      <Tooltip title="Delete attachment">
                        <IconButton color="error" size="small" onClick={resetAttachment}>
                          <ApplicationIcon name={"delete"} />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            ) : (
              <Box
                {...getRootProps()}
                sx={(theme) => ({
                  marginY: 1.5,
                  height: 150,
                  borderRadius: "4px",
                  backgroundColor: isDragActive ? theme.palette.grey[100] : theme.palette.grey[200],
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                })}
              >
                <input {...getInputProps()} />
                {isDragActive ? (
                  <p>Drop file(s) here ...</p>
                ) : (
                  <p>Drag and drop, or click to select file(s)</p>
                )}
              </Box>
            )}
          </>
        )}

        {isLinkForm && (
          <Box sx={{ marginTop: 3 }}>
            <TextField
              type="url"
              name="uri"
              label="Enter file URL"
              control={control}
              disabled={isSubmitting}
              required
            />
          </Box>
        )}
      </FormGroup>

      {showNotifications && (
        <Box sx={{ marginY: 1 }}>
          {loading && (
            <>
              <Alert severity="info">Creating attachment...</Alert>
              <LinearProgress color="secondary" />
            </>
          )}

          {isSuccess && <Alert severity="success">Attachment successfully created.</Alert>}

          {isError && (
            <Alert severity="error">
              Unfortunately, we were unable to create attachment. Please try again.
            </Alert>
          )}
        </Box>
      )}
    </Box>
  );
});

AttachmentForm.displayName = "AttachmentForm";
