import * as React from "react";
import { SimpleTreeView as TreeView, TreeItem, TreeItemProps } from "@mui/x-tree-view";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Typography from "@mui/material/Typography";
import { Alert, Box } from "@mui/material";
import deepEquals from "fast-deep-equal";
import { useMediaQuery } from "../../hooks";
import { Location } from "@packages/service-api";
import { useUserProfile } from "@packages/service-api";

import LocationTreeItem, { LocationTreeItemProps } from "./LocationTreeItem";

import { listToTree } from "../../utils";

export interface TreeNode {
  children?: TreeNode[];
}

type Props = {
  loading?: boolean;
  locations: Location[];
  onChange?: (location: Location) => void;
  selected?: Location;
  groupBySite?: boolean;
  allowAnyLocationSelection?: boolean;
};

const LocationTree: React.FC<Props> = (props) => {
  const { groupBySite, loading, locations, onChange, selected, allowAnyLocationSelection } = props;

  const { mdUp } = useMediaQuery();
  const userProfile = useUserProfile();

  const [expanded, setExpanded] = React.useState<string[]>([]);

  React.useEffect(() => {
    if (!selected) {
      return;
    }

    const nextExpanded = [];
    let location = selected;

    while (location) {
      if (typeof location !== "object") {
        throw new Error("location must be the complete Location object");
      }
      // at the root level, we want to include itself, rather than the parent
      // because the root does not have a parent
      nextExpanded.push(location.parentLocationId || location.locationId);

      if (location.parentLocationId) {
        const parent = locations.find(
          (location) => location.locationId === location.parentLocationId
        );

        location = parent;
      }
    }

    if (!deepEquals(expanded, nextExpanded)) {
      setExpanded(nextExpanded);
    }
  }, [selected]);

  const handleLocationSelect = (locationId: string) => {
    if (onChange) {
      const location = locations.find((location) => location.locationId === locationId);

      if (location) {
        onChange(location);
      }
    }
  };

  const handleExpandedChange = (_event, nodes) => {
    setExpanded(nodes);
  };

  const tree = React.useMemo(() => {
    if (!locations?.length) {
      return [];
    }

    let locationsList: Location[] = locations;

    if (groupBySite) {
      const groupBySite = locations
        .sort((a, b) => (a.sortOrder < b.sortOrder ? -1 : 1))
        .reduce<Record<string, Location[]>>((group, location) => {
          const { siteId } = location;

          group[siteId] = group[siteId] ?? [];
          group[siteId].push(location);
          return group;
        }, {});

      const sitesList = Object.values(groupBySite);

      if (sitesList.length > 0) {
        locationsList = sitesList[0];
      }
    }

    const treeData =
      listToTree(locationsList as TreeNode[], "locationId", "parentLocationId") || [];

    const sortNodesAndChildren = (nodes) => {
      nodes?.sort((a, b) => (a.sortOrder < b.sortOrder ? -1 : 1));
      nodes.forEach((node) => {
        if (node?.children?.length) {
          sortNodesAndChildren(node.children);
        }
      });
    };

    sortNodesAndChildren(treeData);
    return treeData;
  }, [groupBySite, locations]);

  interface TreeItemWithProps extends TreeItemProps {
    itemDisabled?: boolean;
  }

  const CustomTreeItem = (props: TreeItemWithProps) => {
    return (
      <TreeItem
        ContentComponent={LocationTreeItem}
        {...props}
        ContentProps={
          {
            onItemSelect: () => handleLocationSelect(props.itemId),
            itemDisabled: props.itemDisabled
          } as LocationTreeItemProps
        }
      />
    );
  };

  const hasSomeRights = (locationId: string) => {
    return (
      allowAnyLocationSelection ||
      userProfile.isSysAdmin ||
      userProfile.permissions.some((p) => p.locationIds.includes(locationId))
    );
  };

  const renderTree = (nodes) => {
    return (
      <CustomTreeItem
        key={nodes.locationId}
        itemId={nodes.locationId}
        label={nodes.name}
        itemDisabled={!hasSomeRights(nodes.locationId)}
      >
        {Array.isArray(nodes.children) && nodes.children.length
          ? nodes.children.map((node) => renderTree(node))
          : null}
      </CustomTreeItem>
    );
  };

  return (
    <Box sx={{ position: "relative" }}>
      <Box
        minHeight={300}
        sx={{
          display: "flex",
          flexDirection: mdUp ? "row" : "column"
        }}
      >
        <TreeView
          aria-label="icon expansion"
          expandedItems={expanded}
          onExpandedItemsChange={handleExpandedChange}
          selectedItems={selected?.locationId ? [selected?.locationId] : []}
          slots={{
            collapseIcon: ExpandMoreIcon,
            expandIcon: ChevronRightIcon
          }}
          sx={{ minHeight: 240, flexGrow: 1, maxWidth: 400, overflowY: "auto" }}
        >
          {tree.length
            ? tree.map((data) => renderTree(data))
            : !loading && (
                <Alert severity="warning">
                  <Typography>There are no locations enabled for your site.</Typography>
                </Alert>
              )}
        </TreeView>
      </Box>
    </Box>
  );
};

export default LocationTree;
