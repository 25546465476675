import { UseQueryOptions, useQuery } from "react-query";
import { CommonDataApi, HTTPError, PermissionListResponse } from "../../..";
import { permissionsOfApplication } from "./query-cache";

/**
 * Get permissions of an application
 */
export const useGetPermissionsByApplicationId = (applicationId: string) => {
  const queryOptions: UseQueryOptions<PermissionListResponse, HTTPError> = {
    queryKey: permissionsOfApplication(applicationId),
    queryFn: () => CommonDataApi.Permissions.get(applicationId)
  };
  const { data, isLoading, isError, isSuccess } = useQuery(queryOptions);

  return {
    permissions: data ? data.data : [],
    loading: isLoading,
    isError,
    isSuccess
  };
};
